import React from "react";
import { Paper, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";

const Deactivated = ({ message }) => {
  const history = useHistory();

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
        p: 8,
      }}
    >
      <Typography variant="h6">
        {ReactHtmlParser(
          message ||
            history?.location?.state?.message ||
            "Quizzify service has been deactivated. Please contact HR for more info."
        )}
      </Typography>
    </Paper>
  );
};

export default Deactivated;
