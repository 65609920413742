import React, { useState } from "react";
import {
  Button,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Typography,
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../api/endpoints";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const SpouseProfile = ({ spouse, handleClose }) => {
  const [sending, setSending] = useState();
  const [sent, setSent] = useState();

  return (
    <>
      <Snackbar
        open={sent}
        autoHideDuration={6000}
        onClose={() => setSent(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSent(false)}
          severity="success"
        >
          Login information sent to {spouse.email}!
        </Alert>
      </Snackbar>
      <Modal open onClose={handleClose} aria-labelledby="modal-spouse-profile">
        <Card sx={modalStyle}>
          <CardHeader
            title="Your Spouse"
            avatar={<GroupAddIcon />}
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <Stack spacing={1}>
              <Typography>
                Your spouse has an account using the email:
              </Typography>
              <Typography>{spouse.email}</Typography>
              <Typography variant="caption">
                If you'd like to change their email please contact 'Help'
              </Typography>
            </Stack>
          </CardContent>
          <Divider />
          <CardActions>
            <Button sx={{ marginLeft: "auto" }} onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="contained"
              disabled={sending}
              endIcon={sending && <CircularProgress size={18} />}
              onClick={() => {
                setSending(true);
                api.invite
                  .resendToExistingAccount()
                  .then((res) => {
                    setSent(true);
                    setSending(false);
                  })
                  .catch((err) => {
                    setSending(false);

                    window.alert(err);
                  });
              }}
            >
              Resend Invitation to Your Spouse
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default SpouseProfile;
