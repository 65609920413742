import React from "react";
import { Modal, Box } from "@mui/material";

const getStyle = () => ({
  position: "absolute",
  width: 1200,
  maxWidth: "100%",
  height: 900,
  maxHeight: "100%",
  bgcolor: "background.paper",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  outline: "none",
});

const DetailModal = ({ open, onClose, children }) => {
  const style = getStyle();

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default DetailModal;
