import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const AuthButton = ({ isLoggedIn, hideLogin, handleLogin, handleLogout }) => {
  const { t } = useTranslation();

  if (isLoggedIn) return <Button onClick={handleLogout}>{t("Logout")}</Button>;

  if (hideLogin) return null;

  return <Button onClick={handleLogin}>{t("Login")}</Button>;
};

export default AuthButton;
