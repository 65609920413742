import React, { useRef, useEffect } from "react";
import QRCode from "qrcode.react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Link,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";

const QrCode = ({ url, subdomain }) => {
  const theme = useTheme();

  const saveButton = useRef();

  useEffect(() => {
    const canvas = document.getElementById("qrcode");
    const dataURL = canvas.toDataURL("image/jpeg", 1.0);
    saveButton.current.href = dataURL;
  }, [url, saveButton]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" align="center" color="primary">
            Want to Make a Flier, Postcard or Handout to Promote Quizzify?
          </Typography>
        }
        style={{ paddingBottom: 0 }}
      />
      <CardContent>
        <Grid
          container
          alignItems="center"
          direction="column"
          spacing={1}
          style={{ marginTop: "1em" }}
        >
          <Grid item>
            <Typography variant="h6" align="center">
              Your Personalized QR Code Can Help:
            </Typography>
          </Grid>
          <Grid item>
            <QRCode
              id="qrcode"
              value={url}
              fgColor={theme.palette.primary.main}
            />
          </Grid>
          <Grid item>
            <Link
              ref={saveButton}
              download={`${subdomain}-quizzify-qr-code.jpg`}
              underline="none"
            >
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              (The QR code also links to your public home page)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default QrCode;
