import React from "react";
import { Button } from "@mui/material";

export default function AddToGoogleWalletButton({
  alt,
  href,
  onClick,
  target,
}) {
  return (
    <Button href={href} onClick={onClick} target={target}>
      <img src="/img/google_wallet.png" width={175} height="auto" alt={alt} />
    </Button>
  );
}
