import React from "react";
import { CardHeader, Grid, Typography, Box } from "@mui/material";
import { darken } from "@mui/material/styles";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

import ShareQuestion from "./ShareQuestion";
import transform from "../../../utils/transformLinks";

const QuestionHeader = ({ question }) => {
  const { t, i18n } = useTranslation();

  const currentLocale = i18n.language;

  const isEnglish = currentLocale === "en";

  const Title = () => (
    <Typography
      variant="h6"
      sx={(theme) => ({
        fontWeight: 500,
        fontSize: 18,
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
        },
        color: theme.palette.common.white,
        "& a": {
          color: theme.palette.secondary.main,
        },
      })}
    >
      {ReactHtmlParser(question.prompt, { transform })}
    </Typography>
  );

  const Harvard = () => (
    <>
      {question.harvardApproved && (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 20,
            marginRight: 20,
          }}
        >
          <img
            style={{ height: "1em", padding: "0.35em 0.5em 0 0.5em" }}
            alt={t("Harvard Medical School Approved")}
            src="/img/hms-logo.png"
          />
        </div>
      )}
    </>
  );

  const Content = () => (
    <>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: "space-between", flexWrap: "nowrap" }}
      >
        <Grid item sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <div style={{ display: "inline-block" }}>
            <Harvard />
          </div>

          <Box
            sx={(theme) => ({
              display: question.harvardApproved ? "none" : "block",
              [theme.breakpoints.up("md")]: {
                display: "block",
              },
            })}
          >
            <Title />
          </Box>
        </Grid>
        <Grid
          item
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          {isEnglish && <ShareQuestion question={question} />}
        </Grid>
      </Grid>
      <Box
        sx={(theme) => ({
          marginTop: 2,
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: question.harvardApproved ? "block" : "none",
          },
        })}
      >
        <Title />
      </Box>
    </>
  );

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: darken(theme.palette.primary.light, 0.25),
          position: "sticky",
          top: 12,
          zIndex: 100,
        })}
        title={
          <Box>
            <Content />
          </Box>
        }
      />
    </>
  );
};

export default QuestionHeader;
