import React from "react";
import { Alert, Grid } from "@mui/material";
import SalesButtons from "./SalesButtons";

const SalesMessage = ({ sales_message }) => {
  if (!sales_message) return null;

  const { intro_message, buttons } = sales_message;

  return (
    <>
      <Alert
        icon="🙌"
        severity="warning"
        variant="filled"
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
        })}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>{intro_message}</Grid>
          <SalesButtons {...sales_message} />
        </Grid>
      </Alert>
    </>
  );
};

export default SalesMessage;
