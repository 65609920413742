import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    "min-height": "calc(100vh - 64px)",
    padding: theme.spacing(2),
  },
}));

const Error = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.container}
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid item>
        <Typography color="primary" variant="h5">
          Sorry! Something went wrong.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Error;
