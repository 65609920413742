import React, { useState, useEffect, useContext } from "react";
import { Box, Card, CardHeader, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { default as api } from "../../api/endpoints";
import Table from "./Table";
import GamesCounter from "./GamesCounter";
import { Loader } from "../Loader";
import { sort, SORTING_FIELDS } from "./sorting";
import { context as authContext, actions as authActions } from "../AuthContext";

const MINUTE_IN_MILIS = 60 * 1000;

const QuizLeaderboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [timestamp, setTimestamp] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [currentGamesCount, setCurrentGamesCount] = useState(0);
  const { quizId } = useParams();

  const { dispatch: authDispatch } = useContext(authContext);

  useEffect(() => {
    if (!quizId) {
      return;
    }

    api.leaderboard
      .quiz({ quizId, timestamp: null })
      .then(({ data }) => {
        setRows(data.leaderboard.rows);
        setTimestamp(data.leaderboard.last_update_at);
        setCurrentGamesCount(data.current_games_count);
        setQuiz(data.quiz);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          authDispatch({
            type: authActions.promptLogin,
          });
        } else {
          console.error(error);
          window.alert(
            `We apologize. There was an error loading the leaderboard. Please contact Help.\n${error}`
          );
        }
      });
  }, [quizId, authDispatch]);

  useEffect(() => {
    if (!quizId || !timestamp) {
      return;
    }

    setTimeout(() => {
      api.leaderboard.quiz({ quizId, timestamp }).then(({ data }) => {
        data.leaderboard.recent_updates.forEach((update) => {
          enqueueSnackbar(
            `${update.user.name} completed the quiz with ${update.score} points`
          );
        });

        setRows((oldRows) => {
          let newRows = [...oldRows];

          data.leaderboard.rows.forEach((row) => {
            const index = oldRows.findIndex(
              (oldRow) => oldRow.user.id === row.user.id
            );

            if (index >= 0) {
              newRows[index] = row;
            } else {
              newRows.push(row);
            }
          });

          SORTING_FIELDS.forEach((field) => {
            newRows = sort(newRows, field);
          });

          return newRows;
        });
        setCurrentGamesCount(data.current_games_count);
        setTimestamp(data.leaderboard.last_update_at);
      });
    }, MINUTE_IN_MILIS);
  }, [quizId, timestamp]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SnackbarProvider>
      <Card>
        <CardHeader title={`${quiz.name} - Leaderboard`} />
        <CardContent>
          <Box>
            <GamesCounter currentGamesCount={currentGamesCount} />
          </Box>
          <Table rows={rows} />
        </CardContent>
      </Card>
    </SnackbarProvider>
  );
};

export default QuizLeaderboard;
