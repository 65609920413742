import React, { useEffect, useState, useContext } from "react";
import { Container } from "@mui/material";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { score as initialScore } from "../../StateModel";
import { useTranslation } from "react-i18next";
import isQuestionComplete from "./Question/isQuestionComplete";
import endpoints from "../../api/endpoints";
import QuizContent from "./QuizContent";
import { useHistory } from "react-router-dom";
import { context as quizContext, actions } from "./QuizContext";
import i18n from "../../i18n";

const R = require("ramda");

const Quiz = ({ state }) => {
  const { dispatch } = useContext(quizContext);

  const location = useLocation();

  const history = useHistory();
  const { t } = useTranslation();
  const [questionSubmitted, setQuestionSubmitted] = useState(false);

  const { embedView, quiz, questionIndex, resultId, locale } = state;
  const { sidebar, show_table_of_contents, questions, learn_more_format } =
    quiz;

  useEffect(() => {
    i18n.changeLanguage(locale || "en");
  }, [locale]);

  let question;

  if (questions) {
    const searchParams = new URLSearchParams(location.search);
    const goToQuestionId = searchParams.get("go-to-question");
    question = questions.find((q) => q.id === parseInt(goToQuestionId));

    if (question) {
      const paramsQuestionIndex = questions.indexOf(question);

      if (paramsQuestionIndex !== questionIndex) {
        dispatch({
          type: actions.onChangeQuestion,
          data: paramsQuestionIndex,
        });
        searchParams.delete("go-to-question");
        const newSearchString = searchParams.toString();
        const newUrl = `${location.pathname}${
          newSearchString ? `?${newSearchString}` : ""
        }`;

        history.replace(newUrl);
      }
    } else {
      question = questions[questionIndex];
    }
  }

  const score = R.defaultTo(initialScore)(question.score);
  const questionComplete = isQuestionComplete(question);

  const sidebarCount = sidebar + show_table_of_contents;

  const { total: totalScore } = state.score;
  const usePoints = _.get(state, "company.use_points", true);

  // Scroll to the top of the page with each new question
  useEffect(() => {
    const quizBox = document.getElementById("quiz-box");
    if (quizBox) {
      quizBox.scrollTo(0, 0);
    }
  }, [questionIndex]);

  useEffect(() => {
    if (questionComplete && !questionSubmitted) {
      endpoints.quiz
        .logQuestion({
          question,
          resultId,
          score: totalScore,
          quiz: {
            id: quiz.id,
            name: quiz.title,
          },
          questionsCompleted: questionIndex + 1,
        })
        .then(() => console.log("Question logged"))
        .catch((err) => window.alert(err));

      setQuestionSubmitted(true);
    }
  }, [
    questionComplete,
    questionSubmitted,
    resultId,
    totalScore,
    quiz,
    questionIndex,
    question,
  ]);

  const postLinkClicked = (values) =>
    endpoints.userBehavior
      .linkClicked(values)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));

  useEffect(() => {
    const clickHandler = (e) => {
      const clicked = e.target;
      const question_id = question.id;
      const quiz_id = quiz.id;
      let link;
      let text;

      if (clicked.tagName === "A") {
        link = clicked.href;
        text = clicked.innerText;

        postLinkClicked({
          question_id,
          quiz_id,
          link,
          text,
        });

        // Handle Learn More button
      } else if (clicked.innerText === "LEARN MORE") {
        link = question.learnMoreUrl;
        text = "LEARN MORE";

        postLinkClicked({
          question_id,
          quiz_id,
          link,
          text,
        });
      }
    };

    window.addEventListener("click", clickHandler);

    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, [question.id, quiz.id, question.learnMoreUrl]);

  return question ? (
    <Container
      id="overall-quiz-container"
      sx={(theme) => ({
        padding: 5,
        maxHeight: "100%",
        height: 1000,
        minHeight: 500,
        margin: 0,
        width: 1000 + sidebarCount * 250,
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down("sm")]: {
          padding: 0,
        },
        ...(embedView
          ? {
              padding: 0,
            }
          : {}),
      })}
    >
      <QuizContent
        isNotScored={!!quiz.isShared || !usePoints}
        sidebarCount={sidebarCount}
        score={score}
        showTableOfContents={show_table_of_contents}
        question={question}
        state={state}
        questions={questions}
        lang={locale}
        learnMoreFormat={learn_more_format}
        setQuestionSubmitted={setQuestionSubmitted}
        sidebar={sidebar}
      />
    </Container>
  ) : (
    <div>{t("No questions in quiz.")}</div>
  );
};

export default Quiz;
