import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  TextField,
  Container,
  TableContainer,
  Table,
  Stack,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Drawer,
  Box,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Fuse from "fuse.js";
import api from "../../api/endpoints";
import PrepkitDetail from "./PrepkitDetail";

const Prepkits = () => {
  const [prepkits, setPrepkits] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPrepkit, setSelectedPrepkit] = useState();

  useEffect(() => {
    api.prepkits
      .index()
      .then((response) => {
        setPrepkits(response.data);
      })
      .catch((err) => window.alert(err));
  }, []);

  const options = {
    keys: [
      "title",
      "subtitle",
      "preparations",
      "questionsByCategory.question",
      "tags",
    ],
    threshold: 0.3,
  };

  const fuse = new Fuse(prepkits, options);

  const filteredPrepkits = searchTerm
    ? fuse.search(searchTerm).map((result) => result.item)
    : prepkits;

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      <Container maxWidth="sm" sx={{ height: "100%" }}>
        <Drawer
          open={!!selectedPrepkit}
          onClose={() => setSelectedPrepkit(null)}
          anchor="right"
        >
          {selectedPrepkit && (
            <PrepkitDetail
              {...selectedPrepkit}
              handleClose={() => setSelectedPrepkit(null)}
            />
          )}
        </Drawer>

        <Stack spacing={2} sx={{ pt: 3 }}>
          <Typography variant="h6" align="center">
            Doctor Visit Prepkits
          </Typography>
          <Typography align="center" gutterBottom>
            Powered by{" "}
            <Typography
              align="center"
              gutterBottom
              color="primary"
              sx={{
                fontWeight: "900 !important",
                fontFamily: "Didot !important",
                fontSize: "1.25em",
              }}
              component="span"
            >
              Quizzify
            </Typography>
          </Typography>

          {!prepkits ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                label="Filter"
                placeholder="Knee Pain"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {filteredPrepkits.length === 0 && (
                <Typography>No results match search term</Typography>
              )}
              <TableContainer>
                <Table>
                  <TableBody>
                    {filteredPrepkits.map((prepkit) => (
                      <TableRow
                        key={prepkit.id}
                        hover
                        onClick={() => setSelectedPrepkit(prepkit)}
                      >
                        <TableCell>{prepkit.title}</TableCell>
                        <TableCell align="right">
                          <ChevronRightIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default Prepkits;
