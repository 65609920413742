const isDevelopment = () =>
  process.env.NODE_ENV === "development" &&
  !process.env.REACT_APP_NODE_ENV_OVERRIDE; // For optional local testing of production API

export const getMainRoot = () =>
  isDevelopment()
    ? `${process.env.REACT_APP_MAIN_PROTOCOL}${process.env.REACT_APP_MAIN_HOST}:${process.env.REACT_APP_MAIN_PORT}`
    : `${process.env.REACT_APP_MAIN_PROTOCOL}${process.env.REACT_APP_MAIN_DEFAULT_SUBDOMAIN}.${process.env.REACT_APP_MAIN_HOST}`;

export const getAPIEndpoint = () =>
  `${getMainRoot()}${process.env.REACT_APP_API_PATH}`;

export const getMainRootWithSubdomain = (subdomain) => {
  const BASIC_ROOT = `${process.env.REACT_APP_MAIN_PROTOCOL}${subdomain}.${process.env.REACT_APP_MAIN_HOST}`;
  return isDevelopment()
    ? `${BASIC_ROOT}.com:${process.env.REACT_APP_MAIN_PORT}` // To test subdomains locally you need to spoof them like http://subdomain.localhost.com:3000
    : `${BASIC_ROOT}`;
};
