import { get, post, apiPath } from "./core";
import { getMainRoot } from "../utils/externalRoutes";

const header = {
  "Access-Control-Allow-Origin": getMainRoot(),
  Vary: "Origin",
};

const endpoints = {
  auth: {
    login: (params) => post("authentication/login", params),
    verify: () => get("authentication/check_auth", {}),
    checkRoster: () => get("authentication/check_roster", {}),
    profile: () =>
      get("authentication/user_profile", {
        header,
      }),
    getAuthConfig: ({ companyId, quizId, ...rest }) =>
      post("authentication/get_auth_config", {
        company_id: companyId,
        quiz_id: quizId,
        ...rest,
      }),
    setPassword: (password) =>
      post("authentication/set_password", {
        password,
      }),
    sendPasswordReset: (params) =>
      post("authentication/send_password_reset", params),
    sendMagicLink: (params) => post("authentication/send_magic_link", params),
    setInitialPassword: ({ password }) =>
      post("unified_authentication/set_password", { password }),
  },
  getTogoLink: () => get("mobile/authentication/get_togo_link", {}),
  quiz: {
    welcome: async (quizId) =>
      get("quizzes/welcome", {
        header,
        params: { quiz_id: quizId },
      }),
    get: async ({ quizId, searchParams }) =>
      get("app_state", {
        header,
        params: { quiz_id: quizId, ...searchParams },
      }),
    get_with_language: async (quizId, lang) =>
      get("app_state", {
        header,
        params: { quiz_id: quizId, lang },
      }),
    getByQuestions: async (questions) =>
      get("quiz_from_questions_app_state", {
        header,
        params: { questions },
      }),
    getByShareQuestion: async (share) =>
      get("quiz_from_questions_app_state", {
        header,
        params: { share },
      }),
    getDynamic: async (term) =>
      get("dynamic_quiz_app_state", {
        header,
        params: { term },
      }),
    saveGamePostQuiz: async (params) =>
      post("save_game/save_game_post_quiz", params),
    logQuestion: async ({
      resultId,
      quiz,
      score,
      questionsCompleted,
      firstName,
      lastName,
      email,
      phone,
      message,
      employer,
      employeeId,
      shouldContact,
      question,
    }) => {
      const body = {
        id: resultId,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        message,
        quiz,
        score,
        employer,
        employee_id: employeeId,
        should_contact: shouldContact,
        questions_completed: questionsCompleted,
        question,
      };
      return await post("public_results/create_or_update")(body);
    },
    checkCompletion: async (params) =>
      post("public_results/check_completion", params),
  },
  publicHome: {
    get: async (companyId) =>
      get("public_home_app_state", {
        header,
        params: { companyId },
      }),
    getTips: async (companyId) =>
      get("public_home_app_state_tips", {
        header,
        params: { companyId },
      }),
    getNews: async (companyId) =>
      get("public_home_app_state_news", {
        header,
        params: { companyId },
      }),
    getQuizzes: async (companyId) =>
      get("public_home_app_state_quizzes", {
        header,
        params: { companyId },
      }),
    getSalesMessage: async (companyId) =>
      get("public_home_app_state_sales_message", {
        header,
        params: { companyId },
      }),
  },
  survey: {
    post: post("survey_results/create"),
  },
  userBehavior: {
    linkClicked: post("user_behavior/link_clicked"),
    logAnswerSelected: post("user_behavior/answer_selected"),
  },
  pdf: {
    path: (quizId) => apiPath(`download_quiz?quiz_id=${quizId}`),
  },
  invite: {
    config: () => get("invite/get_config", {}),
    spouse: post("invite/spouse"),
    resendToExistingAccount: () => get("invite/resend_to_existing_account", {}),
  },
  moreQuizzes: {
    get: (params) =>
      get("next_quizzes", {
        header,
        params,
      }),
  },
  companies: {
    public: (params) =>
      get("companies/public", {
        header,
        params,
      }),
    notOnRosterMessages: () =>
      get("companies/not_on_roster_messages", {
        header,
      }),
  },
  leaderboard: {
    index: (companyId) => get(`leaderboards/${companyId}`, {}),
    setDisplayNameStyle: (style) =>
      post("leaderboards/set_display_name_style", { style }),
    quiz: ({ quizId, timestamp }) => {
      const timestampParam = timestamp ? `?timestamp=${timestamp}` : "";

      return get(`quiz_leaderboards/${quizId}${timestampParam}`, {});
    },
    getUserProfile: () => get("leaderboards/get_user_profile", {}),
  },
  pilot: {
    curriculum_preview: () => get("pilot/curriculum_preview", {}),
    acceptInvite: (inviteCode) =>
      get(`invitations/${inviteCode}/accept_pilot_invite`, {}),
  },
  terms: {
    getLatest: () => get("terms_and_conditions/latest", {}),
    agree: (params) => post("terms_and_conditions/agree", params),
  },
  salesBehavior: {
    eventTriggered: (params) => post("sales_behavior/event_triggered", params),
  },
  prepkits: {
    index: () => get("mobile/cribs", {}),
  },
  activity: {
    logEvent: (params) => post("analytics/log_event", params),
  },
};

export default endpoints;
