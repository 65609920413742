import queryString from "query-string";

const homePageUrl = ({ company, homePageUrl: showHomePage }) => {
  const { redirect_home_url } = queryString.parse(window.location.search);

  if (redirect_home_url) return redirect_home_url;

  if (company && showHomePage) {
    return company?.settings?.home_page_url || `/${company.id}`;
  }

  return null;
};

export default homePageUrl;
