import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useField, splitFormProps } from "react-form";

const useStyles = makeStyles((theme) => ({
  group: {
    margin: ".5em 0",
  },
}));

const RadioField = (props) => {
  const classes = useStyles();

  const [field, fieldOptions, { options, label, color, ...rest }] =
    splitFormProps(props);

  const {
    value = "",
    setValue,
    meta: { error },
  } = useField(field, fieldOptions);

  const handleRadioChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <FormControl component="fieldset" {...rest}>
        <FormLabel component="legend">
          <Typography fontWeight="bold" variant="subtitle1">
            {label}
          </Typography>
        </FormLabel>
        <RadioGroup
          {...rest}
          classes={{ root: classes.group }}
          aria-label={field}
          name={field}
          value={value}
          onChange={handleRadioChange}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio color={color ? color : "primary"} />}
              label={option}
            />
          ))}
        </RadioGroup>
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default RadioField;
