import React, { useState } from "react";
import {
  Collapse,
  Button,
  IconButton,
  Grid,
  Box,
  Snackbar,
  CircularProgress,
  Alert,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import api from "../api/endpoints";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const LoginHelp = ({ email, contactButton }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const [snackbar, setSnackbar] = useState({});

  const [magicLinkLoading, setMagicLinkLoading] = useState();
  const [passwordResetLoading, setPasswordResetLoading] = useState();

  const handleExpandClick = () => setExpanded(!expanded);
  const handleSnackbarClose = () => setSnackbar({});

  return (
    <Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item>
          <Button onClick={handleExpandClick} color="primary" size="small">
            I need help logging in
          </Button>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Preview"
            label="Preview"
          >
            <ExpandMoreIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack spacing={2} alignItems="flex-end">
          <Button
            disabled={magicLinkLoading}
            onClick={() => {
              setMagicLinkLoading(true);
              api.auth
                .sendMagicLink({
                  email,
                  url: window.location.href,
                })
                .then(() => {
                  setMagicLinkLoading(false);
                  setSnackbar({
                    open: true,
                    severity: "success",
                    message: `Magic login link sent to ${email}`,
                  });
                })
                .catch((err) => {
                  setMagicLinkLoading(false);
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message: err,
                  });
                });
            }}
            size="small"
            color="primary"
          >
            Get Login Link
            {magicLinkLoading && <CircularProgress size={16} />}
          </Button>

          <Button
            disabled={passwordResetLoading}
            onClick={() => {
              setPasswordResetLoading(true);
              api.auth
                .sendPasswordReset({ email })
                .then(() => {
                  setPasswordResetLoading(false);
                  setSnackbar({
                    open: true,
                    severity: "success",
                    message: `Password reset sent to ${email}`,
                  });
                })
                .catch((err) => {
                  setPasswordResetLoading(false);
                  setSnackbar({
                    open: true,
                    severity: "error",
                    message: err,
                  });
                });
            }}
            size="small"
            color="primary"
          >
            Reset Password
            {passwordResetLoading && <CircularProgress size={16} />}
          </Button>

          {contactButton}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default LoginHelp;
