import React from "react";
import { Grid, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Divider from "./Divider";
import transform from "../../utils/transformLinks";

const ContestMessage = ({ contestMessage }) => {
  const { t } = useTranslation();
  const { text } = contestMessage;

  return (
    <>
      {contestMessage ? (
        <>
          <Divider />
          <Grid item>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              {t("Contest Information")}
            </Typography>
            <Typography variant="body1">
              {ReactHtmlParser(text, { transform })}
            </Typography>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default ContestMessage;
