import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Modal,
  IconButton,
  Divider,
  Button,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { axiosInstance, API_ENDPOINT } from "../../../api/core";
import getPosition from "./getPosition";
import BodyContent from "./BodyContent";
import LoadingButton from "../../LoadingButton";
import api from "../../../api/endpoints";

const cardStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  maxWidth: "100%",
  maxHeight: "100%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function getCoordinates(places = []) {
  const results = places.map((place) => ({
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  }));

  return results;
}

const DownloadGooglePass = ({ open, handleClose }) => {
  const [position, setPosition] = useState();
  const [locations, setLocations] = useState();
  const [locationComplete, setLocationComplete] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getPosition(setPosition, () => setLocationComplete(true));
  }, []);

  const handleSave = () => {
    setSaving(true);
    axiosInstance
      .post(`${API_ENDPOINT}/er_consent_passes/create_google_pass`, {
        locations: getCoordinates(locations),
      })
      .then(({ data: { save_url } }) => {
        window.location.href = save_url;

        api.activity.logEvent({
          event_category: "PREVENT_CONSENT_INTERACTION",
          event_action: "Google Wallet Pass Saved",
        });

        setSaving(false);
      })
      .catch((err) => {
        console.error(err);
        window.alert("Failed to open Google pass: ", err.message);
        setSaving(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="download-pass-modal"
    >
      <Card
        sx={{
          ...cardStyle,
          display: "flex",
          flexDirection: "column",
          height: {
            xs: "100%",
            sm: "100%",
            md: "auto",
          },
        }}
      >
        <CardHeader
          avatar={<DownloadIcon />}
          title={
            <Typography variant="h6" fontWeight="bold">
              Save the ER Prevent Consent to Your Google Wallet
            </Typography>
          }
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ overflowY: "scroll" }}>
          <BodyContent
            locationComplete={locationComplete}
            position={position}
            locations={locations}
            setLocations={setLocations}
            setLocationComplete={setLocationComplete}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            onClick={handleSave}
            loading={!(locations || locationComplete) || saving}
          >
            Save Wallet Pass to Your Google Wallet
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default DownloadGooglePass;
