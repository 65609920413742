import React, { useContext, useEffect, useState } from "react";
import { Typography, Button, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

import pluralize from "pluralize";
import ReactHtmlParser from "react-html-parser";
import commaNumber from "comma-number";

import { context as quizContext, actions } from "../../QuizContext";
import isQuestionComplete, { attemptsExhausted } from "../isQuestionComplete";
import Slider from "./Slider";
import isIE from "../../../../utils/isIE";
import api from "../../../../api/endpoints";
import transform from "../../../../utils/transformLinks";

const useStyles = makeStyles(() => ({
  sliderContainer: {
    width: "85%",
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  checkButtonContainer: {
    textAlign: "center",
    marginBottom: 16,
  },
  footer: {
    marginTop: "1.5rem",
  },
  feedback: {
    padding: "1rem",
    marginBottom: 16,
  },
}));

const decimalCount = (num) => {
  const numStr = String(num);

  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }

  return 0;
};

const formatValue = (value, unit = "", interval) => {
  let formattedValue = value;
  if (value.toFixed) {
    formattedValue = value.toFixed(decimalCount(interval));
  }

  if (!unit) return value;

  // Use translation for pluralizing
  const u = unit.trim();
  if (u.includes("dollar") || u === "$") {
    return `$${commaNumber(formattedValue)}`;
  }

  if (u === "percent" || u === "%" || u === "per cent") {
    return `${formattedValue}%`;
  }

  return pluralize(unit, formattedValue, true);
};

const Feedback = ({ question, value }) => {
  if (Math.abs(question.solution - value) <= question.tolerance)
    return (
      <Alert severity="success">
        <Typography variant="body2" gutterBottom>
          {question.solution === value
            ? "Wow! That's exactly right! "
            : "That's close enough! "}
          {question.solution !== value &&
            `The answer is ${formatValue(
              question.solution,
              question.unit,
              question.interval
            )}`}
        </Typography>
        <Typography variant="body2">
          {ReactHtmlParser(question.feedbackText, { transform })}
        </Typography>
      </Alert>
    );

  if (attemptsExhausted(question))
    return (
      <Alert severity="info">
        <Typography variant="body2" gutterBottom>
          Sorry, that's not it. The answer is{" "}
          {formatValue(question.solution, question.unit, question.interval)}.
        </Typography>
        <Typography variant="body2">
          {ReactHtmlParser(question.feedbackText, { transform })}
        </Typography>
      </Alert>
    );

  return (
    <Alert severity="error">
      <Typography variant="body2" gutterBottom>
        Sorry that's not quite right. Aim
        {value < question.solution ? " higher" : " lower"}.
      </Typography>
    </Alert>
  );
};

const isFloat = (n) => n % 1 !== 0;

const getInterval = (question) => {
  const { min, max, solution, interval } = question;

  if (interval !== 1 && interval !== 0) return interval;

  if (isFloat(solution) || isFloat(min) || isFloat(max)) {
    if (
      solution % 1 === 0.5 &&
      (min % 1 === 0.5 || min % 1 === 0.0) &&
      (max % 1 === 0.5 || max % 1 === 0.0)
    )
      return 0.5;
    return 0.1;
  }

  return interval || 1;
};

const logAnswerSelected = (
  questionId,
  quizId,
  isQuestionComplete,
  valueSelected
) =>
  api.userBehavior
    .logAnswerSelected({
      answer_type: "slider",
      question_id: questionId,
      quiz_id: quizId,
      is_question_complete: isQuestionComplete,
      value_selected: valueSelected,
    })
    .catch((err) => console.error(err));

const SliderAnswer = ({ question }) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(quizContext);
  const { quiz } = state;

  const { min, max, unit, id, startingValue } = question;

  const [value, setValue] = useState(startingValue);
  const [showCheckAnswer, setShowCheckAnswer] = useState(true);

  // When the question changes state needs to reset
  useEffect(() => {
    setValue(startingValue);
  }, [id, startingValue, setValue]);

  const isComplete = isQuestionComplete(question);

  useEffect(() => {
    setShowCheckAnswer(!isComplete);
  }, [isComplete]);

  const interval = getInterval(question);

  const marks = [
    { value: min, label: `${formatValue(min, unit, interval)}` },
    { value: max, label: `${formatValue(max, unit, interval)}` },
  ];

  const handleChange = (_, newValue) => {
    setValue(newValue);
    if (newValue !== value) {
      setShowCheckAnswer(true);
    }
  };

  const checkAnswer = () => {
    setShowCheckAnswer(false);

    dispatch({ type: actions.selectAnswer, value });

    logAnswerSelected(id, quiz?.id, isComplete, value);
  };

  return (
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      <Typography gutterBottom align="center" variant="h5">
        {formatValue(value, unit, interval)}
      </Typography>
      <div className={classes.sliderContainer}>
        {isIE ? (
          <input
            type="range"
            style={{ width: "100%" }}
            defaultValue={30}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              if (e.target.value !== value) {
                setShowCheckAnswer(true);
              }
            }}
            step={interval || 1}
            min={min}
            max={max}
            marks={marks}
            aria-labelledby="discrete-slider"
          />
        ) : (
          <Slider
            defaultValue={30}
            aria-labelledby="discrete-slider"
            value={value}
            disabled={isQuestionComplete(question)}
            onChange={handleChange}
            step={interval || 1}
            min={min}
            max={max}
            marks={marks}
          />
        )}
      </div>
      <div className={classes.footer}>
        {showCheckAnswer ? (
          <div className={classes.checkButtonContainer}>
            <Button color="secondary" variant="contained" onClick={checkAnswer}>
              Check Answer
            </Button>
          </div>
        ) : (
          <Feedback question={question} value={value} />
        )}
      </div>
    </div>
  );
};

export default SliderAnswer;
