import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Modal,
  IconButton,
  Divider,
  Button,
  CardActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "../../LoadingButton";
import { axiosInstance, API_ENDPOINT } from "../../../api/core";
import getPosition from "./getPosition";
import BodyContent from "./BodyContent";
import api from "../../../api/endpoints";

const cardStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  maxWidth: "100%",
  maxHeight: "100%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const DownloadPass = ({
  open,
  handleClose,
  userCoordinates,
  mobileWebview,
}) => {
  const [position, setPosition] = useState();
  const [locations, setLocations] = useState();
  const [locationComplete, setLocationComplete] = useState(false);
  const [loadingPass, setLoadingPass] = useState(false);

  useEffect(() => {
    if (userCoordinates) {
      setPosition({
        lat: userCoordinates[0],
        lng: userCoordinates[1],
      });

      setLocationComplete(true);
    } else {
      getPosition(setPosition, () => setLocationComplete(true));
    }
  }, [userCoordinates]);

  const savePass = () => {
    setLoadingPass(true);
    function download(blob, filename) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename; // the filename you want
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }

    function getCoordinates(places = []) {
      const results = places.map((place) => ({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));

      return results;
    }

    axiosInstance
      .post(
        `${API_ENDPOINT}/er_consent_passes/create_pass`,
        { locations: getCoordinates(locations) },
        { responseType: "blob" }
      ) // Ensure responseType is 'blob'
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        let filename = "pass.pkpass";
        if (contentDisposition && contentDisposition.includes("filename=")) {
          filename = contentDisposition
            .split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
        }

        download(response.data, filename);
        setLoadingPass(false);
        window.alert(
          "Check your downloads for the pass file, open it, and add it to your wallet."
        );

        api.activity.logEvent({
          event_category: "PREVENT_CONSENT_INTERACTION",
          event_action: "Apple Wallet Pass Downloaded",
        });
      })
      .catch((err) => {
        window.alert(`Error creating pass: `, err);
        setLoadingPass(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="download-pass-modal"
    >
      <Card
        sx={{
          ...cardStyle,
          display: "flex",
          flexDirection: "column",
          height: {
            xs: "100%",
            sm: "100%",
            md: "auto",
          },
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6" fontWeight="bold">
              Save the ER Prevent Consent to Your Apple Wallet
            </Typography>
          }
          subheader="For iPhone, iPad and Mac users."
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent sx={{ overflowY: "scroll" }}>
          <BodyContent
            locationComplete={locationComplete}
            position={position}
            locations={locations}
            setLocations={setLocations}
            setLocationComplete={setLocationComplete}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            startIcon={<DownloadIcon />}
            disabled={!locationComplete}
            onClick={savePass}
            variant="contained"
            color="secondary"
            loading={loadingPass}
          >
            Save Wallet Pass to Your Apple Device
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default DownloadPass;
