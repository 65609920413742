import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ShareDialogue from "../ShareDialogue";

const questionUrl = (question) =>
  `${window.location.origin}/quiz?share=${question.id}`;

const ShareQuestion = ({ question }) => {
  const [showDialogue, setShowDialogue] = useState(false);

  const url = questionUrl(question);

  const handleShareClick = (e) => {
    setShowDialogue(true);
  };

  return question !== null ? (
    <React.Fragment>
      <ShareDialogue
        open={showDialogue}
        onClose={() => setShowDialogue(false)}
        shareUrl={url}
        questionId={question.id}
      />

      <Tooltip title="Share Question">
        <IconButton
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
          variant="outlined"
          aria-label="share question"
          onClick={handleShareClick}
          style={{ color: "white" }}
          color="secondary"
          size="small"
        >
          <ShareIcon style={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <IconButton
        sx={(theme) => ({
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "block",
          },
        })}
        style={{ color: "white" }}
        color="secondary"
        size="small"
        onClick={handleShareClick}
      >
        <ShareIcon style={{ color: "white" }} />
      </IconButton>
    </React.Fragment>
  ) : null;
};

export default ShareQuestion;
