import React, { Fragment } from "react";
import {
  Stack,
  CardContent,
  Typography,
  Divider,
  Link,
  Card,
  CardHeader,
  IconButton,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";

import CloseIcon from "@mui/icons-material/Close";
import QuestionsList from "./QuestionsList";

const isQuestion = (str) => {
  return str.includes("?") & (str.slice(0, 1) === '"');
};

const RATINGS_HEADER = "User Ratings and Reviews for Likely Prescriptions";

const CribModal = ({
  title,
  subtitle,
  preparations,
  questionsByCategory,
  sources,
  handleClose,
}) => {
  const questionsToAsk = questionsByCategory?.filter(
    (q) => q.category === "outgoing"
  );

  const questionsToExpect = questionsByCategory?.filter(
    (q) => q.category === "incoming"
  );

  const ratings = questionsByCategory?.filter((q) => q.category === "ratings");

  return (
    <Card
      sx={{
        maxWidth: "100vw",
        width: 500,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        title={title}
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent sx={{ overflowY: "auto" }}>
        <Stack spacing={1}>
          {subtitle && (
            <>
              <Typography fontWeight="bold">Overview</Typography>
              <Typography>{ReactHtmlParser(subtitle)}</Typography>
            </>
          )}
          {preparations && (
            <>
              <Divider />
              <Typography fontWeight="bold">
                Preparing for Your Visit
              </Typography>
              <Typography>{ReactHtmlParser(preparations)}</Typography>
            </>
          )}
          <QuestionsList
            title="Questions to Ask The Doctor"
            questions={questionsToAsk}
          />
          <QuestionsList
            doctor
            title="What the Doctor May Ask or Say"
            questions={questionsToExpect.filter((q) => isQuestion(q.content))}
          />
          <QuestionsList
            doctor
            info
            title="What the Doctor May Do, Test or Prescribe"
            questions={questionsToExpect.filter((q) => !isQuestion(q.content))}
          />
          <QuestionsList info title={RATINGS_HEADER} questions={ratings} />

          <Divider />
          {sources && sources.length > 0 ? (
            <Stack direction="row" spacing={1}>
              <Typography variant="caption">Sources for More Info:</Typography>
              <Typography variant="caption">
                {sources.map((source, i) => {
                  let prefix = "";
                  if (i !== 0) {
                    prefix = ", ";
                  }
                  if (source.title) {
                    return (
                      <Fragment key={source.title + source.url}>
                        {prefix}
                        <Link {...source} href={source.url} variant="caption">
                          {source.title}
                        </Link>
                      </Fragment>
                    );
                  }

                  return (
                    <Fragment key={source.title + source.url}>
                      {prefix}
                      <Link {...source} href={source.url} variant="caption">
                        {source.url}
                      </Link>
                    </Fragment>
                  );
                })}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CribModal;
