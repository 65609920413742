import React, { useState, useEffect, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import { Typography, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    minWidth: 64,
  },
  tipsContainer: {
    width: "calc(100% - 128px) !important",
  },
  tipContainer: {
    height: "100%",
  },
  tip: {
    padding: theme.spacing(2),
  },
}));

const Carousel = ({ items, small, interval = 6000 }) => {
  const [index, setIndex] = useState(0);
  const [autoRotate, setAutoRotate] = useState(true);
  const classes = useStyles();

  const increment = useCallback(() => {
    if (autoRotate && index < items.length - 1) {
      setIndex((i) => i + 1);
    }
  }, [autoRotate, index, items]);

  useEffect(() => {
    const carouselInterval = setInterval(increment, interval);

    // Specify how to clean up after this effect:
    return function cleanup() {
      clearInterval(carouselInterval);
    };
  }, [increment, interval]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid className={classes.buttonContainer} item align="left">
        <Button
          onClick={() => {
            setIndex(index - 1);
            setAutoRotate(false);
          }}
          disabled={index === 0}
        >
          <ArrowBackIosIcon />
        </Button>
      </Grid>
      <Grid className={classes.tipsContainer} item>
        <SwipeableViews index={index}>
          {items.map((i) => (
            <Grid
              className={classes.tipContainer}
              key={i}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  className={classes.tip}
                  align="left"
                  variant="body1"
                >
                  {i}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </SwipeableViews>
      </Grid>
      <Grid className={classes.buttonContainer} item align="right">
        <Button
          onClick={() => {
            setIndex(index + 1);
            setAutoRotate(false);
          }}
          disabled={index === items.length - 1}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Carousel;
