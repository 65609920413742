import React, { useState } from "react";
import {
  Card,
  Tooltip,
  Button,
  Modal,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import copyToClipboard from "copy-to-clipboard";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EmailIcon from "@mui/icons-material/Email";
import analytics from "../../analytics";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxWidth: "100%",
    backgroundColor: theme.palette.white,
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
}));

const ShareDialogue = ({
  onClose,
  shareUrl,
  isQuiz,
  questionId,
  quizId,
  ...rest
}) => {
  const classes = useStyles();

  const [alert, setAlert] = useState();

  /* Track a page view */
  analytics.page("Share Dialogue Opened: " + isQuiz ? `Quiz` : "Question");

  // @TODO make this work for logged in users
  // /* Identify a visitor */
  // analytics.identify('user-id-xyz', {
  //   firstName: 'bill',
  //   lastName: 'murray',
  //   email: 'da-coolest@aol.com'
  // })

  return (
    <>
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={() => setAlert(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setAlert(false)}
          severity="success"
        >
          {alert}
        </Alert>
      </Snackbar>
      <Modal {...rest} onClose={onClose}>
        <Card className={classes.paper}>
          <CardHeader
            title={`Share ${isQuiz ? "Quiz" : "Question"}`}
            subheader={`Use the link below to share this ${
              isQuiz ? "quiz" : "question"
            }`}
            avatar={<ShareIcon />}
            action={
              <Tooltip title="Close">
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Sharing Link:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Link
                    href={shareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {shareUrl}
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<FileCopyIcon />}
                  variant="outlined"
                  onClick={() => {
                    setAlert("Clicked Copy Share Link to Clipboard");

                    analytics.track("Share Link Copied", {
                      quizId,
                      questionId,
                      isQuiz,
                    });

                    copyToClipboard(shareUrl);
                  }}
                >
                  Copy to Clipboard
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<EmailIcon />}
                  variant="outlined"
                  onClick={() => {
                    analytics.track("Clicked Email Share Link", {
                      quizId,
                      questionId,
                      isQuiz,
                    });

                    setAlert("Email Opened in Your Default Client");
                    window.location.href = `mailto:?subject=Look what I've learned about health from Quizzify!&body=${encodeURIComponent(
                      `I found this interesting and thought you would too: ${shareUrl}`
                    )}`;
                  }}
                >
                  Email Link
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button onClick={onClose}>Close</Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default ShareDialogue;
