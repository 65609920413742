import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import pluralize from "pluralize";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  CardActions,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { lighten } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import _ from "lodash";
import QuizPreview from "./QuizPreview";
import { context } from "../AuthContext";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  specialHeader: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.75),
    color: theme.palette.secondary.contrastText,
  },
  successIcon: {
    color: theme.palette.common.green,
  },
}));

const getButtonText = (hasGames, lastGameIncomplete, needsLogin) => {
  if (needsLogin) return "Log in to Play";
  if (lastGameIncomplete) return "Continue Quiz";
  if (hasGames) return "Play Again";
  return "Play Quiz";
};

const Quiz = ({ quiz, latest, use_points, flat, onClick = () => {} }) => {
  const { state } = useContext(context);

  const classes = useStyles();
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const needsLogin = !quiz.is_public && !state.isLoggedIn;
  const sso_url = state.config?.auth_format === "sso" && state.config?.sso_url;

  const { games = [], bannerImages = [] } = quiz;
  const hasGames = games.length > 0;
  const lastGame = _.last(games);
  const lastGameIncomplete = lastGame && !lastGame.complete;
  const completed = games.find((g) => g.complete);
  const usePoints = use_points !== false; // Weird construction since it might be undefined

  const bannerImage =
    bannerImages[Math.floor(Math.random() * bannerImages.length)];

  const renderImage = (bannerImage) => {
    const url = _.get(bannerImage, "url");
    if (!url) return <Divider />;

    if (url.includes("mp4"))
      return (
        <video
          style={{ width: "100%", maxHeight: 225, objectFit: "cover" }}
          autoPlay
          muted
          loop
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );

    return (
      <img
        src={bannerImage.url}
        alt={bannerImage.alt}
        style={{ width: "100%", maxHeight: 225, objectFit: "cover" }}
      />
    );
  };

  const timesCompleted = games.filter((g) => g.complete).length;

  return (
    <Card raised={!flat}>
      {latest && (
        <CardHeader
          avatar={<ScheduleIcon />}
          title="Latest Quiz"
          className={classes.specialHeader}
        />
      )}

      <CardHeader
        title={
          <Grid container spacing={1} alignItems="middle">
            {completed && (
              <Grid item>
                <CheckCircleIcon className={classes.successIcon} />
              </Grid>
            )}
            <Grid item>
              <Typography sx={{ fontSize: 20, fontWeight: 400 }}>
                {quiz.name}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      {renderImage(bannerImage)}
      <CardContent>
        <Typography variant="subtitle1">{quiz.description}</Typography>
        {hasGames &&
          (usePoints ? (
            <>
              <Typography variant="overline" style={{ marginTop: 10 }}>
                Your Scores
              </Typography>
              <List dense>
                {games.map((g) => (
                  <ListItem key={g.id} style={{ padding: 0 }}>
                    <ListItemIcon style={{ minWidth: "auto", marginRight: 8 }}>
                      {g.complete ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={`Game ${g.number}: ${g.points_earned}`}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : timesCompleted > 0 ? (
            <Typography
              variant="subtitle1"
              style={{ marginTop: 10, fontWeight: "bold" }}
            >
              You completed this quiz {pluralize("times", timesCompleted, true)}
              !
            </Typography>
          ) : null)}
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          type="button"
          onClick={(e) => {
            onClick();
            e.preventDefault();

            if (needsLogin && sso_url) {
              window.parent.location.href = sso_url;
            } else {
              history.push(`/quiz/${quiz.id}`);
            }
          }}
          fullWidth={!quiz.questions?.length}
          variant={"contained"}
          color={"secondary"}
          startIcon={<PlayCircleOutlineIcon />}
        >
          {getButtonText(hasGames, lastGameIncomplete, needsLogin)}
        </Button>
        {quiz.questions?.length > 0 && (
          <Button
            onClick={handleExpandClick}
            endIcon={
              <ExpandMoreIcon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              />
            }
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            })}
          >
            Preview
          </Button>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <QuizPreview quiz={quiz} />
      </Collapse>
    </Card>
  );
};

export default Quiz;
