import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HomeIcon from "@mui/icons-material/Home";

const ClassicButton = ({ isLoggedIn, companyId, buttonOnly }) => {
  const history = useHistory();

  return isLoggedIn ? (
    <Button
      startIcon={<HomeIcon />}
      variant="contained"
      color="secondary"
      size="large"
      sx={{ fontSize: 18, width: "100%" }}
      href={`/${companyId}`}
    >
      Proceed to the Home Page
    </Button>
  ) : (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        sx={{ fontSize: 18, width: "100%" }}
        startIcon={<PlayCircleOutlineIcon />}
        onClick={() => {
          history.push(`/${companyId}?login=true`);
        }}
      >
        Get Started!
      </Button>
      {!buttonOnly && (
        <Typography sx={{ textAlign: "center" }}>
          (You'll be invited to create an account or login to an existing
          account.)
        </Typography>
      )}
    </>
  );
};

export default ClassicButton;
