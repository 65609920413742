import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Container,
  Box,
  CardHeader,
  Card,
  CardContent,
  Divider,
  CardActions,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import api from "../../api/endpoints";
import { context } from "../AuthContext";

const WelcomeScreen = () => {
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState();

  const { state } = useContext(context);
  const { isLoggedIn } = state;

  useEffect(() => {
    api.quiz
      .welcome(quizId)
      .then((res) => setQuiz(res.data.quiz))
      .catch((err) => window.alert(err));
  }, [quizId]);

  if (quiz) {
    console.log(quiz.description);
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        height: "100%",
      })}
    >
      <Container
        maxWidth="sm"
        sx={(theme) => ({
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 3,
          overflowY: "auto",
          boxSizing: "border-box",
          [theme.breakpoints.down("sm")]: {
            paddingTop: 3,
            paddingBottom: 7,
          },
        })}
      >
        {quiz && (
          <Box>
            <Card>
              <CardHeader title={quiz.name} />
              {quiz.banner_image ? (
                <img
                  src={quiz.banner_image.url}
                  alt={quiz.banner_image.alt}
                  style={{
                    width: "100%",
                    maxHeight: 200,
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Divider />
              )}

              {quiz.description && (
                <>
                  <CardContent>
                    <Typography
                      paragraph
                      dangerouslySetInnerHTML={{
                        __html: quiz.description.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br>"
                        ),
                      }}
                    />
                  </CardContent>
                  <Divider />
                </>
              )}

              <CardActions
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                })}
              >
                <Button
                  startIcon={<PlayArrowIcon />}
                  href={`/quiz/${quizId}`}
                  variant="contained"
                  color="secondary"
                >
                  {isLoggedIn
                    ? "Get Started"
                    : "Register or Login to Get Started"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        )}
      </Container>
      <Button
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        })}
        startIcon={<PlayArrowIcon />}
        href={`/quiz/${quizId}`}
        variant="contained"
        color="secondary"
      >
        {isLoggedIn ? "Get Started" : "Register or Login to Get Started"}
      </Button>
    </Box>
  );
};

export default WelcomeScreen;
