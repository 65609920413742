import React, { useContext } from "react";
import { Card, CardActions, Grid } from "@mui/material";

import { context as quizContext } from "./QuizContext";
import PrimaryButton from "./PrimaryButton";
import QuizScore from "./QuizScore";

const QuizActions = (props) => {
  const { state } = useContext(quizContext);
  const { isNotScored, lang } = props;

  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        maxWidth: "100%",
        padding: 0,
        border: "none",
        position: "sticky",
        bottom: 0,
        borderTop: `1px solid ${theme.palette.primary.light}`,
      })}
    >
      <CardActions>
        <Grid container alignItems="center" justifyContent="space-between">
          <QuizScore lang={lang} isNotScored={isNotScored} {...state} />

          <Grid item sx={{ marginLeft: "auto" }}>
            <PrimaryButton lang={lang} {...props} />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default QuizActions;
