import React from "react";
import {
  Typography
} from "@mui/material";

const GamesCounter = ({ currentGamesCount }) => (
  <Typography sx={{ fontSize: 16, height: 20, marginBottom: 2 }}>
    { currentGamesCount > 0 ? (
      <>Currently playing: { currentGamesCount }</>
    ) : null }
  </Typography>
);

export default GamesCounter;
