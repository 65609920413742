import { useEffect } from "react";

const ZendeskLoader = () => {
  useEffect(() => {
    const onScriptLoaded = () => {
      if (window && window.zE) {
        window.zE("webWidget", "hide");

        window.zE("webWidget:on", "close", function () {
          window.zE("webWidget", "hide");
        });
      }
    };

    // Start of quizzify Zendesk Widget script
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`;

    script.async = true;
    script.addEventListener("load", onScriptLoaded);

    document.body.appendChild(script);
    // End of quizzify Zendesk Widget script

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ZendeskLoader;
