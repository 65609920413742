import React, { useContext, useEffect, useCallback, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { context, actions } from "./AuthContext";
import { context as publicHomeContext } from "./PublicHome/PublicHomeContext";
import { context as quizContext } from "./Quiz/QuizContext";
import Login from "./LoginForm/Login";
import api from "../api/endpoints";
import { Loader } from "./Loader";
import { setAuthToken } from "../api/core";

const Authorization = ({ children }) => {
  const { state, dispatch } = useContext(context);
  const { state: homeState } = useContext(publicHomeContext);
  const { state: quizState } = useContext(quizContext);

  const [loginErrors, setLoginErrors] = useState();

  const history = useHistory();

  history.listen((route) => {
    if (!route.pathname.includes("/quiz")) {
      dispatch({
        type: actions.clearPromptLogin,
      });
    }
  });

  const pathname = window.location.pathname;

  const parsedString = queryString.parse(window.location.search);

  const {
    code,
    auth_token,
    company_id: paramsCompanyId,
    api_key,
  } = parsedString;

  // Need this kludgee since Auth is outside of Router
  const quizId = (() => {
    if (window.location.pathname.includes("/quiz/")) {
      const id = _.first(
        window.location.pathname.match(/^\d+|\d+\b|\d+(?=\w)/g)
      );
      return id;
    }
  })();

  // Need this kludgee since Auth is outside of Router
  const routerParamsCompanyId = (() => {
    if (!isNaN(window.location.pathname.slice(1))) {
      const id = _.first(
        window.location.pathname.match(/^\d+|\d+\b|\d+(?=\w)/g)
      );
      return id;
    }
  })();

  const companyId =
    routerParamsCompanyId ||
    paramsCompanyId ||
    _.get(state, "companyId") ||
    _.get(homeState, "id") ||
    _.get(quizState, "company.id");

  const { promptLogin, config = {}, doNotCheckAuth, isLoggedIn } = state;

  // TODO this and the history.listen above sucks and needs to be fixed
  useEffect(() => {
    if (!isLoggedIn && !api_key) {
      if (pathname.match(/\/\d+\/togo/) || pathname.match(/\/\d+\/er/)) {
        dispatch({
          type: actions.promptLogin,
          companyId: pathname.split("/")[1],
        });
      }
    }
  }, [pathname, dispatch, isLoggedIn, api_key]);

  // Remove the code after it's been processed
  const clearCode = useCallback(() => {
    delete parsedString["code"];
    delete parsedString["auth_token"];

    const search = queryString.stringify(parsedString);

    let url = `${window.location.origin}${window.location.pathname}`;

    if (search) {
      url += `?${search}`;
    }

    window.history.replaceState({}, document.title, url);
  }, [parsedString]);

  const handleLogin = useCallback(
    (values) => {
      setLoginErrors();

      const { loginType, password } = values;

      if (loginType === "set_pw") {
        return api.auth
          .setPassword(password)
          .then(() => {
            // Remove the login=true param
            window.location.href = `${window.location.origin}${window.location.pathname}`;

            dispatch({
              type: actions.login,
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      }

      if (loginType === "lookup") {
        return api.auth
          .getAuthConfig(values)
          .then((res) => {
            dispatch({
              type: actions.loadConfig,
              config: res.data.config,
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      }

      let redirect_url = _.get(window, "location.href");

      const hasLoginParam = window.location.search === "?login=true";

      if (hasLoginParam) {
        redirect_url = `${window.location.origin}${window.location.pathname}`;
      }

      return api.auth
        .login({
          ...values,
          redirect_url,
        })
        .then((res) => {
          const { auth_token: authToken, config, message } = res.data;

          if (code) {
            clearCode();
          }

          if (authToken) {
            dispatch({
              type: actions.login,
              authToken,
            });

            if (Boolean(parsedString.redirect_to)) {
              history.push(parsedString.redirect_to);
            } else if (Boolean(parsedString.redirect_to_togo)) {
              api
                .getTogoLink()
                .then((response) => {
                  if (response.data.togo_redirect) {
                    try {
                      // To get around the iframe nesting
                      const link = document.createElement("a");
                      link.href = response.data.togo_redirect;
                      link.target = "_top";
                      link.click();
                    } catch (error) {
                      console.error(error);
                      window.location.href = response.data.togo_redirect;
                    }
                  }
                })
                .catch((error) => window.alert(error));
            } else if (hasLoginParam) {
              history.push(window.location.pathname);
            }
          } else if (config) {
            dispatch({
              type: actions.loadConfig,
              config,
            });

            if (config.auth_token) {
              setAuthToken(config.auth_token);
            }
          } else if (message) {
            window.alert(message);
          } else {
            window.alert("Login failed");
          }
        })
        .catch((err) => {
          const errorData = _.get(err, "response.data", {});

          if (code) {
            clearCode();
          }

          const { config, error, errors } = errorData;

          if (config) {
            dispatch({
              type: actions.loadConfig,
              config,
            });
          } else if (errors) {
            setLoginErrors(errors);
          } else {
            window.alert(`Login failed\n\n${error || err}`);
          }
        });
    },
    [dispatch, clearCode, code, parsedString, history]
  );

  const handleCancel = () => {
    if (config.step === 2) {
      api.auth
        .getAuthConfig({ companyId, quizId })
        .then((res) => {
          dispatch({
            type: actions.loadConfig,
            config: res.data.config,
            replace: true,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (auth_token) {
      localStorage.setItem("authToken", auth_token);
      clearCode();
    } else if (code) {
      handleLogin({ code });
    } else if (!doNotCheckAuth) {
      api.auth
        .verify()
        .then(() => {
          dispatch({
            type: actions.authVerified,
          });
        })
        .catch(() => {
          dispatch({
            type: actions.authNotVerified,
          });
        });
    }
  }, [handleLogin, code, auth_token, dispatch, doNotCheckAuth, clearCode]);

  const loadConfig = useCallback(
    (config) => {
      dispatch({
        type: actions.loadConfig,
        config,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      api.auth
        .getAuthConfig({ companyId, quizId })
        .then((res) => {
          loadConfig(_.get(res, "data.config"));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [loadConfig, companyId, quizId, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      api.auth
        .checkRoster()
        .then(({ data: { fails_roster_check } }) => {
          if (fails_roster_check) {
            history.push("/not-on-roster");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    if (isLoggedIn) {
      api.auth
        .profile()
        .then((response) => {
          dispatch({
            type: actions.setUserProfile,
            userProfile: response.data.current_user,
          });

          console.log("SET PROFILE RAN");
        })
        .catch((err) => console.log("Profile Error: ", err));
    }
  }, [isLoggedIn, dispatch]);

  if (code) {
    return <Loader />;
  }

  if (promptLogin) {
    return (
      <Login
        onLogin={handleLogin}
        config={config}
        onCancel={handleCancel}
        loginErrors={loginErrors}
      />
    );
  }
  return <>{children}</>;
};

export default Authorization;
