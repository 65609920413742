import React from "react";
import { Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useMobileBreakpoint } from "../utils/responsive";

const useStyles = makeStyles((theme) => ({
  grid: {
    "min-height": "calc(100vh - 64px)",
  },
  mobileCard: {
    "border-radius": 0,
    "margin-bottom": 0,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    "min-height": "calc(100vh - 60px)",
  },
  card: {
    "margin-top": "4rem",
    "margin-bottom": "2rem",
    color: theme.palette.text.secondary,
  },
}));

const ResponsiveContainer = ({ embedView, children, style }) => {
  const classes = useStyles();
  const isMobile = useMobileBreakpoint();

  if (embedView) return children;

  return (
    <Grid className={classes.grid} container justifyContent="center">
      <Grid item xs={12} sm={10} md={8} lg={7} xl={6} style={style}>
        <Card
          className={isMobile ? classes.mobileCard : classes.card}
          style={style}
        >
          {children}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResponsiveContainer;
