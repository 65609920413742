import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import Question from "../Question";

const BasicQuiz = ({ sidebar, sidebarCount, ...rest }) => (
  <Card raised sx={{ height: "100%" }}>
    <Grid container sx={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12 - sidebarCount * 3}
        sx={{
          height: "100%",
        }}
      >
        <Question {...rest} />
      </Grid>
      {sidebar && (
        <Grid item xs={12} sm={3}>
          <Card style={{ height: "100%" }}>
            <CardContent>{ReactHtmlParser(sidebar)}</CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  </Card>
);

export default BasicQuiz;
