import { createSlice } from "@reduxjs/toolkit";

export const spouseSlice = createSlice({
  name: "spouseSettings",
  initialState: {
    canInviteSpouse: false,
    spouse: null,
    pendingInviteEmail: null,
  },
  reducers: {
    loadState: (_, action) => {
      return action.payload;
    },
    updatePendingInviteEmail: (state, action) => {
      state.pendingInviteEmail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loadState, updatePendingInviteEmail } = spouseSlice.actions;

export default spouseSlice.reducer;
