import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";

const TogoButton = ({
  isLoggedIn,
  togoAccess,
  companyId,
  isMobile,
  buttonOnly,
}) => {
  const history = useHistory();

  return isLoggedIn && togoAccess ? (
    <Button
      startIcon={<InstallMobileIcon />}
      variant="contained"
      color="secondary"
      size="large"
      sx={{ fontSize: 18, width: "100%" }}
      href={isMobile ? `/${companyId}/togo-download` : togoAccess.togo_redirect}
      target={isMobile ? "_self" : "_blank"}
      rel={isMobile ? "" : "noopener noreferrer"}
    >
      Proceed to Quizzify2Go
    </Button>
  ) : (
    <>
      <Button
        startIcon={<InstallMobileIcon />}
        variant="contained"
        color="secondary"
        size="large"
        sx={{ fontSize: 18, width: "100%" }}
        onClick={() => {
          isMobile
            ? history.push(
                `/login?company_id=${companyId}&redirect_to=/${companyId}/togo-download`
              )
            : history.push(
                `/login?company_id=${companyId}&redirect_to_togo=true`
              );
        }}
      >
        Get Started!
      </Button>
      {!buttonOnly && (
        <Typography sx={{ textAlign: "center" }}>
          (You'll be invited to create an account or login to an existing
          account.)
        </Typography>
      )}
    </>
  );
};

export default TogoButton;
