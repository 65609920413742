import React from "react";
import { Box, Typography } from "@mui/material";
import DetailButton from "./DetailButton";

const AnswerDetails = ({ details }) => {
  if (!details) return null;

  return details.map((detail) => (
    <Box key={detail.id}>
      {detail.preceding_text && (
        <Typography>{detail.preceding_text}</Typography>
      )}
      {detail.detail_type === "text" && (
        <Typography>{detail.display_name}</Typography>
      )}
      {detail.detail_type === "button" && <DetailButton detail={detail} />}
      {detail.following_text && (
        <Typography>{detail.following_text}</Typography>
      )}
    </Box>
  ));
};

export default AnswerDetails;

// create_table "answer_details", id: :uuid, default: -> { "gen_random_uuid()" }, force: :cascade do |t|
// t.integer "detail_type", default: 0, null: false
// t.string "url"
// t.string "display_name"
// t.integer "open_as", default: 0, null: false
// t.string "preceding_text"
// t.string "following_text"
// t.integer "position", default: 0, null: false
// t.datetime "created_at", null: false
// t.datetime "updated_at", null: false
// end

// enum detail_type: {
//   button: 0,
//   link: 1,
//   label: 2,
//   chip: 3
// }

// enum open_as: {
//   new_tab: 0,
//   same_tab: 1,
//   modal: 2
// }

// enum position: {
//   after_headline: 0,
//   after_feedback: 1
// }
