import React from "react";
import { LinearProgress } from "@mui/material";
import isQuestionComplete from "./isQuestionComplete";

const QuizProgress = ({ state }) => {
  let value = state.questionIndex;

  try {
    const { questions } = state.quiz;
    const numberComplete = questions.reduce(
      (acc, cur) => acc + isQuestionComplete(cur),
      0
    );
    value = numberComplete;
  } catch (err) {
    console.error(err);
  }

  return (
    <LinearProgress
      variant="determinate"
      value={(value / state.quiz.questions.length) * 100}
      sx={(theme) => ({
        display: "flex",
        flexShrink: 0,
        position: "sticky",
        top: 0,
        height: 12,
        zIndex: 100,
        backgroundColor: "rgb(236, 255, 251)",
        "& .MuiLinearProgress-bar": {
          backgroundColor: theme.palette.secondary.main,
        },
      })}
    />
  );
};

export default QuizProgress;
