import React, { useState } from "react";
import { Button, Card, CardContent, Typography, Stack } from "@mui/material";
import api from "../../api/endpoints";

const BOOK_CALL_LINK =
  "https://calendly.com/d/cp8r-kpy-3tx/exploring-quizzify-s-solutions";

const submitEvent = (event) => {
  api.salesBehavior.eventTriggered(event);
};

const SalesPitch = ({ quiz, user }) => {
  const [showFallback, setShowFallback] = useState();

  if (quiz?.company?.sales_mode === "non_sales") {
    return null;
  }

  const eventValues = {
    user,
    quiz,
    location: "sales_pitch_post_quiz_view",
  };

  const submitEventByName = (name) =>
    submitEvent({
      ...eventValues,
      name,
    });

  if (showFallback) {
    return (
      <Card
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
        })}
        raised
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography sx={{ color: "white" }} variant="h6">
              Is it worth a quick call to see how...
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                submitEventByName(
                  "Clicked on 'Quizzify can contain your GLP-1 costs?' button"
                );

                window.location.href = BOOK_CALL_LINK;
              }}
            >
              Quizzify can contain your GLP-1 costs?
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                submitEventByName(
                  "Clicked on 'Our health literacy education engages employees far more than traditional wellness programs?' button"
                );

                window.location.href = BOOK_CALL_LINK;
              }}
            >
              Our health literacy education engages employees far more than
              traditional wellness programs?
            </Button>
            <Button
              variant="contained"
              color="secondary"
              href={BOOK_CALL_LINK}
              onClick={() => {
                submitEventByName(
                  "Clicked on 'This program can be implemented within the week, and requires minimal management?' button"
                );

                window.location.href = BOOK_CALL_LINK;
              }}
            >
              This program can be implemented within the week, and requires
              minimal management?
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
      })}
      raised
    >
      <CardContent>
        <Stack spacing={2}>
          <Typography sx={{ color: "white" }} variant="h6">
            Is it worth bringing health literacy education like this to your
            team for a modest fee?
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              submitEventByName("Clicked on 'Yes, show me how' button");
              window.location.href = "/pilot/offer"; // Manually navigate after the event
            }}
          >
            Yes, show me how
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              submitEventByName(
                "Clicked on 'No, I don't think health literacy is important' button"
              );
              setShowFallback(true);
            }}
          >
            No, I don't think health literacy is important
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SalesPitch;
