import React from "react";
import { makeStyles } from "@mui/styles";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.dark,
  },
  successIcon: {
    color: theme.palette.common.green,
  },
}));

const FeedbackIcon = ({ wasSelected, isCorrect }) => {
  const classes = useStyles();

  if (wasSelected) {
    return isCorrect ? (
      <CheckCircleIcon className={classes.successIcon} />
    ) : (
      <ErrorIcon className={classes.errorIcon} />
    );
  }

  return <RadioButtonUncheckedIcon />;
};

export default FeedbackIcon;
