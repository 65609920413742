import React from "react";
import { useSelector } from "react-redux";
import { Button, MenuItem, Stack, Typography } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";

const SpouseButton = ({ menuItem, handleClick }) => {
  const state = useSelector((state) => state.spouseSettings);

  const { canInviteSpouse, spouse } = state;

  if (!canInviteSpouse && !spouse) return null;

  const hasPostInviteSpouse = spouse && !spouse.fake_email;

  const title = hasPostInviteSpouse ? "Your Spouse" : "Invite Spouse";
  const icon = hasPostInviteSpouse ? <PeopleIcon /> : <GroupAddIcon />;

  if (menuItem)
    return (
      <MenuItem onClick={handleClick}>
        <Stack direction="row" spacing={1} alignItems="center">
          {icon}
          <Typography>{title}</Typography>
        </Stack>
      </MenuItem>
    );

  return (
    <Button startIcon={icon} onClick={handleClick}>
      {title}
    </Button>
  );
};

export default SpouseButton;
