import React, { useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { sort } from "./sorting";

const TableHeader = ({ orderBy, onChange }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          Rank
        </TableCell>
        <TableCell>
          Name
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            active={orderBy === 'first'}
            direction="desc"
            onClick={() => onChange('first')}
          >
            First game
          </TableSortLabel>
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            active={orderBy === 'max'}
            direction="desc"
            onClick={() => onChange('max')}
          >
            Highest score
          </TableSortLabel>
        </TableCell> 
        <TableCell align="right">
          <TableSortLabel
            active={orderBy === 'average'}
            direction="desc"
            onClick={() => onChange('average')}
          >
            Average score
          </TableSortLabel>
        </TableCell>
        <TableCell align="right">
          Max questions complete
        </TableCell>
        <TableCell align="center">
          Completed
        </TableCell>
      </TableRow>
    </TableHead>
  )
};

const useStyles = makeStyles((theme) => ({
  successIcon: {
    color: theme.palette.common.green,
  }
}));

const SortableTable = ({ rows }) => {
  const [orderBy, setOrderBy] = useState('first');
  const visibleRows = useMemo(
    () => sort(rows, orderBy),
    [rows, orderBy]
  );
  const classes = useStyles();

  return (
    <Flipper flipKey={visibleRows.map(({ user }) => user.id).join("-")}>
      <TableContainer>
        <Table>
          <TableHeader
            orderBy={orderBy}
            onChange={(newOrderBy) => setOrderBy(newOrderBy)}
          />
          <TableBody>
            {
              visibleRows.length > 0 ? (
                visibleRows.map(
                  (row, index) => (
                    <Flipped flipId={row.user.id}>
                      <TableRow hover={true} key={row.user.id}>
                        <TableCell>{ index + 1 }</TableCell>
                        <TableCell>{ row.user.name }</TableCell>
                        <TableCell align="right">{ row.first }</TableCell>
                        <TableCell align="right">{ row.max }</TableCell>
                        <TableCell align="right">{ row.average }</TableCell>
                        <TableCell align="right">{ row.max_questions_completed }</TableCell>
                        <TableCell align="center">{ !row.completed ? <CheckCircleIcon className={classes.successIcon} /> : <BlockIcon /> }</TableCell>
                      </TableRow>
                    </Flipped>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan="5" align="center">
                    No players yet!
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Flipper>
  )
};

export default SortableTable;
