import { Slider } from "@mui/material";
import { withStyles } from "@mui/styles";

const QuizzifySlider = withStyles({
  root: {
    height: 8,
    backgroundColor: "#fff",
  },
  thumb: {
    height: "24px !important",
    width: "24px !important",
    backgroundColor: "#fff",
    border: `2px solid currentColor`,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 6,
    borderRadius: 6,
    borderWidth: 2,
    borderStyle: "solid",
  },
  rail: {
    height: 6,
    borderRadius: 6,
    borderWidth: 2,
    borderStyle: "solid",
    backgroundColor: "#fff",
  },
  mark: {
    backgroundColor: "transparent",
  },
})(Slider);

export default QuizzifySlider;
