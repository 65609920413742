import keyMirror from "../../utils/keyMirror";
import { score as initialScore } from "../../StateModel";
import * as R from "ramda";

const pointValues = {
  incorrect: -25,
  correct: 100,
};

const { attemptsMade, questionIndex, score } = keyMirror({
  attemptsMade: null,
  questionIndex: null,
  score: null,
});

const questionLens = (questionIndex) =>
  R.lensPath(["quiz", "questions", questionIndex]);
const makeCurrentQuestionLens = R.compose(questionLens, R.prop(questionIndex));
const attemptsMadeLens = R.lens(R.prop(attemptsMade), R.assoc(attemptsMade));
const scoreLens = R.lensProp(score);

const increment = R.compose(R.inc, R.defaultTo(0));

const calculateTotalScore = R.pipe(
  R.view(R.lensPath(["quiz", "questions"])),
  R.reduce((acc, q) => {
    const {
      incorrectSelected,
      correctSelected,
      correctTotal,
      total,
    } = R.defaultTo(initialScore)(q.score);

    return R.evolve(
      {
        incorrectSelected: R.add(incorrectSelected),
        correctSelected: R.add(correctSelected),
        correctTotal: R.add(correctTotal),
        total: R.add(total),
      },
      acc
    );
  }, initialScore)
);

const updateTotalScore = R.converge(R.set, [
  R.always(scoreLens),
  calculateTotalScore,
  R.identity,
]);

const checkCorrect = (tolerance, solution, value) =>
  R.compose(R.lte(R.__, tolerance), Math.abs, R.subtract(solution))(value);

const updateQuestionScore = R.curry(({ value }, state) => {
  const questionLens = makeCurrentQuestionLens(state);
  const { solution, tolerance } = R.view(questionLens, state);
  const isCorrect = checkCorrect(tolerance, solution, value);
  const currentScoreLens = R.compose(questionLens, scoreLens);

  return R.over(
    currentScoreLens,
    R.evolve({
      incorrectSelected: R.ifElse(
        R.always(R.not(isCorrect)),
        R.inc,
        R.identity
      ),
      correctSelected: R.ifElse(R.always(isCorrect), R.inc, R.identity),
      total: R.ifElse(
        R.always(isCorrect),
        R.add(pointValues.correct),
        R.add(pointValues.incorrect)
      ),
    })
  )(state);
});

const makeAttemptsLens = R.converge(R.compose, [
  makeCurrentQuestionLens,
  R.always(attemptsMadeLens),
]);

const incrementAttempts = R.converge(R.over, [
  makeAttemptsLens,
  R.always(increment),
  R.identity,
]);

const onSelectSliderAnswer = (action) =>
  R.pipe(incrementAttempts, updateQuestionScore(action), updateTotalScore);

export default onSelectSliderAnswer;
