import React from "react";
import { Card, CardHeader } from "@mui/material";
import Question from "../Question";
import { MobileTableOfContents } from "./TableOfContents";

const MobileTocQuiz = (props) => {
  const { questions } = props;

  return (
    <Card raised sx={{ height: "100%" }}>
      <Question
        header={
          <CardHeader
            title={<MobileTableOfContents questions={questions} />}
            sx={(theme) => ({
              pt: 0,
              pb: 0,
              flexShrink: 0,
              backgroundColor: theme.palette.grey[100],
            })}
          />
        }
        {...props}
      />
    </Card>
  );
};

export default MobileTocQuiz;
