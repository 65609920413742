import React, { useRef, useEffect } from "react";
import { useCountUp } from "react-countup";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";

import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  scoreContainer: () => ({
    display: "flex",
    backgroundColor: theme.palette.grey[100],
    padding: ".5em 0.75em",
    borderRadius: 4,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: 0,
      backgroundColor: "white",
      paddingLeft: "8px",
    },
  }),
  scoreLabel: {
    color: theme.palette.grey[600],
    fontSize: "0.9rem",
    marginRight: "6px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      marginRight: "0px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px !important",
      marginRight: "0px !important",
    },
  },
  score: R.pipe(
    R.prop("change"),
    R.cond([
      [R.equals(0), R.always(theme.palette.primary.light)],
      [R.gt(0), R.always(theme.palette.error.dark)],
      [R.lt(0), R.always(theme.palette.common.green)],
    ]),
    R.objOf("color")
  ),
}));

const Points = ({ score, questionIndex, isNotScored, lang }) => {
  const { t } = useTranslation();

  const { total } = score;

  const prevTotalRef = useRef(total);
  const prevIndex = useRef(questionIndex);
  const classes = useStyles({ change: total - prevTotalRef.current });

  let { countUp, update } = useCountUp({
    start: total,
    end: total,
    delay: 0,
    duration: 0.75,
  });

  useEffect(() => {
    update(total);
    if (prevIndex.current !== questionIndex) {
      prevTotalRef.current = total;
      prevIndex.current = questionIndex;
    }
  }, [update, total, questionIndex]);

  return !isNotScored ? (
    <Grid item className={classes.scoreContainer}>
      <Typography
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        })}
        className={classes.scoreLabel}
        variant={"h6"}
      >
        {lang === "es" ? "Puntaje: " : t("totalScore")}
      </Typography>

      <Typography className={classes.score} variant="h6">
        <Typography
          sx={(theme) => ({
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "block",
            },
          })}
          className={classes.scoreLabel}
          variant="caption"
        >
          Score
        </Typography>
        {countUp}
      </Typography>
    </Grid>
  ) : (
    <Grid item />
  );
};

export default Points;
