import React from "react";
import { Link, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import isQuestionComplete from "./isQuestionComplete";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const LearnMoreLink = ({ question, lang, format }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!question.learnMoreUrl) return null;

  if (format === "after_question_complete" && !isQuestionComplete(question)) {
    return null;
  }

  return (
    <Link
      underline="none"
      href={question.learnMoreUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button color="secondary" className={classes.button} variant="contained">
        {lang === "es" ? "Aprende más" : t("Learn More")}
      </Button>
    </Link>
  );
};

export default LearnMoreLink;
