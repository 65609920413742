import React from "react";
import AnswerList from "./AnswerList";
import SliderAnswer from "./SliderAnswer";
import TileAnswers from "./TileAnswers";
import { slider, tiles } from "../../../../utils/formats";

const R = require("ramda");

const Answers = ({ question }) =>
  R.cond([
    [
      R.pipe(R.prop("format"), R.equals(slider)),
      R.always(<SliderAnswer question={question} />),
    ],
    [
      R.pipe(R.prop("format"), R.equals(tiles)),
      R.always(<TileAnswers question={question} />),
    ],
    [R.T, () => <AnswerList question={question} />],
  ])(question);

export default Answers;
