import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  header: {
    paddingBottom: 0,
  },
}));

const PublicHomeIntro = ({ homePageUrl }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" align="center" color="primary">
            The following preview is your permanent, public home page for
            Quizzify
          </Typography>
        }
        className={classes.header}
      />
      <CardContent>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              What you see below is exactly what your team members will see at:
            </Typography>
          </Grid>
          <Grid item>
            <Link
              href={homePageUrl}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <Button variant="contained" color="secondary">
                {homePageUrl}
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              (Don't believe us? Try clicking me!)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PublicHomeIntro;
