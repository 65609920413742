import React from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { lighten } from "@mui/material/styles";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

import transform from "../../utils/transformLinks";

const useStyles = makeStyles((theme) => ({
  htmlTextContainer: {
    wordBreak: "break-word",
  },
  notice: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.75),
    color: theme.palette.secondary.contrastText,
  },
}));

const HtmlMessage = ({ title, html, type, contest }) => {
  const classes = useStyles();

  if (!html) return null;

  if (type === "notice")
    return (
      <Card variant="outlined">
        <CardHeader
          className={classes.notice}
          title={
            <Typography className={classes.htmlTextContainer} component="div">
              {ReactHtmlParser(html, { transform })}
            </Typography>
          }
          avatar={<NewReleasesIcon />}
        />
      </Card>
    );

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
            {title}
          </Typography>
        }
        avatar={<WysiwygIcon />}
      />
      <Divider />
      <CardContent>
        <Typography className={classes.htmlTextContainer} component="div">
          {ReactHtmlParser(html, {
            transform,
          })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HtmlMessage;
