import React from "react";
import { Button, Grid } from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const SalesButtons = () => (
  <>
    {[
      {
        id: "pilot",
        href: "/pilot/offer",
        startIcon: <FlightTakeoffIcon />,
        text: "$500 Pilot",
      },
      {
        id: "pricing",
        href: "https://home.quizzify.com/pricing",
        startIcon: <SavingsIcon />,
        text: "Pricing",
      },
      {
        id: "meet",

        href: "https://calendly.com/mark-quizzify/30min?month=2023-05",
        startIcon: <CalendarMonthIcon />,
        text: "Let's Talk",
      },
    ].map(({ id, href, startIcon, text }) => (
      <Grid item key={id}>
        <Button
          href={href}
          variant="contained"
          color="secondary"
          rel="noopener noreferrer"
          target="_blank"
          startIcon={startIcon}
          size="small"
        >
          {text}
        </Button>
      </Grid>
    ))}
  </>
);

export default SalesButtons;
