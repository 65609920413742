import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import ReactHtmlParser from "react-html-parser";

const QuestionsList = ({ title, questions, doctor, info }) => {
  const theme = useTheme();

  if (!questions.length) return null;

  const getColor = () => {
    if (doctor) return "#E80000";

    return theme.palette.secondary.dark;
  };

  return (
    <>
      <Divider />
      <Typography variant="body2" fontWeight="bold">
        {title}
      </Typography>
      <List sx={{ width: "100%" }} disablePadding dense>
        {questions.map((question) => (
          <ListItem
            key={question.content}
            alignItems="center"
            dense={true}
            sx={{ padding: 0 }}
          >
            <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>
              {info ? (
                <InfoIcon sx={{ color: getColor() }} />
              ) : (
                <HelpIcon sx={{ color: getColor() }} />
              )}
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography>{ReactHtmlParser(question.content)}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default QuestionsList;
