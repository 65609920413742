export const attemptsExhausted = (q) =>
  q.score.incorrectSelected >= q.attemptsAllowed;

const isQuestionComplete = (q) => {
  try {
    if (
      q.score.correctSelected > 0 &&
      q.score.correctTotal === q.score.correctSelected
    )
      return true;

    if (attemptsExhausted(q)) return true;

    return false;
  } catch (err) {
    console.error(err);

    return false;
  }
};

export default isQuestionComplete;
