import React, { useContext } from "react";
import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Alert,
  Button,
  Container,
} from "@mui/material";
import moment from "moment";
import Countdown from "../Countdown";
import { context as quizContext, actions } from "./QuizContext";

const EarlyAdminBackdoor = ({ onClickPlayEarly }) => (
  <Alert>
    <Typography gutterBottom>
      As an admin you can play this quiz early:{" "}
    </Typography>
    <Button variant="outlined" onClick={onClickPlayEarly}>
      Play Quiz
    </Button>
  </Alert>
);

const WaitingRoom = ({ state, onClickPlayEarly }) => {
  const { dispatch } = useContext(quizContext);

  const { quiz } = state;
  const { startTime } = quiz;

  const isCurrentYear = moment().isSame(startTime, "year");

  return (
    <Container maxWidth="xs" sx={{ pt: 3 }}>
      <Card>
        <CardHeader title={`Quiz: ${quiz.title}`} />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Typography align="center" variant="subtitle1">
              Welcome! But you're a bit early. Your quiz starts
            </Typography>
            <Typography align="center" variant="h6">
              {moment(startTime).calendar(null, {
                sameElse: isCurrentYear
                  ? "MMM Do [at] h:mm A"
                  : "MMM Do, YYYY [at] h:mm A",
              })}
            </Typography>

            <Countdown
              endTime={new Date(startTime).getTime()}
              onComplete={() => dispatch({ type: actions.hideWaitingRoom })}
            />
            {quiz.allowAdminAccessEarly && (
              <EarlyAdminBackdoor onClickPlayEarly={onClickPlayEarly} />
            )}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default WaitingRoom;
