import React from "react";
import { useSelector } from "react-redux";

import InviteSpouse from "./InviteSpouse";
import SpouseProfile from "./SpouseProfile";

function SpouseManager({ handleClose }) {
  const state = useSelector((state) => state.spouseSettings);
  const { spouse, pendingInviteEmail } = state;

  if (!spouse || spouse?.fake_email) {
    return (
      <InviteSpouse
        spouse={spouse}
        open
        handleClose={handleClose}
        pendingEmail={pendingInviteEmail}
      />
    );
  }

  return <SpouseProfile spouse={spouse} handleClose={handleClose} />;
}

export default SpouseManager;
