import { score, sliderScore } from "../StateModel";
import * as R from "ramda";
import { slider } from "../utils/formats";

const isFormat = (format) => R.pipe(R.prop("format"), R.equals(format));

const questionsLens = R.lensPath(["quiz", "questions"]);
const scoreLens = R.lensProp("score");
const answersLens = R.lensProp("answers");
const selectedLens = R.lensProp("wasSelected");
const attemptsMadeLens = R.lensProp("attemptsMade");

const unselectAnswer = R.set(selectedLens, false);
const unselectBasicAnswers = R.over(answersLens, R.map(unselectAnswer));
const unselectSliderAnswer = R.set(attemptsMadeLens, 0);
const resetQuestion = R.cond([
  [
    isFormat(slider),
    R.pipe(R.set(scoreLens, sliderScore), unselectSliderAnswer),
  ],
  [R.T, R.pipe(R.set(scoreLens, score), unselectBasicAnswers)],
]);

const resetQuestions = R.over(questionsLens, R.map(resetQuestion));
const resetMeta = R.evolve({
  score: R.always(score),
  quizComplete: R.always(false),
  questionIndex: R.always(0),
  resultId: R.always(null),
});

const onReset = () => R.pipe(resetMeta, resetQuestions);

export default onReset;
