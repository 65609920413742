import React from "react";

const AnswerImage = ({ image, fixedHeight, align = "center" }) =>
  image ? (
    <div style={{ textAlign: align }}>
      <div
        style={{
          minHeight: fixedHeight ? 275 : "auto",
        }}
      >
        <img
          style={{
            maxHeight: 250,
            maxWidth: "100%",
            width: "auto",
            height: "auto",
            position: "relative",
            userDrag: "none",
            WebkitUserDrag: "none",
            KhtmlUserDrag: "none",
            MozUserDrag: "none",
            OUserDrag: "none",
          }}
          src={image.url}
          alt={image.alt}
        />
      </div>
    </div>
  ) : null;

export default AnswerImage;
