import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import TagCloud from "react-tag-cloud";
import queryString from "query-string";
import { context as quizContext, actions } from "../Quiz/QuizContext";
import ResponsiveContainer from "../ResponsiveContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4.0),
  },
  searchInput: {
    margin: theme.spacing(1.0),
    width: "100%",
  },
  popularSearchesContainer: {
    margin: theme.spacing(3.0),
  },
}));

const MakeDynamicQuiz = () => {
  const { dispatch } = useContext(quizContext);
  const { error } = queryString.parse(window.location.search);

  useEffect(() => {
    dispatch({ type: actions.reset });
  }, [dispatch]);

  const classes = useStyles();
  const history = useHistory();

  const [term, setTerm] = useState("");
  return (
    <form>
      <ResponsiveContainer>
        <Grid
          className={classes.container}
          container
          direction="column"
          alignItems="center"
        >
          <Grid item>
            {error && (
              <Typography variant="h6" color="error" gutterBottom>
                No results found. Please try another search.
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6" color="primary" gutterBottom>
              Get Your Custom Quiz
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" gutterBottom>
              Use keywords, such as "sugar", to get a quiz on the topic:
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              className={classes.searchInput}
              autoFocus
              label="Search"
              variant="outlined"
              placeholder="Enter keywords, e.g., 'heart'"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/dynamic/?term=${term}`);
              }}
              disabled={term.length < 3}
              variant="contained"
              color="secondary"
            >
              Get My Quiz
            </Button>
          </Grid>
          <Divider />
          <Grid item className={classes.popularSearchesContainer}>
            <Typography variant="subtitle1" gutterBottom align="center">
              Or try one of our popular searches:
            </Typography>
            <TagCloud
              style={{
                fontSize: 18,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "bold",
                padding: 5,
                width: 300,
                height: 150,
              }}
              random={() => 1.0}
            >
              <Link href={`/dynamic/?term=Cavities`} style={{ fontSize: 25 }}>
                Cavities
              </Link>
              <Link href={`/dynamic/?term=Sleep aids`}>Sleep aids</Link>
              <Link href={`/dynamic/?term=Heartburn`}>Heartburn</Link>
              <Link href={`/dynamic/?term=Scans`} style={{ fontSize: 50 }}>
                Scans
              </Link>
              <Link href={`/dynamic/?term=Diabetes`}>Diabetes</Link>
              <Link href={`/dynamic/?term=Back Pain`}>Back Pain</Link>
            </TagCloud>
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </form>
  );
};

export default MakeDynamicQuiz;
