import React, { useContext, useRef, useEffect } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ReactHtmlParser from "react-html-parser";

import { context as quizContext, actions } from "../../QuizContext";
import { useMobileBreakpoint } from "../../../../utils/responsive";
import FeedbackIcon from "./FeedbackIcon";
import AnswerImage from "./AnswerImage";
import transform from "../../../../utils/transformLinks";
import AnswerDetails from "./AnswerDetails";
import isIE from "../../../../utils/isIE";
import api from "../../../../api/endpoints";

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: "pointer",
    display: "block",
  },
  panelSummary: {
    "margin-bottom": "0px!important",
  },
  imageContainer: {
    margin: theme.spacing(1),
    maxHeight: 200,
    maxWidth: 300,
    overflow: "hidden",
    position: "relative",
  },
  image: {
    objectFit: "contain",
    maxHeight: 200,
    width: 300,
    maxWidth: "100%",
  },
}));

const logAnswerSelected = (answerId, quizId, isQuestionComplete) =>
  api.userBehavior
    .logAnswerSelected({
      answer_id: answerId,
      answer_type: "standard",
      quiz_id: quizId,
      is_question_complete: isQuestionComplete,
    })
    .catch((err) => console.error(err));

const AnswerItem = ({
  answer,
  expanded,
  handleChange,
  endImage,
  isQuestionComplete,
}) => {
  const { dispatch, state } = useContext(quizContext);
  const { quiz } = state;

  const classes = useStyles();
  const isMobile = useMobileBreakpoint();

  const image = answer.image;
  const answerDetails = answer.answer_details;

  const detailsAfterFeedback = answerDetails?.filter(
    (detail) => detail.position === "after_feedback"
  );

  const accordionRef = useRef(null);

  const isIEResult = isIE;

  useEffect(() => {
    if (expanded === answer.id && accordionRef.current) {
      const element = accordionRef.current;

      const scrollIntoViewIfNeeded = () => {
        const rect = element.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);

        if (!isVisible && !isIEResult) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      };

      setTimeout(scrollIntoViewIfNeeded, 300);
    }
  }, [expanded, answer.id, isIEResult]);

  return (
    <Accordion
      expanded={expanded === answer.id}
      onChange={(event, isExpanded) => {
        handleChange(event, isExpanded);
        logAnswerSelected(answer.id, quiz?.id, isQuestionComplete);
      }}
      key={answer.id}
      className={classes.item}
      ref={accordionRef}
    >
      <AccordionSummary
        classes={{ expanded: classes.panelSummary }}
        onClick={(_) =>
          dispatch({
            type: actions.selectAnswer,
            answerId: answer.id,
          })
        }
      >
        <Box sx={{ display: "block", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FeedbackIcon {...answer} />
            <Typography
              variant={isMobile ? "body1" : "subtitle1"}
              sx={(theme) => ({
                ml: 1,
                [theme.breakpoints.down("sm")]: {
                  fontSize: 14,
                },
              })}
            >
              {ReactHtmlParser(answer.mainText, { transform })}
            </Typography>
          </Box>
          {answer.image_visibility === "always" && (
            <Box sx={{ marginTop: 1 }}>
              <AnswerImage image={image} align="left" />
            </Box>
          )}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Alert severity={answer.isCorrect ? "success" : "error"} icon={false}>
          {ReactHtmlParser(answer.feedbackText, { transform })}
        </Alert>
        {endImage && <Box sx={{ pt: 1.5 }}>{endImage}</Box>}

        {answer.image_visibility === "focus" && (
          <AnswerImage image={image} align="left" />
        )}

        <AnswerDetails details={detailsAfterFeedback} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AnswerItem;
