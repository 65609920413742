import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Link,
  CircularProgress,
  Stack,
} from "@mui/material";
import api from "../../api/endpoints";
import Message from "./Message";

const NotOnRoster = () => {
  const [loaded, setLoaded] = useState(false);
  const [notOnRosterMessages, setNotOnRosterMessages] = useState();

  useEffect(() => {
    api.companies
      .notOnRosterMessages()
      .then(({ data: { not_on_roster_messages } }) => {
        setLoaded(not_on_roster_messages);
        setNotOnRosterMessages(not_on_roster_messages);
      })
      .catch((error) => {
        window.alert("An error has occurred. Please try again later.");
      });
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  if (!notOnRosterMessages) {
    return (
      <Card
        sx={{ width: 500, maxWidth: "100%", ml: "auto", mr: "auto", mt: 2 }}
      >
        <CardHeader title="Not on Roster" />
        <Divider />
        <CardContent>
          <Typography>
            This organization enforces a roster and unfortunately you're not on
            it yet.
          </Typography>
          <Typography>
            If you're new you'll likely be added in the next few weeks and
            receive an email to get started.
          </Typography>
          <Typography>
            If you believe this is in error please{" "}
            <Link href="mailto:support@quizzify.zendesk.com">
              Contact Support
            </Link>
            .
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ width: 500, maxWidth: "100%", ml: "auto", mr: "auto", mt: 2 }}>
      <CardHeader title="Not on Roster" />
      <Divider />
      <CardContent>
        <Stack spacing={1}>
          {notOnRosterMessages.map((message) => (
            <Message key={message.value} {...message} />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default NotOnRoster;
