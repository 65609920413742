import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import MobileDetect from "mobile-detect";

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  zIndex: 2000,
};

const AppStoreDownload = ({ togoAuthToken, handleClose }) => {
  if (!togoAuthToken)
    return (
      <Typography color="error">
        The access token is missing. Please contact support.
      </Typography>
    );

  const md = new MobileDetect(window.navigator.userAgent);

  const isIphone = md.is("iPhone");
  const isAndroid = md.is("AndroidOS");

  const appStoreHref = (() => {
    if (isIphone) {
      return "https://apps.apple.com/us/app/quizzify2go/id1635813446";
    } else if (isAndroid) {
      return "https://play.google.com/store/apps/details?id=com.quizzify.quizzify2go";
    }
  })();

  const appStoreImage = (() => {
    if (isIphone) {
      return "/img/app-store-download.png";
    } else if (isAndroid) {
      return "/img/get-on-google-play.png";
    }
  })();

  const url = `quizzify2go://?authToken=${togoAuthToken}`;

  return (
    <Card sx={modalStyle}>
      <CardHeader
        title={
          <Typography variant="subtitle1">
            Download and Login to Quizzify2Go
          </Typography>
        }
        avatar={<GetAppIcon />}
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6">
            1. Install Quizzify2Go on Your Phone
          </Typography>
          <Box sx={{ justifyContent: "center" }}>
            <Button
              href={appStoreHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="App Store Download"
                src={appStoreImage}
                style={{ maxWidth: 200, width: "100%" }}
              />
            </Button>
          </Box>
        </Stack>
      </CardContent>
      <Divider />
      <CardContent>
        <Stack spacing={1}>
          <Typography variant="h6">
            2. After You've Installed Quizzify2Go, Click this Button to Login
          </Typography>
          <Typography variant="body1">
            Logging in with this link associates Quizzify2Go with your profile
          </Typography>
          <Typography variant="body1" color="error">
            Note: this link won't work if you didn't download the app first
          </Typography>
          <Button
            variant="contained"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Login to Your Installed App
          </Button>
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <Button onClick={handleClose}>Close</Button>
      </CardActions>
    </Card>
  );
};

export default AppStoreDownload;
