import React from "react";
import { Box } from "@mui/material";
import queryString from "query-string";

const styles = (theme, embed) => {
  const basic = {
    padding: "0px !important",
    paddingTop: "64px !important",
    height: "calc(100% - 64px) !important",
    backgroundColor: "#f5f5f5 !important",
    maxWidth: "none !important",
    maxHeight: "calc(100% - 64px) !important",
  };

  if (embed) {
    basic.padding = "0px !important";
    basic.paddingTop = "0px !important";
    basic.backgroundColor = "transparent !important";
    basic.height = "100% !important";
    basic.maxHeight = "100% !important";
  } else {
    basic[theme.breakpoints.down("sm")] = {
      paddingTop: "56px !important",
      height: "calc(100% - 56px) !important",
      maxHeight: "calc(100% - 56px) !important",
    };
  }

  return basic;
};

const AppContainer = ({ children }) => {
  const { embed } = queryString.parse(window.location.search);

  return <Box sx={(theme) => styles(theme, embed)}>{children}</Box>;
};

export default AppContainer;
