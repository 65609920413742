import React from "react";
import { Typography, Alert } from "@mui/material";
import pluralize from "pluralize";
const R = require("ramda");

const Instructions = ({ score, lang }) => {
  const correctTotal = R.prop("correctTotal", score);
  const correctSelected = R.prop("correctSelected", score);
  const correctRemaining = correctTotal - correctSelected;

  const style = (theme) => ({
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  });

  if (correctTotal > 0 && correctRemaining > 0) {
    if (correctSelected > 0) {
      if (lang === "es") {
        return (
          <Typography variant="subtitle1" gutterBottom sx={style}>
            <strong>
              Seleccione {correctRemaining} respuesta
              {correctRemaining > 1 ? "s" : ""} correcta
              {correctRemaining > 1 ? "s" : ""}
            </strong>
          </Typography>
        );
      }

      return (
        <Typography variant="subtitle1" gutterBottom sx={style}>
          <strong>
            Select {correctRemaining} more correct{" "}
            {pluralize("answer", correctRemaining)}
          </strong>
        </Typography>
      );
    }

    if (lang === "es") {
      return (
        <Typography variant="subtitle1" gutterBottom sx={style}>
          <strong>
            Seleccione {correctRemaining} respuesta
            {correctRemaining > 1 ? "s" : ""} correcta
            {correctRemaining > 1 ? "s" : ""}
          </strong>
        </Typography>
      );
    }

    return (
      <Typography variant="subtitle1" gutterBottom sx={style}>
        <strong>
          Select {correctTotal} correct {pluralize("answer", correctTotal)}
        </strong>
      </Typography>
    );
  }

  return (
    <Alert severity="info" sx={style}>
      {lang === "es"
        ? "Esta pregunta está completa"
        : "This Question Is Complete"}
    </Alert>
  );
};

export default Instructions;
