import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: theme.spacing(2),
  },
  imageContainer: {
    paddingTop: theme.spacing(1),
    width: "100%",
    maxWidth: 300,
    maxHeight: 100,
    textAlign: "center",
  },
  image: {
    maxHeight: 100,
    objectFit: "contain !important",
    maxWidth: "100%",
  },
}));

const Welcome = ({ logo, name, customGreeting }) => {
  const classes = useStyles();

  return (
    // <Card variant="outlined">
    // <CardContent className={classes.contentContainer}>
    <Grid container direction="column" alignItems="center" sx={{ mb: 3 }}>
      {logo ? (
        <>
          <Grid item>
            <Typography variant="h6" color="primary">
              {customGreeting || "Quizzify Welcomes"}
            </Typography>
          </Grid>
          <Grid item className={classes.imageContainer}>
            <img src={logo} alt="Logo" className={classes.image} />
          </Grid>
          <Grid item className={classes.imageContainer}></Grid>
        </>
      ) : (
        <Grid item>
          <Typography variant="h6" color="primary">
            Welcome to Quizzify for {name}!
          </Typography>
        </Grid>
      )}
    </Grid>
    //   </CardContent>
    // </Card>
  );
};

export default Welcome;
