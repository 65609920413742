import React from "react";
import ConfettiGenerator from "confetti-js";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  canvas: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
  },
}));

const Confetti = () => {
  const classes = useStyles();

  React.useEffect(() => {
    const confettiSettings = {
      target: "confetti-canvas",
      clock: 85,
      rotate: true,
      respawn: false,
      max: 1000,
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []);

  return <canvas id="confetti-canvas" className={classes.canvas}></canvas>;
};

export default Confetti;
