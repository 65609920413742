import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Stack,
  Button,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../api/endpoints";
import { updatePendingInviteEmail } from "../../redux/spouseSlice";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => EMAIL_REGEX.test(email);

const InviteSpouse = ({ pendingEmail, handleClose, spouse }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(pendingEmail || "");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const [error, setError] = useState();

  const canSubmit = validateEmail(email);

  return (
    <>
      <Snackbar
        open={sent}
        autoHideDuration={6000}
        onClose={() => setSent(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSent(false)}
          severity="success"
        >
          Invitation sent to {email}!
        </Alert>
      </Snackbar>
      <Modal open onClose={handleClose} aria-labelledby="modal-invite-spouse">
        <Card sx={modalStyle}>
          <CardHeader
            title="Invite Your Spouse"
            avatar={<GroupAddIcon />}
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              {spouse?.fake_email && (
                <Alert elevation={6} variant="filled" severity="info">
                  We have your spouse linked as {spouse.first_name}{" "}
                  {spouse.last_name}, but we don't have their email. Please
                  invite them below.
                </Alert>
              )}
              {pendingEmail && (
                <>
                  <Typography>
                    You have a pending invite out to {pendingEmail}.
                  </Typography>
                  <Typography>
                    You can resend the invitation. If you need to change the
                    email it's addressed to, please edit the email below.
                  </Typography>
                  <Divider />
                </>
              )}
              <TextField
                label="Spouse's Email"
                name="email"
                fullWidth
                value={email}
                error={error}
                helperText={error ? "Requires a valid email" : ""}
                onBlur={() => setError(!validateEmail(email))}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (error && validateEmail(email)) {
                    setError();
                  }
                }}
              />
              <TextField
                name="message"
                label="Message (Optional)"
                placeholder="I'm inviting you to try Quizzify to learn about health."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                fullWidth
              />
            </Stack>
          </CardContent>
          <Divider />
          <CardActions>
            <Button sx={{ marginLeft: "auto" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!canSubmit || sending}
              endIcon={sending && <CircularProgress size={18} />}
              onClick={() => {
                setSending(true);
                api.invite
                  .spouse({ email, message })
                  .then(() => {
                    setSent(true);
                    setSending(false);
                    dispatch(updatePendingInviteEmail(email));
                  })
                  .catch((err) => {
                    setSending(false);

                    window.alert(err);
                  });
              }}
            >
              {pendingEmail ? "Resend Invite" : "Send"}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default InviteSpouse;
