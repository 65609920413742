import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import muiTheme from "../mui-theme";

const WARNING_TIME = 60;

const getTimerProps = (size) => {
  if (size === "small") {
    return {
      size: 50,
      strokeWidth: 3,
    };
  }

  return {
    size: 120,
    strokeWidth: 6,
  };
};

const formatMinOrSec = (value) => (value < 10 ? `0${value}` : value);

const getDimension = (secondsLeft) => {
  const duration = moment.duration(secondsLeft, "seconds");
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0) return [`${years}+`, "yrs."];
  if (months > 0) return [`${months}+`, "months"];
  if (days > 7) return [`${Math.floor(days / 7)}+`, "weeks"];
  if (days > 0) return [`${days}+`, "days"];
  if (hours > 0)
    return [`${hours}:${formatMinOrSec(minutes)}:${formatMinOrSec(seconds)}`];
  return [`${minutes}:${formatMinOrSec(seconds)}`];
};

const Time = ({ time, size }) => {
  const [timeValue, timeLabel] = getDimension(time);
  const warningTime = time < WARNING_TIME;

  return (
    <Box>
      <Typography
        color="secondary.dark"
        variant={timeValue.length > 5 ? "h5" : "h4"}
      >
        {timeValue}
      </Typography>
      {timeLabel ? (
        <Typography color={warningTime ? "error" : "secondary.dark"}>
          {timeLabel}
        </Typography>
      ) : null}
    </Box>
  );
};

const Countdown = ({ endTime, onComplete = () => {}, size }) => {
  const startTime = Date.now(); // use UNIX timestamp in seconds
  const secondsLeft = (endTime - startTime) / 1000;
  const color = muiTheme.palette.secondary.dark;

  return (
    <Box align="center">
      <CountdownCircleTimer
        {...getTimerProps(size)}
        isPlaying
        onComplete={() => {
          onComplete();
          return [false];
        }}
        colors={[[color]]}
        duration={secondsLeft}
      >
        {({ remainingTime }) => {
          return <Time time={remainingTime} size={size} />;
        }}
      </CountdownCircleTimer>
    </Box>
  );
};

export default Countdown;
