import React from "react";
import MobileDetect from "mobile-detect";

export default function () {
  const md = new MobileDetect(window.navigator.userAgent);
  console.log(navigator.userAgent);
  console.log(md);

  return (
    <div style={{ marginTop: 80 }}>
      <div>Mobile: {md.mobile()}</div>
      <div>Phone: {md.phone()}</div>
      <div>User agent: {md.userAgent()}</div>
      <div>OS: {md.os()}</div>
    </div>
  );
}
