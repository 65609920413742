import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";

const Welcome = ({ companyName }) => (
  <Card>
    <CardHeader
      title={
        <Typography align="center" color="primary" variant="h6">
          Welcome to Quizzify!
        </Typography>
      }
      style={{ paddingBottom: 0 }}
    />
    <CardContent>
      <Typography align="center" variant="subtitle1" gutterBottom>
        The team at {companyName} can start using our <br /> educational health
        literacy quizzes in less than 60 seconds.
      </Typography>
    </CardContent>
  </Card>
);

export default Welcome;
