import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  IconButton,
  Tooltip,
  CardActions,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DetailModal from "./DetailModal";

const DetailButton = ({ detail }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    if (detail.open_as === "new_tab") {
      window.open(detail.url, "_blank");
    } else if (detail.open_as === "same_tab") {
      window.location.href = detail.url;
    } else if (detail.open_as === "modal") {
      setModalOpen(true);
    }
  };

  return (
    <>
      <DetailModal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Card
          raised
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6">
                This is an example of the content end users would learn from
                Quizzify
              </Typography>
            }
            action={
              <Tooltip title="Close">
                <IconButton onClick={() => setModalOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
          />
          <Divider />
          <CardContent
            sx={{
              flex: 1,
              p: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              id="IFRAME"
              style={{
                width: "99%",
                height: "99%",
                outline: "none",
                border: "none",
                m: "auto",
              }}
              src={detail.url}
              title={detail.display_name}
            />
          </CardContent>
          <Divider />
          <CardActions sx={{ flexShrink: 0 }}>
            <Button
              startIcon={<CloseIcon />}
              variant="outlined"
              onClick={() => setModalOpen(false)}
            >
              Close Sample Question
            </Button>
          </CardActions>
        </Card>
      </DetailModal>
      <Button variant="outlined" onClick={handleClick}>
        {detail.display_name}
      </Button>
    </>
  );
};

export default DetailButton;
