import React, { useContext, useEffect, useState } from "react";
import { Divider, Typography, Button, Box } from "@mui/material";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";

import { context, actions } from "../AuthContext";
import endpoints from "../../api/endpoints";
import {
  context as quizContext,
  actions as quizActions,
} from "../Quiz/QuizContext";

const LoginPrompt = ({ resultId, question, quiz, score }) => {
  const { state, dispatch } = useContext(context);
  const { dispatch: quizDispatch } = useContext(quizContext);
  const [registeredPostQuiz, setRegisteredPostQuiz] = useState(false);

  const { isLoggedIn } = state;
  const { total: totalScore } = score;

  const promptHeadline = _.get(quiz, "endScreen.loginPrompt.headline");
  const promptMessage = _.get(quiz, "endScreen.loginPrompt.message");
  const hideLogin = _.get(quiz, "hideLogin");

  useEffect(() => {
    // If you're logged in but still have a public result ID convert it
    if (isLoggedIn && resultId) {
      endpoints.quiz
        .saveGamePostQuiz({
          resultId,
          quizId: quiz.id,
        })
        .then(() =>
          quizDispatch({
            type: quizActions.removeResultId,
          })
        )
        .catch(() => {
          window.alert(
            "Unfortunately something went wrong and we could not save your score. Please screenshot this page and contact support."
          );
        });

      setRegisteredPostQuiz(true);
    }
  }, [
    isLoggedIn,
    resultId,
    quizDispatch,
    question,
    quiz,
    totalScore,
    setRegisteredPostQuiz,
  ]);

  if (hideLogin) return null;

  if (!isLoggedIn) {
    return (
      <>
        <Box pt={1} pb={1}>
          <Typography variant="h6" gutterBottom>
            {promptHeadline
              ? ReactHtmlParser(promptHeadline)
              : "Login or Register to Save Your Score"}
          </Typography>
          {promptMessage && (
            <Typography variant="subtitle1" gutterBottom>
              {ReactHtmlParser(promptMessage)}
            </Typography>
          )}
          <Button
            style={{ marginTop: 12 }}
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch({
                type: actions.promptLogin,
              });
            }}
          >
            Login / Register
          </Button>
        </Box>
        <Divider />
      </>
    );
  }

  if (registeredPostQuiz) {
    return (
      <>
        <Divider />
        <Box pt={1} pb={1}>
          <Typography variant="h6" gutterBottom>
            Thanks! We've logged your score!
          </Typography>
        </Box>
      </>
    );
  }

  return null;
};

export default LoginPrompt;
