import React from "react";
import { CardContent, Box } from "@mui/material";
import QuestionIntro from "./QuestionIntro";
import QuizProgress from "./QuizProgress";
import QuestionHeader from "./QuestionHeader";
import Instructions from "./Instructions";
import QuestionImages from "./QuestionImages";
import Answers from "./Answers";
import LearnMoreLink from "./LearnMoreLink";
import QuizActions from "../QuizActions";

const Question = ({
  question,
  state,
  questions,
  score,
  lang,
  learnMoreFormat,
  setQuestionSubmitted,
  isNotScored,
  header = null,
}) => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    }}
  >
    <Box
      id="quiz-box"
      sx={{
        overflowY: "auto",
        flex: 1,
      }}
    >
      {header}
      <QuizProgress state={state} />
      <QuestionIntro question={question} />
      <QuestionHeader question={question} questions={questions} />
      <CardContent sx={{ flex: 1 }}>
        <Instructions score={score} lang={lang} />
        <QuestionImages question={question} score={score} />
        <Answers question={question} />
        <LearnMoreLink
          lang={lang}
          question={question}
          format={learnMoreFormat}
        />
      </CardContent>
    </Box>

    <Box sx={{ flexShrink: 0 }}>
      <QuizActions
        lang={lang}
        score={score}
        setQuestionSubmitted={setQuestionSubmitted}
        question={question}
        isNotScored={isNotScored}
      />
    </Box>
  </Box>
);

export default Question;
