import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Stack,
} from "@mui/material";
import api from "../../api/endpoints";

const Template = ({ template }) => (
  <Grid item xs={12} sm={6}>
    <Card sx={{ height: "100%" }}>
      <img
        src={template.banner_image.url}
        alt={template.banner_image.alt}
        width="100%"
        height="175"
        style={{ objectFit: "cover" }}
      />
      <CardContent>
        <Stack spacing={1}>
          {template.audience && (
            <Chip label={`For ${template.audience.name}`} color="warning" />
          )}
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            {template.name}
          </Typography>
          <Typography>{template.description}</Typography>
        </Stack>
      </CardContent>
    </Card>
  </Grid>
);

const Curriculum = ({ open, onClose }) => {
  const [templates, setTemplates] = useState();

  useEffect(() => {
    api.pilot.curriculum_preview().then(({ data: { quiz_templates } }) => {
      setTemplates(quiz_templates);
    });
  }, []);

  const pinnedTemplates = templates?.filter(
    (template) => template.start_pinned
  );
  const rest = templates?.filter((template) => !template.start_pinned);

  return (
    <Card
      sx={{
        height: "90%",
        display: "flex",
        flexDirection: "column",
      }}
      raised
    >
      <CardContent sx={{ backgroundColor: "#EFEFEF", overflowY: "auto" }}>
        <Grid container spacing={3}>
          {pinnedTemplates?.map((template) => (
            <Template key={template.id} template={template} />
          ))}
          {rest?.map((template) => (
            <Template key={template.id} template={template} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Curriculum;
