import Analytics from "analytics";
import googleAnalyticsPlugin from "@analytics/google-analytics";

/* Initialize analytics */
const analytics = Analytics({
  app: "Quizzify Game App",
  version: "0.1.0",
  plugins: [
    googleAnalyticsPlugin({
      trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
    }),
  ],
});

export default analytics;
