import React from "react";
import Carousel from "./Carousel";

const QuizPreview = ({ quiz }) => {
  if (!quiz?.questions) return null;

  return <Carousel items={quiz.questions.map((q) => q.name)} small />;
};

export default QuizPreview;
