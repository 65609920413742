import React from "react";
import { Hidden } from "@mui/material";
import DesktopTocQuiz from "./DesktopTocQuiz";
import MobileTocQuiz from "./MobileTocQuiz";

const TocQuiz = (props) => {
  return (
    <>
      <Hidden mdDown>
        <DesktopTocQuiz {...props} />
      </Hidden>
      <Hidden mdUp>
        <MobileTocQuiz {...props} />
      </Hidden>
    </>
  );
};

export default TocQuiz;
