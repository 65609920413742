import React from "react";
import { Card, Grid } from "@mui/material";
import Question from "../Question";
import { DesktopTableOfContents } from "./TableOfContents";

const DesktopTocQuiz = (props) => {
  const { questions, question } = props;

  return (
    <Card
      raised
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            height: "100%",
          }}
        >
          <DesktopTableOfContents
            questions={questions}
            currentQuestion={question}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{
            height: "100%",
          }}
        >
          <Question {...props} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default DesktopTocQuiz;
