import { convertNodeToElement } from "react-html-parser";

// Make links open in new tabs
const transformLinks = (node, index) => {
  if (node.name === "a") {
    node.attribs = {
      ...node.attribs,
      target: "_blank",
      rel: "noopener noreferrer",
      class: "",
    };
    return convertNodeToElement(node, index, transformLinks);
  }
  // Remove margin from paragraph tags
  if (node.name === "p" || node.name === "span") {
    node.attribs = {
      ...node.attribs,
      style: "margin: 0",
      class: "",
    };
    return convertNodeToElement(node, index, transformLinks);
  }
};

export const removeLinks = (node, index) => {
  if (node.name === "a") {
    node.name = "span";
  }

  node.attribs = {
    ...node.attribs,
    style: "margin: 0",
    class: "",
  };

  return convertNodeToElement(node, index, removeLinks);
};

export default transformLinks;
