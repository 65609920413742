import React from "react";
import { Typography, Divider, Stack } from "@mui/material";
import _ from "lodash";

const Epilogue = ({ quiz }) => {
  const epilogue = _.get(quiz, "endScreen.epilogue");

  if (!epilogue) return null;

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Divider />
      <Typography
        sx={{ textAlign: "center" }}
        variant="subtitle1"
        dangerouslySetInnerHTML={{
          __html: epilogue.replace(/(?:\\n)/g, "<br />"),
        }}
      />
    </Stack>
  );
};

export default Epilogue;
