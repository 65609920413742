import React, { createContext, useReducer } from "react";
import * as R from "ramda";
import keyMirror from "../../utils/keyMirror";
import reducer from "../../utils/curriedReducer";

const actions = keyMirror({
  loadState: null,
  error: null,
});

const { loadState, error } = actions;

const context = createContext({});
const { Provider } = context;

const handlers = {
  [loadState]: R.pipe(R.prop("data"), R.mergeDeepLeft),
  [error]: R.always(R.assoc("hasApiError", true)),
};

const PublicHomeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer(handlers), {});
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { context, PublicHomeProvider, actions };
