import React from "react";
import { default as TextField } from "./TextField";
import { default as RadioField } from "./RadioField";
import * as R from "ramda";

const isTextField = R.compose(
  R.flip(R.includes)(["employeeId", "phone"]),
  R.prop("key")
);
const isBooleanField = R.compose(
  R.flip(R.includes)(["shouldContact"]),
  R.prop("key")
);
const isMultilineField = R.compose(
  R.flip(R.includes)(["message"]),
  R.prop("key")
);

const makeField = (classes) =>
  R.cond([
    [
      isTextField,
      (config) => (
        <TextField
          classes={{ root: classes.field }}
          field={config.key}
          label={config.label}
          fullWidth={true}
          validate={(value) =>
            !!value ? false : `Please Enter ${config.label}`
          }
        />
      ),
    ],
    [
      isBooleanField,
      (config) => (
        <RadioField
          classes={{ root: classes.radio }}
          color="primary"
          field={config.key}
          options={["Yes", "No"]}
          label={config.label}
        />
      ),
    ],
    [
      isMultilineField,
      (config) => (
        <TextField
          multiline
          classes={{ root: classes.field }}
          rows={4}
          field={config.key}
          label={config.label}
          fullWidth={true}
          validate={(value) =>
            !!value ? false : `Please Enter ${config.label}`
          }
        />
      ),
    ],
  ]);

export { makeField };
