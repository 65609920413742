import React from "react";
import { Button } from "@mui/material";

export default function AddToWalletButton({ alt, href, onClick }) {
  return (
    <Button href={href} onClick={onClick} sx={{ minWidth: 150 }}>
      <img src="/img/apple_wallet.svg" width="150" height="47" alt={alt} />
    </Button>
  );
}
