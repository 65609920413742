import React, { useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Link,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import TocIcon from "@mui/icons-material/Toc";

import { context as quizContext, actions } from "../../QuizContext";
import { removeLinks } from "../../../../utils/transformLinks";
import QuestionIcon from "./QuestionIcon";

const TableOfContents = ({ questions, currentQuestion }) => {
  const { dispatch } = useContext(quizContext);

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[50],
        maxWidth: 550,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderColor: theme.palette.grey[300],
        borderStyle: "solid",
        borderWidth: "1px",
      })}
    >
      <CardHeader
        avatar={<TocIcon />}
        title="Table of Contents"
        subheader="Click questions to navigate"
        sx={{ flexShrink: 0 }}
      />
      <Divider />
      <CardContent
        sx={{
          overflowY: "auto",
          flex: 1,
        }}
      >
        <Stack spacing={2}>
          {questions.map((question, index) => (
            <Link
              key={question.id}
              onClick={(e) => {
                dispatch({
                  type: actions.onChangeQuestion,
                  data: index,
                });
              }}
              sx={{
                cursor: "pointer",
                fontSize: 13,
                fontWeight: currentQuestion === question ? "bold" : "normal",
              }}
            >
              <Stack spacing={2} direction="row">
                <Box>
                  <QuestionIcon question={question} />
                </Box>
                <Box>
                  <Typography sx={{ fontStyle: "italic" }}>
                    {ReactHtmlParser(question.intro, {
                      transform: removeLinks,
                    })}
                  </Typography>
                  {question.intro && <br />}
                  <Typography>
                    {ReactHtmlParser(question.prompt, {
                      transform: removeLinks,
                    })}
                  </Typography>
                </Box>
              </Stack>
            </Link>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TableOfContents;
