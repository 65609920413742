import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Divider,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";
import api from "../api/endpoints";
import { context, actions } from "./AuthContext";
import AcceptTerms from "./AcceptTerms";
import SetPassword from "./SetPassword";

const PilotAcceptInvite = () => {
  const [errors, setErrors] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const inviteCode = searchParams.get("invite_code");

  const { state: authState, dispatch } = useContext(context);
  const { userProfile } = authState;

  useEffect(() => {
    if (inviteCode) {
      api.pilot
        .acceptInvite(inviteCode)
        .then(({ data: { redirect_to_path, auth_token } }) => {
          localStorage.setItem("authToken", auth_token);

          setRedirectTo(redirect_to_path);

          api.auth
            .profile()
            .then((response) => {
              dispatch({
                type: actions.setUserProfile,
                userProfile: response.data.current_user,
              });
            })
            .catch((err) => console.log("Profile Error: ", err));
        })
        .catch((err) => {
          const errors = _.get(err, "response.data.errors");
          if (errors) {
            setErrors(errors);
          } else {
            setErrors([
              `An unknown error occurred while accepting the invitation: ${err.message}`,
            ]);
          }
        });
    }
  }, [inviteCode, history]);

  const {
    email = "",
    needs_action: { needs_terms = false, needs_password = false } = {},
  } = userProfile || {};

  useEffect(() => {
    if (userProfile && redirectTo && !(needs_terms || needs_password)) {
      history.push(redirectTo);
    }
  }, [userProfile, needs_terms, needs_password, history, redirectTo]);

  return (
    <Container maxWidth="sm" sx={{ pt: 5, pb: 5, height: "100%" }}>
      <BodyContent {...{ needs_terms, needs_password, email, errors }} />
    </Container>
  );
};

const BodyContent = ({ needs_terms, needs_password, email, errors }) => {
  if (needs_terms) {
    return <AcceptTerms />;
  }

  if (needs_password) {
    return <SetPassword email={email} />;
  }

  if (errors) {
    return (
      <Card raised>
        <CardHeader title="Errors" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            {errors.map((error) => (
              <Alert key={error} severity="error">
                {error}
              </Alert>
            ))}
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card raised>
      <CardContent sx={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading your pilot invitation...
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PilotAcceptInvite;
