import React from "react";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";

const RetakeQuiz = () => {
  const { t } = useTranslation();
  return (
    <Button
      color="primary"
      onClick={(_) => {
        window.location.reload();
      }}
      variant="outlined"
      startIcon={<RefreshIcon />}
      sx={{ flexShrink: 0, mt: "4px", mb: "4px" }}
    >
      {t("Replay")}
    </Button>
  );
};

export default RetakeQuiz;
