import { Notifier } from '@airbrake/browser';

const fakeNotifier = {
  notify: (...args) => console.log("Error: ", args)
};

const getNotifier = () => {
  if (process.env.NODE_ENV !== 'production') {
    return fakeNotifier;
  }

  return new Notifier({
    projectId: process.env.REACT_APP_AIBRAKE_PROJECT_ID,
    projectKey: process.env.REACT_APP_AIBRAKE_PROJECT_KEY
  });
};

export default getNotifier;
