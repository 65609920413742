import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

const NycCustom = () => (
  <Card raised>
    <CardContent>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={3}>
            <img
              src="https://quizzify-files.s3.us-east-2.amazonaws.com/Logos/workwell.png"
              alt="logo"
              style={{ maxWidth: "100%" }}
            />
          </Grid> */}
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <a
              href="http://nyc.gov/workwellnyc"
              target="_blank"
              rel="noopener noreferrer"
            >
              WorkWell NYC
            </a>
             is committed to supporting City employees in maintaining healthy,
            active lifestyles at work, at home and beyond, as you work to
            maintain our vibrant, safe and thriving city. To learn more, join
            our{" "}
            <a
              href="https://www.surveymonkey.com/r/ZYP8RTP"
              target="_blank"
              rel="noopener noreferrer"
            >
              mailing list
            </a>{" "}
            and visit our{" "}
            <a
              href="      http://nyc.gov/workwellnyc"
              target="_blank"
              rel="noopener noreferrer"
            >
              website
            </a>{" "}
            to learn more about upcoming events and find resources to help you
            live well, work well, and be well.
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default NycCustom;
