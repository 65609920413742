const R = require("ramda");

const score = {
  incorrectSelected: 0,
  correctSelected: 0,
  correctTotal: 0,
  total: 0,
};

// @TODO Slider score might be better handled in the reducer
const sliderScore = {
  incorrectSelected: 0,
  correctSelected: 0,
  correctTotal: 1,
  total: 0,
};

const initialState = {
  quiz: {
    id: null,
    title: null,
    questions: [],
    endScreen: {},
  },
  company: {
    id: null,
    name: null,
    logo: null,
  },
  questionIndex: 0,
  quizComplete: false,
  score,
};

const testState = R.mergeDeepRight(initialState, {
  quiz: {
    endScreen: {
      sections: [
        {
          type: "ctaLink",
          url: "https://www.surveymonkey.com/r/ZYP8RTP",
          buttonText: "Join the WorkWell NYC Mailing List",
          buttonCaption:
            "Get health and wellness content directly to your inbox - exclusively for City of New York employees!",
        },
        {
          type: "survey",
          prompt:
            "Would questions like these be useful or interesting for you or your family?",
          responses: ["Yes", "Probably", "Sometimes", "Rarely", "Never"],
        },
      ],
    },
  },
  company: {
    id: null,
    name: "NYC Workwell",
    logo: "https://quizzify-files.s3.us-east-2.amazonaws.com/Logos/workwell.png",
  },
});

export { score, sliderScore, initialState, testState };
