import React from "react";
import { Typography, CardHeader } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import transform from "../../../utils/transformLinks";

const stripHtml = (str) => (str ? str.replace(/(<([^>]+)>)/gi, "") : str);

const QuestionIntro = ({ question }) => {
  if (!question.intro) return null;

  if (!question.intro && question.customCompany !== null) {
    return null;
  }

  if (stripHtml(question.intro) === "") {
    return null;
  }

  return (
    <CardHeader
      sx={{
        avatar: {
          marginRight: 0,
        },
      }}
      avatar={
        <NewReleasesIcon
          color="success"
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              fontSize: 0,
              marginRight: 0,
            },
          })}
        />
      }
      title={
        <Typography
          variant="h6"
          sx={(theme) => ({
            fontSize: 18,
            [theme.breakpoints.down("sm")]: {
              fontSize: 14,
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: 12,
            },
          })}
        >
          {ReactHtmlParser(question.intro, { transform })}
        </Typography>
      }
    />
  );
};

export default QuestionIntro;
