import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import endpoints from "../../api/endpoints";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const SavePdf = ({ quizId }) => {
  const { pdf } = endpoints;
  const { t } = useTranslation();

  return quizId ? (
    <Button
      onClick={() => window.open(pdf.path(quizId))}
      color="primary"
      variant="outlined"
      startIcon={<CloudDownloadIcon />}
      sx={{ flexShrink: 0, mt: "4px", mb: "4px" }}
    >
      {t("Save Quiz as PDF")}
    </Button>
  ) : null;
};

export default SavePdf;
