import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CompletionMessage = ({ quiz: { name } }) => {
  const { t, i18n } = useTranslation();

  const isEnglish = i18n.language === "en";

  if (name && isEnglish) {
    return (
      <Typography variant="h6" sx={{ textAlign: { xs: "center", sm: "left" } }}>
        Great job! You've completed:
        <br />
        <em>{name}</em>
      </Typography>
    );
  }

  return (
    <Typography variant="h6" sx={{ textAlign: { xs: "center", sm: "left" } }}>
      {t("completionMessage")}
    </Typography>
  );
};

export default CompletionMessage;
