import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Chip,
  Stack,
} from "@mui/material";

const Lesson = ({ name, banner_image, description, id, audience }) => (
  <CardActionArea href={`/quizzes/${id}`} sx={{ height: "100%" }}>
    <Card raised sx={{ height: "100%" }}>
      {banner_image && (
        <img
          src={banner_image.url}
          alt={banner_image.alt}
          width="100%"
          height="175"
          style={{ objectFit: "cover" }}
        />
      )}
      <CardContent>
        <Stack spacing={1}>
          {audience && <Chip label={`For ${audience.name}`} color="warning" />}
          <Typography variant="h6">{name}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </CardContent>
    </Card>
  </CardActionArea>
);

export default Lesson;
