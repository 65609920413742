import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      "en-US": {
        translation: {
          Quizzify: "Quizzify",
          "Learn More": "Learn More",
          "No questions in quiz.": "No questions in quiz.",
          "Next Question": "Next Question",
          Next: "Next",
          "Contest Information": "Contest Information",
          "Feedback Survey (Optional)": "Feedback Survey (Optional)",
          Finish: "Finish",
          "Harvard Medical School Approved": "Harvard Medical School Approved",
          "Thanks for your input!": "Thanks for your input!",
          questionIofN: "Question {{i}} of {{n}}",
          iOfNCorrectSelected: "{{i}}/{{n}} correct selected",
          completionMessage: "Thanks for completing our quiz!",
          news: "News",
          totalScore: "Total Score:",
          finalScore: "Your final score is:",
          "Please answer to proceed": "Please answer to proceed",
          "Please select one to proceed": "Please select one to proceed",
          Optional: "Optional",
          Submit: "Submit",
          "Take this quiz again": "Take this quiz again:",
          "Save Quiz as PDF": "Save Quiz as PDF",
          "Back to Home Page": "Back to Home Page",
          Replay: "Replay",
          "Select an option before submitting":
            "Select an option before submitting",
          "Thank you for your feedback!": "Thank you for your feedback!",
          "Apple Wallet Passes": "Apple Wallet Passes",
          "Email Address": "Email Address",
        },
      },
      es: {
        translation: {
          "Learn More": "Aprende más",
          "No questions in quiz.": "No hay preguntas en el cuestionario.",
          "Next Question": "Próxima pregunta",
          Next: "Próxima",
          "Contest Information": "Información del concurso",
          "Feedback Survey (Optional)": "Encuesta de comentarios (opcional)",
          Finish: "Terminar",
          "Harvard Medical School Approved":
            "Escuela de medicina de Harvard aprobada",
          "Thanks for your input!": "Gracias por tu contribución",
          questionIofN: "Pregunta {{i}} de {{n}}",
          iOfNCorrectSelected: "{{i}}/{{n}} correcto seleccionado",
          completionMessage: "¡Gracias por completar nuestro cuestionario!",
          news: "Noticias",
          totalScore: "Puntaje total:",
          "Please answer to proceed": "Por favor responda para continuar",
          "Please select one to proceed": "Seleccione uno para continuar",
          Optional: "Opcional",
          Submit: "Enviar",
          "Take this quiz again": "Realice este cuestionario nuevamente:",
          "Save Quiz as PDF": "Guardar cuestionario como PDF",
          "Back to Home Page": "Volver a la pagina principal",
          Replay: "Repetición",
          "Select an option before submitting":
            "Seleccione una opción antes de enviar",
          "Thank you for your feedback!": "¡Gracias por tus comentarios!",
          "Apple Wallet Passes": "Pases de Apple Wallet",
          "Email Address": "Dirección de correo electrónico",
          Login: "Iniciar sesión",
          Logout: "Cerrar sesión",
          Home: "Inicio",
          finalScore: "Tu puntuación final es:",
        },
      },
    },
  });

export default i18n;
