import onSelectSliderAnswer from "./onSelectSliderAnswer";
import onSelectBasicAnswer from "./onSelectBasicAnswer";
import { slider } from "../../utils/formats";
import * as R from "ramda";

const indexLens = R.lensProp("questionIndex");
const questionLens = (questionIndex) =>
  R.lensPath(["quiz", "questions", questionIndex, "format"]);

const getAnswerType = R.converge(R.view, [
  R.pipe(R.view(indexLens), questionLens),
  R.identity,
]);

const isOfType = (answerType) => R.pipe(getAnswerType, R.equals(answerType));

const onSelectAnswer = (action) =>
  R.cond([
    [isOfType(slider), onSelectSliderAnswer(action)],
    [R.T, onSelectBasicAnswer(action)],
  ]);

export default onSelectAnswer;
