import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import isQuestionComplete from "../../Question/isQuestionComplete";

const QuestionIcon = ({ question }) => {
  const isComplete = isQuestionComplete(question);

  return isComplete ? (
    <CheckBoxIcon color="success" />
  ) : (
    <CheckBoxOutlineBlankIcon />
  );
};

export default QuestionIcon;
