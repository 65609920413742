import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Typography,
  CircularProgress,
  Button,
  Link,
  Checkbox,
  Collapse,
  CardHeader,
  Divider,
  CardContent,
  Stack,
} from "@mui/material";
import moment from "moment";
import api from "../api/endpoints";
import { context as authContext, actions as authActions } from "./AuthContext";

const AGREE_BUTTON_PREFACE = "I Understand and Agree to ";
const AGREE_BUTTON_LINK = "Quizzify's Terms and Conditions";

const AGREE_BUTTON_TEXT = `${AGREE_BUTTON_PREFACE}${AGREE_BUTTON_LINK}`;

const getLinkedText = (onClick) => (
  <>
    <Typography>
      {AGREE_BUTTON_PREFACE}
      <Link onClick={onClick} sx={{ cursor: "pointer" }}>
        {AGREE_BUTTON_LINK}
      </Link>
    </Typography>
  </>
);

const Terms = () => {
  const [terms, setTerms] = useState();
  const [agree, setAgree] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const { dispatch } = useContext(authContext);

  useEffect(() => {
    api.terms.getLatest().then(({ data: { terms } }) => {
      setTerms(terms);
    });
  }, []);

  const handleAgree = () => {
    api.terms
      .agree({
        terms,
        acceptance_method: "box_check_and_click",
        acceptance_language: AGREE_BUTTON_TEXT,
      })
      .then(() => {
        dispatch({
          type: authActions.acceptTerms,
        });
      })
      .catch((err) => window.alert(err));
  };

  if (!terms) return <CircularProgress />;

  return (
    <>
      <Card
        sx={{
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Collapse in={showDetail} sx={{ overflowY: "scroll" }}>
          <CardHeader
            title={<Typography variant="h6">Terms and Conditions</Typography>}
            subheader={`Last modified: ${moment(terms.updated_at).calendar()}`}
          />
          <Divider />
          <CardContent>
            <Stack spacing={1}>
              {terms.body.split("\n").map((line, index) =>
                index % 2 === 0 ? (
                  <Typography key={line} variant="subtitle1">
                    {line}
                  </Typography>
                ) : (
                  <Typography key={line} fontSize={14}>
                    {line}
                  </Typography>
                )
              )}
            </Stack>
          </CardContent>
        </Collapse>
        {showDetail && <Divider />}

        <CardContent>
          <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
            <Checkbox
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            <>{getLinkedText(() => setShowDetail(true))}</>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAgree}
            disabled={!agree}
            sx={{ width: "100%" }}
          >
            Proceed
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default Terms;
