import React, { createContext, useReducer } from "react";
import * as R from "ramda";
import keyMirror from "../utils/keyMirror";
import reducer from "../utils/curriedReducer";
import { setAuthToken, clearAuthToken } from "../api/core";

const actions = keyMirror({
  promptLogin: null,
  clearPromptLogin: null,
  login: null,
  logout: null,
  authVerified: null,
  authNotVerified: null,
  loadConfig: null,
  setUserProfile: null,
  acceptTerms: null,
  setPassword: null,
});

const {
  promptLogin,
  clearPromptLogin,
  login,
  logout,
  authVerified,
  authNotVerified,
  loadConfig,
  setUserProfile,
  acceptTerms,
  setPassword,
} = actions;

const defaultValues = {
  isLoggedIn: false,
};

const context = createContext(defaultValues);

const { Provider } = context;

const handlers = {
  [promptLogin]: (action) => {
    return R.mergeDeepLeft({
      promptLogin: true,
      doNotCheckAuth: true,
      companyId: action.companyId,
    });
  },
  [loadConfig]: (action) => (state) => {
    if (action.replace) {
      return {
        ...state,
        config: action.config,
      };
    }
    return {
      ...state,
      config: {
        ...state.config,
        ...action.config,
      },
    };
  },
  [clearPromptLogin]: () => R.mergeDeepLeft({ promptLogin: false }),
  [login]: (action) => {
    if (action.authToken) {
      setAuthToken(action.authToken);
    }

    return R.mergeDeepLeft({
      promptLogin: false,
      isLoggedIn: true,
      doNotCheckAuth: true,
    });
  },
  [authVerified]: () => {
    return R.mergeDeepLeft({
      promptLogin: false,
      isLoggedIn: true,
      doNotCheckAuth: true,
    });
  },
  [authNotVerified]: () => {
    return R.mergeDeepLeft({
      isLoggedIn: false,
      doNotCheckAuth: true,
    });
  },
  [logout]: () => {
    clearAuthToken();
    return R.mergeDeepLeft({ isLoggedIn: false, userProfile: null });
  },
  [setUserProfile]: (action) => (state) => ({
    ...state,
    userProfile: action.userProfile,
  }),
  [acceptTerms]: () => (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      needs_action: {
        ...state.userProfile.needs_action,
        needs_terms: false,
      },
    },
  }),
  [setPassword]: () => (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      needs_action: {
        ...state.userProfile.needs_action,
        needs_password: false,
      },
    },
  }),
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer(handlers), defaultValues);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { context, actions, AuthProvider };
