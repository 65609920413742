import React, { useContext, useState } from "react";
import { Button, Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import pluralize from "pluralize";
import queryString from "query-string";

import { context as quizContext, actions } from "./QuizContext";
import isQuestionComplete from "../Quiz/Question/isQuestionComplete";

const ButtonContent = ({
  questionComplete,
  correctTotal,
  setQuestionSubmitted,
  dispatch,
  nextIncompleteQuestion,
  isLastQuestion,
  nextQuestionIndex,
  state,
  setShowMultiAnswerHelper,
  answersMessage,
}) => {
  const { no_finish } = queryString.parse(window.location.search);

  const { t } = useTranslation();

  let activeButtonLabel = isLastQuestion ? t("Finish") : t("Next Question");

  if ((questionComplete || correctTotal === 0) && no_finish) {
    return null;
  }

  if (questionComplete || correctTotal === 0) {
    return (
      <Button
        disabled={!questionComplete}
        variant="contained"
        color="secondary"
        onClick={(_) => {
          setQuestionSubmitted(false);
          if (!nextIncompleteQuestion) {
            dispatch({
              type: actions.completeQuiz,
            });
          } else {
            dispatch({
              type: actions.onChangeQuestion,
              data: nextQuestionIndex,
            });
          }
        }}
      >
        {questionComplete
          ? activeButtonLabel
          : t("questionIofN", {
              i: state.questionIndex + 1,
              n: state.quiz.questions.length,
            })}
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      color="warning"
      onClick={() => setShowMultiAnswerHelper(true)}
    >
      {answersMessage}
    </Button>
  );
};

const PrimaryButton = ({ question, score, setQuestionSubmitted, lang }) => {
  const [showMultiAnswerHelper, setShowMultiAnswerHelper] = useState();
  const { dispatch, state } = useContext(quizContext);

  let { correctSelected, correctTotal } = score;

  let questionComplete = isQuestionComplete(question);

  let isLastQuestion = state.questionIndex + 1 === state.quiz.questions.length;
  let nextQuestionIndex = state.questionIndex + 1;
  let nextIncompleteQuestion = null;

  try {
    const { quiz } = state;
    const { questions } = quiz;
    nextIncompleteQuestion = questions.find(
      (q) => q.id && !isQuestionComplete(q)
    );

    if (nextIncompleteQuestion) {
      nextQuestionIndex = questions.indexOf(nextIncompleteQuestion);

      // To account for non-linear quizzes
      isLastQuestion = false;
    }
  } catch (error) {
    console.error(error);
  }

  const remainingCorrectAnswers = correctTotal - correctSelected;
  let answersMessage = `Select ${remainingCorrectAnswers} ${
    correctSelected > 0 ? "more " : ""
  }correct
  ${pluralize("answer", remainingCorrectAnswers)}`;

  if (lang === "es") {
    answersMessage = `Seleccione ${remainingCorrectAnswers} respuesta
    ${remainingCorrectAnswers > 1 ? "s" : ""} correcta
    ${remainingCorrectAnswers > 1 ? "s" : ""}`;
  }
  return (
    <React.Fragment>
      <Snackbar
        open={showMultiAnswerHelper}
        autoHideDuration={5000}
        onClose={() => setShowMultiAnswerHelper(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setShowMultiAnswerHelper(false)}
          severity="warning"
        >
          {answersMessage} to proceed.
        </Alert>
      </Snackbar>
      <ButtonContent
        questionComplete={questionComplete}
        correctTotal={correctTotal}
        setQuestionSubmitted={setQuestionSubmitted}
        dispatch={dispatch}
        nextIncompleteQuestion={nextIncompleteQuestion}
        isLastQuestion={isLastQuestion}
        nextQuestionIndex={nextQuestionIndex}
        state={state}
        setShowMultiAnswerHelper={setShowMultiAnswerHelper}
        answersMessage={answersMessage}
      />
    </React.Fragment>
  );
};

export default PrimaryButton;
