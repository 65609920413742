import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReactHtmlParser from "react-html-parser";
import transform from "../../utils/transformLinks";

const useStyles = makeStyles((theme) => ({
  newsContainer: {
    wordBreak: "break-word",
  },
}));

const NewsFeed = ({ newsFeed }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>News</Typography>
        }
        avatar={<NewspaperIcon />}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          {newsFeed.map((n) => (
            <Grid key={n.id} item xs={12}>
              <Typography className={classes.newsContainer} component="div">
                {ReactHtmlParser(n.text, {
                  transform,
                })}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NewsFeed;
