import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  LinearProgress,
  Box,
  Button,
} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import pluralize from "pluralize";
import _ from "lodash";

import IncentiveStatusModal from "./IncentiveStatusModal";
import transform from "../../../utils/transformLinks";

const Progress = ({ contest }) => {
  if (!contest) return null;
  if (contest.style !== "default") return null;

  const {
    show_tracker,
    show_progress,
    format,
    quiz_completions,
    target_quiz_completions,
  } = contest;

  if (
    (show_tracker || (show_progress && format === "threshold")) &&
    target_quiz_completions > 0
  ) {
    const completionRatio = Math.min(
      100,
      (100 * quiz_completions) / target_quiz_completions
    );
    const remainingQuizzes = Math.max(
      target_quiz_completions - quiz_completions,
      0
    );
    const completedContest = remainingQuizzes === 0;

    const progress = `${Math.min(
      target_quiz_completions,
      quiz_completions
    )}/${target_quiz_completions}`;

    return (
      <Box sx={{ mt: 2 }}>
        <Divider sx={{ mb: 1 }} />
        <Typography variant="caption">Your Progress</Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              id="contest-progress-bar"
              sx={{ height: 10, borderRadius: 5 }}
              variant="determinate"
              value={completionRatio}
              color={completedContest ? "success" : "primary"}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {progress}
            </Typography>
          </Box>
        </Box>

        {completedContest ? (
          <>
            <Typography gutterBottom>
              Congratulations! You've completed enough quizzes to qualify!
            </Typography>
            <Typography>
              But you can still keep going to review and learn more.
            </Typography>
          </>
        ) : (
          <Typography>
            Finish {remainingQuizzes} more {pluralize("quiz", remainingQuizzes)}{" "}
            to qualify!
          </Typography>
        )}
      </Box>
    );
  }

  return null;
};

const Contest = ({
  message,
  contest,
  title = "Contest",
  revealContestStatusPopup,
}) => {
  const [showIncentiveStatus, setShowIncentiveStatus] = useState(false);

  // Contest overview takes precedence as message is deprecated
  const overview = _.get(contest, "overview") || message;
  if (!overview) return null;

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
            {title}
          </Typography>
        }
        avatar={<EmojiEventsIcon />}
      />
      <Divider />
      <CardContent>
        <Typography sx={{ wordBreak: "break-word" }} component="div">
          {ReactHtmlParser(overview, {
            transform,
          })}
        </Typography>
        {revealContestStatusPopup && contest && (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowIncentiveStatus(true)}
            >
              Check My Incentive Status
            </Button>
            <IncentiveStatusModal
              open={showIncentiveStatus}
              handleClose={() => setShowIncentiveStatus(false)}
              contest={contest}
            />
          </>
        )}
        <Progress contest={contest} />
      </CardContent>
    </Card>
  );
};

export default Contest;
