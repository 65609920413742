import { score as initialState } from "../../StateModel";
const R = require("ramda");

const pointValues = {
  incorrect: -25,
  correct: 100,
};

const questionLens = (questionIndex) =>
  R.lensPath(["quiz", "questions", questionIndex]);

const answersLens = (idx) =>
  R.compose(questionLens(idx), R.lensProp("answers"));

const scoreReducer = (acc, answer) => {
  const { wasSelected, isCorrect } = answer;
  const incorrectSelected =
    !isCorrect && wasSelected
      ? acc.incorrectSelected + 1
      : acc.incorrectSelected;
  const correctSelected =
    isCorrect && wasSelected ? acc.correctSelected + 1 : acc.correctSelected;
  const correctTotal = isCorrect ? acc.correctTotal + 1 : acc.correctTotal;

  return {
    incorrectSelected,
    correctSelected,
    correctTotal,
    total:
      incorrectSelected * pointValues.incorrect +
      (correctSelected === correctTotal ? pointValues.correct : 0),
  };
};

const correctAnswersExhausted = R.curry((question, _answer) => {
  const { correctSelected, correctTotal } = R.defaultTo(initialState)(
    question.score
  );
  return correctSelected !== 0 && correctSelected === correctTotal;
});

const scoreQuestion = (question) =>
  R.pipe(
    R.view(R.lensProp("answers")),
    R.ifElse(
      correctAnswersExhausted(question),
      R.always(question.score),
      R.reduce(scoreReducer, initialState)
    ),
    R.set(R.lensProp("score"), R.__, question)
  )(question);

const aggregateQuestionScores = R.pipe(
  R.view(R.lensPath(["quiz", "questions"])),
  R.reduce((acc, q) => {
    const score = R.defaultTo(initialState)(q.score);
    return {
      incorrectSelected: acc.incorrectSelected + score.incorrectSelected,
      correctSelected: acc.correctSelected + score.correctSelected,
      correctTotal: acc.correctTotal + score.correctTotal,
      total: acc.total + score.total,
    };
  }, initialState)
);

const isSelectedQuestion = R.curry((action) =>
  R.pipe(R.view(R.lensProp("id")), R.equals(action.answerId))
);

const setSelected = R.set(R.lensProp("wasSelected"), true);
const viewQuestionIndex = R.view(R.lensProp("questionIndex"));

const setAnswerSelected = (action) =>
  R.converge(R.over, [
    R.pipe(viewQuestionIndex, answersLens),
    R.always(
      R.map(R.ifElse(isSelectedQuestion(action), setSelected, R.identity))
    ),
    R.identity,
  ]);

const updateQuestionScore = R.converge(R.over, [
  R.pipe(viewQuestionIndex, questionLens),
  R.always(scoreQuestion),
  R.identity,
]);

const updateTotalScore = R.converge(R.set, [
  R.always(R.lensProp("score")),
  aggregateQuestionScores,
  R.identity,
]);

const onSelectBasicAnswer = (action) =>
  R.pipe(setAnswerSelected(action), updateQuestionScore, updateTotalScore);

export default onSelectBasicAnswer;
