import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { context, actions } from "./PublicHomeContext";
import { context as authContext } from "../AuthContext";
import {
  context as quizContext,
  actions as quizActions,
} from "../Quiz/QuizContext";
import endpoints from "../../api/endpoints";
import { Loader } from "../Loader";
import PublicHome from "./PublicHome";
import { Error } from "../Error";
import { AdminWelcome } from "./AdminWelcome";
import SimpleHome from "../SimpleHome";

const PublicHomeController = () => {
  const { companyId } = useParams();
  const { adminWelcome } = queryString.parse(window.location.search);

  const { state, dispatch } = useContext(context);
  const { dispatch: authDispatch } = useContext(authContext);
  const { dispatch: quizDispatch } = useContext(quizContext);

  const { id, hasApiError, account_type } = state;

  const noCompanyPresent = !id;

  useEffect(() => {
    authDispatch({
      type: actions.clearPromptLogin,
    });

    quizDispatch({
      type: quizActions.clearQuiz,
    });

    endpoints.publicHome
      .get(companyId)
      .then((res) => {
        dispatch({ type: actions.loadState, data: res.data });
      })
      .catch((error) => {
        dispatch({ type: actions.error });
        console.error(error);
      });

    endpoints.publicHome
      .getTips(companyId)
      .then((res) => {
        dispatch({ type: actions.loadState, data: res.data });
      })
      .catch((error) => {
        dispatch({ type: actions.error });
        console.error(error);
      });

    endpoints.publicHome
      .getNews(companyId)
      .then((res) => {
        dispatch({ type: actions.loadState, data: res.data });
      })
      .catch((error) => {
        dispatch({ type: actions.error });
        console.error(error);
      });

    endpoints.publicHome
      .getQuizzes(companyId)
      .then((res) => {
        dispatch({ type: actions.loadState, data: res.data });
      })
      .catch((error) => {
        dispatch({ type: actions.error });
        console.error(error);
      });

    endpoints.publicHome
      .getSalesMessage(companyId)
      .then((res) => {
        dispatch({ type: actions.loadState, data: res.data });
      })
      .catch((error) => {
        dispatch({ type: actions.error });
        console.error(error);
      });
  }, [quizDispatch, companyId, dispatch, authDispatch]);

  if (hasApiError) return <Error />;

  if (noCompanyPresent) return <Loader />;

  if (["pilot", "individual"].includes(account_type))
    return <SimpleHome state={state} />;

  if (adminWelcome)
    return (
      <AdminWelcome state={state}>
        <PublicHome state={state} />
      </AdminWelcome>
    );

  return <PublicHome state={state} />;
};

export default PublicHomeController;
