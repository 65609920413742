import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Typography,
  TextField,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";
import { EMAIL_REGEX } from "./constants";

const DefaultForm = ({ onLogin, config }) => {
  const { t } = useTranslation();

  const configEmail = _.get(config, "email", "");
  const loginType = _.get(config, "loginType");

  const { handleSubmit, setValue, register, formState, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      email: configEmail,
    },
  });

  useEffect(() => {
    setValue("email", configEmail);
  }, [configEmail, setValue]);

  // Use the useForm hook to create a form instance
  const onSubmit = handleSubmit((values) => {
    const expandedValues = {
      ...values,
      email: values.email || configEmail,
      company_id: _.get(config, "company.id"),
      loginType,
    };

    onLogin(expandedValues);
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid container direction="column" spacing={2}>
        {errors.login && (
          <Grid item>
            <Typography color="error">{errors.login.message}</Typography>
          </Grid>
        )}
        <Grid item>
          <TextField
            name="email"
            label={t("Email Address")}
            variant="outlined"
            inputRef={register({
              required: t("You must enter an email"),
              validate: {
                isEmail: (value) =>
                  EMAIL_REGEX.test(value)
                    ? true
                    : t("You must enter a valid email address"),
              },
            })}
            aria-label={t("Email Address")}
            fullWidth={true}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            disabled={formState.isSubmitting}
            endIcon={formState.isSubmitting && <CircularProgress size={20} />}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DefaultForm;
