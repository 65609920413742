import React from "react";
import { Grid } from "@mui/material";
import TileAnswer from "./TileAnswer";

const TileAnswers = ({ question }) => (
  <Grid container spacing={3}>
    {question.answers.map((answer) => (
      <Grid key={answer.id} item xs={12} sm={6} md={3}>
        <TileAnswer {...answer} />
      </Grid>
    ))}
  </Grid>
);

export default TileAnswers;
