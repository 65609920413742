import React from "react";
import { Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { getMainRoot } from "../../utils/externalRoutes";

const AdminLink = ({ show }) => (
  <>
    {show ? (
      <Button
        startIcon={<SettingsIcon />}
        href={`${getMainRoot()}/admin`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Admin
      </Button>
    ) : null}
  </>
);

export default AdminLink;
