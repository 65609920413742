// import { score as initialScore } from "../StateModel";
// import { slider } from "../utils/formats";
const R = require("ramda");

// const questionsLens = R.lensPath(["quiz", "questions"]);
// const scoreLens = R.lensProp("score");
// const correctTotalLens = R.lensProp("correctTotal");
// const scoreCorrectTotalLens = R.compose(scoreLens, correctTotalLens);

// const setQuestionScore = (q) => {
//   if (q.format === slider) return R.set(scoreCorrectTotalLens, 1)(q);

//   const correctTotal = R.pipe(
//     R.view(R.lensProp("answers")),
//     R.reduce(
//       (acc, answer) =>
//         R.ifElse(R.prop("isCorrect"), R.always(acc + 1), R.always(acc))(answer),
//       0
//     )
//   )(q);

//   return R.set(scoreCorrectTotalLens, correctTotal)(q);
// };

// const setStartingScore = R.map(setQuestionScore);
// const setDefaultScore = R.map(R.set(scoreLens, initialScore));

const onLoadState = (action, state) =>
  R.compose(
    // R.over(questionsLens, setStartingScore),
    // R.over(questionsLens, setDefaultScore),
    R.assoc("quizComplete", false),
    R.mergeDeepLeft(action.data)
  )(state);

export default onLoadState;
