import React from "react";
import { LinearProgress, Typography, Box } from "@mui/material";

const LinearProgressWithLabel = (props) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    {props.label && (
      <Box minWidth={100}>
        <Typography variant="body2" color="textSecondary">
          {props.label}
        </Typography>
      </Box>
    )}
  </Box>
);

export default LinearProgressWithLabel;
