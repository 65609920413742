import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Container,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MobileDetect from "mobile-detect";
import "./hover.css";
import { context } from "../AuthContext";
import LoginController from "../LoginController";
import endpoints from "../../api/endpoints";
import ClassicButton from "./ClassicButton";
import TogoButton from "./TogoButton";

const NewUserWelcome = () => {
  const [company, setCompany] = useState({});
  const [togoAccess, setTogoAccess] = useState();
  const [user, setUser] = useState();

  const history = useHistory();
  const { companyId } = useParams();

  const { state } = useContext(context);
  const { isLoggedIn } = state;

  useEffect(() => {
    endpoints.companies
      .public({
        company_id: companyId,
      })
      .then((res) => setCompany(res.data.company));
  }, [companyId]);

  useEffect(() => {
    if (isLoggedIn) {
      endpoints
        .getTogoLink()
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            setTogoAccess(response.data);
          }
        })
        .catch((error) => window.alert(error));

      endpoints.auth
        .profile()
        .then((response) => {
          setUser(response.data.current_user);
        })
        .catch((err) => console.log("Profile Error: ", err));
    } else {
      setTogoAccess();
    }
  }, [isLoggedIn]);

  const shouldShowLogin = history.location.search.includes("login=true");

  const md = new MobileDetect(window.navigator.userAgent);

  const isIphone = md.is("iPhone");
  const isAndroid = md.is("Android") || md.os() === "AndroidOS";
  const isMobile = isIphone || isAndroid;

  if (shouldShowLogin) {
    return <LoginController companyId={companyId} />;
  }

  return (
    <div
      style={{
        minHeight: "100%",
        backgroundColor: "#eeeeee",
        paddingBottom: 100,
      }}
    >
      <Container>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "24px",
            height: "auto",
            backgroundColor: "transparent",
          }}
        >
          {company.logo && (
            <Grid
              item
              xs={12}
              sm={3}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  maxWidth: 275,
                  maxHeight: 150,
                }}
              >
                <img
                  src={company.logo}
                  alt="Logo"
                  style={{
                    width: "100%",
                    objectFit: "contain !important",
                  }}
                />
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <AddIcon fontSize="large" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                maxWidth: 225,
                maxHeight: 95,
              }}
            >
              <img
                src="/img/quizzify-logo-wordmark.svg"
                alt="Logo"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain !important",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={(theme) => ({
          p: 2,
          backgroundColor: theme.palette.primary.main,
        })}
      >
        <Typography
          color="white"
          sx={{ fontWeight: "normal", fontSize: 24, textAlign: "center" }}
        >
          Get smart about your{" "}
          <span
            style={{ fontWeight: "bold", borderBottom: "5px solid #64ffda" }}
          >
            health
          </span>
          ,{" "}
          <span
            style={{ fontWeight: "bold", borderBottom: "5px solid #64ffda" }}
          >
            healthcare
          </span>{" "}
          and{" "}
          <span
            style={{ fontWeight: "bold", borderBottom: "5px solid #64ffda" }}
          >
            benefits
          </span>
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#eeeeee",
          p: 2,
          pt: 3.5,
        }}
      >
        {user ? (
          <Typography
            sx={{ fontWeight: 700, fontSize: 24, textAlign: "center", pb: 2 }}
          >
            Welcome Back, {user.display_name}! Select which product you'd like
            to use:
          </Typography>
        ) : (
          <Typography
            sx={{ fontWeight: 700, fontSize: 24, textAlign: "center", pb: 2 }}
          >
            Two products that boost your health literacy
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "#eeeeee",
          p: 1,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={6}>
            <Grid item sm={12} md={6}>
              <Card raised>
                <CardHeader
                  title={
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{
                        fontWeight: "900",
                        fontFamily: "Didot",
                        color: "#2962FF",
                        textAlign: "center",
                      }}
                    >
                      Quizzify Classic
                    </Typography>
                  }
                  subheader={
                    <Stack spacing={2}>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontWeight: 700,
                          fontSize: 20,
                        }}
                        variant="subtitle1"
                      >
                        Learn as you go by playing educational, engaging quizzes
                      </Typography>
                      <ClassicButton
                        isLoggedIn={isLoggedIn}
                        companyId={companyId}
                      />
                    </Stack>
                  }
                />
                <Divider />
                <div className="hoverContainer">
                  <div className="modal">
                    <div className="innerContainer">
                      <ClassicButton
                        isLoggedIn={isLoggedIn}
                        companyId={companyId}
                        buttonOnly
                      />
                    </div>
                  </div>

                  <div className="content">
                    <CardContent
                      sx={{
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <Box
                        sx={(theme) => ({
                          height: 600,
                          textAlign: "center",
                          boxSizing: "border-box",
                          [theme.breakpoints.down("md")]: {
                            height: 400,
                          },
                          [theme.breakpoints.down("sm")]: {
                            height: "auto",
                          },
                        })}
                      >
                        <img
                          src="/img/classic.png"
                          alt="Classic preview"
                          style={{
                            border: "1px solid #f5f5f5",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item sm={12} md={6}>
              <Card raised>
                <CardHeader
                  title={
                    <>
                      <Typography
                        variant="h3"
                        gutterBottom
                        style={{
                          fontWeight: "900",
                          fontFamily: "Didot",
                          color: "#2962FF",
                          textAlign: "center",
                        }}
                      >
                        Quizzify
                        <span
                          style={{
                            color: "rgb(70, 178, 152)",
                          }}
                        >
                          2Go
                        </span>
                      </Typography>
                    </>
                  }
                  subheader={
                    <Stack spacing={2}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          textAlign: "center",
                          fontWeight: 700,
                          fontSize: 20,
                        }}
                      >
                        Download our mobile app to help with ER, doctor or
                        dentist visits
                      </Typography>
                      <TogoButton
                        isLoggedIn={isLoggedIn}
                        togoAccess={togoAccess}
                        companyId={companyId}
                        isMobile={isMobile}
                      />
                    </Stack>
                  }
                />
                <Divider />
                <div className="hoverContainer">
                  <div className="modal">
                    <div className="innerContainer">
                      <TogoButton
                        isLoggedIn={isLoggedIn}
                        togoAccess={togoAccess}
                        companyId={companyId}
                        isMobile={isMobile}
                        buttonOnly
                      />
                    </div>
                  </div>

                  <div className="content">
                    <CardContent
                      sx={{
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <Box
                        sx={(theme) => ({
                          height: 600,
                          textAlign: "center",
                          boxSizing: "border-box",
                          [theme.breakpoints.down("md")]: {
                            height: 400,
                          },
                          [theme.breakpoints.down("sm")]: {
                            height: "auto",
                          },
                        })}
                      >
                        <img
                          src="/img/2go-annotated.png"
                          alt="2Go preview"
                          style={{
                            border: "1px solid #f5f5f5",
                            maxWidth: "100%",
                            objectFit: "contain",
                            maxHeight: "100%",
                          }}
                        />
                      </Box>
                    </CardContent>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default NewUserWelcome;
