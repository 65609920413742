import React from "react";
import QuestionImage from "./QuestionImage";
import isQuestionComplete from "../isQuestionComplete";

const Images = ({ question }) => {
  const images = question.images;

  const questionComplete = isQuestionComplete(question);

  if (images.length === 0) return null;

  const questionStartOrOnlyImage =
    images.find((i) => i.usage === "questionStart") ||
    (images[0].usage !== "questionEnd" ? images[0] : null);

  const questionEndImage =
    images.find((i) => i.usage === "questionEnd") ||
    (images.length >= 2 ? images[1] : null);

  // Only slider questions should show the image up top at the end
  if (questionComplete && questionEndImage && question.format === "slider") {
    return <QuestionImage image={questionEndImage} />;
  }

  if (questionStartOrOnlyImage) {
    return <QuestionImage image={questionStartOrOnlyImage} />;
  }

  return null;
};

export default Images;
