import React, { useState } from "react";
import { CircularProgress, Button, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useForm } from "react-form";
import * as R from "ramda";
import keyMirror from "../../utils/keyMirror";
import { RadioField, TextField } from "../form/fields";
import { useTranslation } from "react-i18next";
import endpoints from "../../api/endpoints";
import LinearProgressWithLabel from "../LinearProgressWithLabel";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: 85,
  },
}));

const SurveyForm = ({ survey, state }) => {
  const classes = useStyles();
  const { survey: surveyEndpoint } = endpoints;
  const { t } = useTranslation();
  const { content } = survey;
  const [contentIndex, setContentIndex] = useState(0);
  const [surveyId, setSurveyId] = useState();
  const [finished, setFinished] = useState(false);

  const contentItem = content[contentIndex];
  const onNext = () => {
    if (contentIndex < content.length - 1) {
      setContentIndex(contentIndex + 1);
    } else {
      setFinished(true);
    }
  };

  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    debugForm: false,
    onSubmit: (values) =>
      surveyEndpoint
        .post({
          prompt: contentItem.prompt,
          response: values[contentItem.prompt],
          quiz_id: state.quiz.id,
          company_id: state.company.id,
          survey_id: surveyId,
        })
        .then((res) => {
          setSurveyId(res.data.survey_id);
          onNext();
        }),
  });

  const { textInput, radioButtons } = keyMirror({
    textInput: null,
    radioButtons: null,
  });

  const typeLens = R.lensProp("type");
  const isType = (type) => R.pipe(R.view(typeLens), R.equals(type));

  let prompt = (item) =>
    `${item.prompt}${!item.required ? ` (${t("Optional")})` : ""}`;

  const createQuestion = R.cond([
    [
      isType(textInput),
      (s) => (
        <TextField
          fullWidth
          label={prompt(s)}
          field={s.prompt}
          placeholder={s.placeholder}
          validate={
            s.required
              ? (value) => (!!value ? false : t("Please answer to proceed"))
              : null
          }
        />
      ),
    ],
    [
      isType(radioButtons),
      (s) => (
        <RadioField
          color="primary"
          field={s.prompt}
          options={s.content}
          label={prompt(s)}
          validate={(value) =>
            !!value ? false : t("Please select one to proceed")
          }
        />
      ),
    ],
  ]);

  const submitText =
    contentIndex === content.length - 1 ? t("Submit") : t("Next");

  return (
    <>
      <Grid item xs={12} md={9}>
        {finished ? (
          <h3>{t("Thank you for your feedback!")}</h3>
        ) : (
          <>
            <Form>
              <Box>{createQuestion(contentItem)}</Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  className={classes.submitButton}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={!canSubmit || isSubmitting}
                >
                  {isSubmitting ? <CircularProgress size={20} /> : submitText}
                </Button>
                <Box width="100%" ml={2}>
                  <LinearProgressWithLabel
                    value={100 * ((contentIndex + 1) / content.length)}
                    label={`Question ${contentIndex + 1} of ${content.length}`}
                  />
                </Box>
              </Box>
            </Form>
          </>
        )}
      </Grid>
    </>
  );
};

export default SurveyForm;
