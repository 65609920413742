import "./App.css";
import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { Helmet } from "react-helmet";
import queryString from "query-string";

import store from "./redux/store";
import { Provider } from "react-redux";

import AppRouter from "./AppRouter";
import muiTheme from "./mui-theme";
import AppHeader from "./components/AppHeader";
import { AuthProvider } from "./components/AuthContext";
import Authorization from "./components/Authorization";
import { QuizStateProvider } from "./components/Quiz";
import { PublicHomeProvider } from "./components/PublicHome";
import ZendeskLoader from "./components/ZendeskLoader";
import generateClassName from "./generateClassName";

const theme = createTheme(muiTheme);

const App = () => {
  const { embed, endScreen } = queryString.parse(window.location.search);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          http-equiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </Helmet>
      <Provider store={store}>
        <StylesProvider injectFirst generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <Router>
              <AuthProvider>
                <PublicHomeProvider>
                  <QuizStateProvider>
                    <Authorization>
                      <ZendeskLoader />
                      {!embed && <AppHeader />}
                      <AppRouter endScreen={endScreen} />
                    </Authorization>
                  </QuizStateProvider>
                </PublicHomeProvider>
              </AuthProvider>
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    </>
  );
};

export default App;
