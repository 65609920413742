import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const BasicTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 2 }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map((t, i) => (
            <Tab key={t.label} label={t.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((t, i) => (
        <TabPanel key={t.label} value={value} index={i}>
          {t.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default BasicTabs;
