import React from "react";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const HelpButton = ({ title = "Help", size }) => (
  <Button
    startIcon={<HelpOutlineIcon />}
    size={size}
    onClick={() => {
      if (window && window.zE) {
        window.zE("webWidget", "show");
        window.zE("webWidget", "open");
      }
    }}
  >
    {title}
  </Button>
);

export default HelpButton;
