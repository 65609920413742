import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const PublicHomePreview = ({ children }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" align="center" color="primary">
            Preview: Your Public Home Page
          </Typography>
        }
      />
      <Divider />
      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};

export default PublicHomePreview;
