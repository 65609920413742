import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useMobileBreakpoint = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return isMobile;
};

const useWidescreenBreakpoint = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("xl"));
  return isMobile;
};

export { useMobileBreakpoint, useWidescreenBreakpoint };
