import React from "react";
import TocQuiz from "./TocQuiz";
import BasicQuiz from "./BasicQuiz";

const QuizContent = ({ showTableOfContents, ...rest }) => {
  if (showTableOfContents) {
    return <TocQuiz {...rest} />;
  }

  return <BasicQuiz {...rest} />;
};

export default QuizContent;
