import React from "react";
import { TextField } from "@mui/material";
import { useField, splitFormProps } from "react-form";

const FormTextField = (props) => {
  const [field, fieldOptions, { label, ...rest }] = splitFormProps(props);

  const {
    value = "",
    setValue,
    meta: { error },
  } = useField(field, fieldOptions);

  const handleChange = (e) => setValue(e.target.value);

  return (
    <TextField
      {...rest}
      label={label}
      aria-label={field}
      name={field}
      value={value}
      onChange={handleChange}
      error={!!error}
      helperText={error}
    />
  );
};

export default FormTextField;
