import React from "react";
import { Divider, CardContent } from "@mui/material";
import _ from "lodash";
import EmailPasswordForm from "./EmailPasswordForm";
import CustomFieldsForm from "./CustomFieldsForm";
import DefaultForm from "./DefaultForm";
import EmailLabel from "./EmailLabel";

const LoginForm = ({ config, ...rest }) => {
  const loginType = _.get(config, "loginType");
  const fields = _.get(config, "fields");

  if (loginType === "not_on_roster") {
    return (
      <>
        <Divider />
        <CardContent>
          <EmailLabel config={config} {...rest} />
        </CardContent>
      </>
    );
  }

  if (loginType === "set_pw") {
    return (
      <>
        <Divider />
        <CardContent>
          <EmailPasswordForm confirm config={config} {...rest} />
        </CardContent>
      </>
    );
  }

  if (loginType === "no_login") {
    return null;
  }

  if (loginType === "emailpwLogin") {
    return (
      <>
        <Divider />
        <CardContent>
          <EmailPasswordForm config={config} {...rest} />
        </CardContent>
      </>
    );
  }

  if (fields) {
    return (
      <>
        <Divider />
        <CardContent>
          <CustomFieldsForm config={config} {...rest} />
        </CardContent>
      </>
    );
  }

  // This should be the default starting point unless stated otherwise by the API
  return (
    <>
      <Divider />
      <CardContent>
        <DefaultForm {...rest} config={config} />
      </CardContent>
    </>
  );
};

export default LoginForm;
