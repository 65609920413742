import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import Lesson from "./Lesson";

const SimpleHome = ({ state: { name, quizzes } }) => {
  const pinned = quizzes?.filter((lesson) => lesson.pinned);
  const rest = quizzes?.filter((lesson) => !lesson.pinned);

  return (
    <Box sx={{ maxHeight: "100%", overflowY: "auto" }}>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ mb: 2, color: "#555555" }}
          >
            {name}
          </Typography>
          <Typography variant="h6">
            Select Any Health Literacy Lesson to Learn More
          </Typography>
        </Box>

        <Grid container spacing={4} sx={{ p: 2 }}>
          {pinned?.map((lesson) => (
            <Grid item key={lesson.id} xs={12} sm={6} md={4}>
              <Lesson {...lesson} />
            </Grid>
          ))}
          {rest?.map((lesson) => (
            <Grid item key={lesson.id} xs={12} sm={6} md={4}>
              <Lesson {...lesson} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default SimpleHome;
