import React, { useRef, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";

const GoogleMap = ({
  position,
  setLocations,
  locations,
  setLocationComplete,
}) => {
  const googleMapRef = useRef();
  let googleMap;

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

  useEffect(() => {
    if (googleMapsApiKey) {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", () => {
        getNearbyHospitals();
      });
    }
  }, [googleMapsApiKey]);

  const createGoogleMap = () => {
    googleMap = new window.google.maps.Map(googleMapRef.current, {
      zoom: 11,
      center: position,
      disableDefaultUI: true,
      gestureHandling: "none",
      scrollwheel: false,
    });
  };

  const getNearbyHospitals = () => {
    createGoogleMap();

    const service = new window.google.maps.places.PlacesService(googleMap);

    service.nearbySearch(
      {
        location: position,
        rankBy: window.google.maps.places.RankBy.DISTANCE,
        keyword: "emergency room AND 24 hours",
      },
      (results, status) => {
        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !results
        ) {
          setLocationComplete(true);
          return;
        }

        // Results are already sorted by distance
        const shortList = results.slice(0, 10);

        setLocations(shortList);
        addPlaces(shortList, googleMap);
        setLocationComplete(true);
      }
    );
  };

  function addPlaces(places, map) {
    var bounds = new window.google.maps.LatLngBounds();

    for (const place of places) {
      if (place.geometry && place.geometry.location) {
        const marker = new window.google.maps.Marker({
          position: place.geometry.location,
          map,
          label: {
            text: "\ue548",
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "18px",
          },
          title: place.name,
        });

        bounds.extend(marker.getPosition());
      }
    }

    map.fitBounds(bounds, 15);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <div
          id="google-map"
          ref={googleMapRef}
          style={{ width: "100%", maxWidth: 500, height: 300 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {locations ? (
          <ul style={{ margin: 0 }}>
            {locations.map((l) => {
              return (
                <li
                  key={l.name + l.vicinity}
                  style={{ marginBottom: 3, fontSize: 12 }}
                >
                  {l.name}
                  <br />
                  <Typography variant="caption">({l.vicinity})</Typography>
                </li>
              );
            })}
          </ul>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

export default GoogleMap;
