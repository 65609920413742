import React from "react";
import { List } from "@mui/material";
import AnswerItem from "./AnswerItem";
import isQuestionComplete from "../isQuestionComplete";
import QuestionImage from "../QuestionImages/QuestionImage";

const EndImage = ({ question }) => {
  const questionComplete = isQuestionComplete(question);

  const images = question.images;

  if (!images) return null;

  let questionEndImage =
    images?.find((i) => i.usage === "questionEnd") ||
    (images?.length >= 2 ? images[1] : null);

  if (questionComplete && questionEndImage) {
    return <QuestionImage image={questionEndImage} />;
  }

  return null;
};

const AnswerList = ({ question }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <List>
      {question.answers.map((answer) => (
        <AnswerItem
          key={answer.id}
          answer={answer}
          isQuestionComplete={isQuestionComplete(question)}
          expanded={expanded}
          handleChange={handleChange(answer.id)}
          endImage={answer.isCorrect ? <EndImage question={question} /> : null}
        />
      ))}
    </List>
  );
};

export default AnswerList;
