import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Stack,
  CardContent,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Container,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import SharePilotDialogue from "./SharePilotDialogue";
import Curriculum from "./Curriculum";

const productItems = [
  {
    key: "care",
    icon: "❤️",
    iconAriaLabel: "Heart Icon",
    headline:
      "Invest in education you and your colleagues will actually use, and appreciate",
  },
  {
    key: "cost",
    icon: "💵",
    iconAriaLabel: "Money Icon",
    headline:
      "Start tackling the $3,800 of wasted healthcare spend per employee per year",
  },
];

const items = [
  {
    key: "lessons",
    icon: "🎓",
    iconAriaLabel: "Lessons Icon",
    headline: "12 Valuable Lessons in a Two-Month Pilot",
    subheadline: "Six up front and then one per week",
    buttonText: "Preview Curriculum",
    secondaryActionValue: "curriculum",
  },
  {
    key: "team",
    icon: "👋",
    iconAriaLabel: "Hand Waving Icon",
    headline: "Invite Your Colleagues",
    // subheadline:
    // "Get 100 credits to share how you like (1 Credit = 1 Lesson Completed)",
  },
  {
    key: "diagnostic",
    icon: "📊",
    iconAriaLabel: "Chart Icon",
    headline: "Discover How Health Literate Your Team Is",
    subheadline: "We'll send you a 'Knowledge Gaps Report', and their impact",
  },
  {
    key: "credit",
    icon: "💰",
    iconAriaLabel: "Credit Icon",
    headline: "The $500 Fee is 100% Creditable to Future Quizzify Services",
  },
];

const PilotOffer001 = () => {
  const [showShareDialogue, setShowShareDialogue] = useState(false);
  const [detail, setDetail] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const original_url = searchParams.get("original_url");

  const shareUrl = original_url || window.location.href;

  return (
    <Container sx={{ pt: 8 }}>
      <Card raised>
        <SharePilotDialogue
          shareUrl={shareUrl}
          open={showShareDialogue}
          onClose={() => setShowShareDialogue(false)}
        />
        <Curriculum
          open={detail === "curriculum"}
          onClose={() => setDetail(null)}
        />

        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid item md={6} sm={12} xs={12} sx={{ height: "100%" }}>
              <CardContent
                sx={(theme) => ({
                  backgroundColor: theme.palette.primary.main,
                  height: "100%",
                  p: 3,
                })}
              >
                <Typography
                  variant="h4"
                  sx={{ mb: 1, fontWeight: "bold", color: "white" }}
                  textAlign="center"
                >
                  Is Your Team's Health Literacy Worth $500?
                </Typography>
                <Stack spacing={2}>
                  <List>
                    {productItems.map((item) => (
                      <ListItem key={item.key} sx={{ mb: 2 }}>
                        <ListItemAvatar sx={{ fontSize: 40 }}>
                          <span role="img" aria-label={item.iconAriaLabel}>
                            {item.icon}
                          </span>
                        </ListItemAvatar>
                        <ListItemText sx={{ fontSize: 30, fontWeight: "bold" }}>
                          <Typography variant="h6" sx={{ color: "white" }}>
                            {item.headline}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>

                  <Button
                    sx={{ textTransform: "none", textAlign: "center" }}
                    fullWidth
                    href={process.env.REACT_APP_BUY_PILOT_URL}
                  >
                    <Card raised sx={{ width: "100%" }}>
                      <CardContent>
                        <Typography variant="h6">
                          <span
                            role="img"
                            aria-label="rocket icon"
                            style={{ fontSize: 40, mr: 3 }}
                          >
                            🚀
                          </span>{" "}
                          <span>I'm Ready to Start</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Button>

                  <Button
                    sx={{ textTransform: "none", textAlign: "center" }}
                    fullWidth
                    href="https://calendly.com/mark-quizzify/30min?month=2023-05"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Card raised sx={{ width: "100%" }}>
                      <CardContent>
                        <Typography variant="h6">
                          <span
                            role="img"
                            aria-label="rocket icon"
                            style={{ fontSize: 40, mr: 3 }}
                          >
                            📲
                          </span>{" "}
                          <span>Let's Talk First</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Button>

                  <Button
                    sx={{ textTransform: "none", textAlign: "center" }}
                    fullWidth
                    onClick={() => setShowShareDialogue(true)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Card raised sx={{ width: "100%" }}>
                      <CardContent>
                        <Typography variant="h6">
                          <span
                            role="img"
                            aria-label="rocket icon"
                            style={{ fontSize: 40, mr: 3 }}
                          >
                            🧐
                          </span>
                          {"  "}
                          <span>Can I Check With My Colleagues?</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Button>
                </Stack>
              </CardContent>
            </Grid>
            <Grid item md={6} sm={12} xs={12} sx={{ height: "100%" }}>
              <CardContent sx={{ p: 3 }}>
                <Typography
                  variant="h4"
                  sx={{ mb: 1, fontWeight: "bold" }}
                  textAlign="center"
                >
                  What the Quizzify Pilot Offers
                </Typography>
                <List>
                  {items.map((item) => (
                    <ListItem key={item.key} sx={{ mt: 2, mb: 2 }}>
                      <ListItemAvatar sx={{ fontSize: 40 }}>
                        <span role="img" aria-label={item.iconAriaLabel}>
                          {item.icon}
                        </span>
                      </ListItemAvatar>
                      <ListItemText sx={{ fontSize: 30, fontWeight: "bold" }}>
                        <Stack spacing={1}>
                          <Typography variant="h6">{item.headline}</Typography>
                          {item.subheadline && (
                            <Typography>{item.subheadline}</Typography>
                          )}
                          {item.buttonText && item.secondaryActionValue && (
                            <Box>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  setDetail(item.secondaryActionValue)
                                }
                              >
                                {item.buttonText}
                              </Button>
                            </Box>
                          )}
                        </Stack>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Card>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#EFEFEF",
                    }}
                  >
                    <img src="/img/guarantee.png" alt="Guarantee" width={60} />
                    <Typography
                      sx={{ ml: 2, fontSize: 20 }}
                      variant="subtitle1"
                    >
                      90% of team members surveyed will say they learned
                      something...or your money back!
                    </Typography>
                  </CardContent>
                </Card>
              </CardContent>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  );
};

export default PilotOffer001;
