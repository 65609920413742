import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Box,
  Alert,
  Grid,
  Modal,
  Button,
  CardContent,
  CardActions,
  IconButton,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import api from "../../api/endpoints";
import SortableTable from "./SortableTable";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const DisplaySettingsModal = ({ handleClose, user, updateUser }) => {
  const [displayStyle, setDisplayStyle] = useState(
    user.leaderboard_display_name
  );

  const hasChanged = displayStyle !== user.leaderboard_display_name;

  return (
    <Modal open onClose={handleClose} aria-labelledby="display-settings-modal">
      <Card sx={modalStyle}>
        <CardHeader
          title="Select How to Display Your Name to Your Peers"
          subheader="This is how the rest of the company will see it also."
          avatar={<SettingsIcon />}
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Display Style
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="anonymous"
              name="radio-buttons-group"
              value={displayStyle}
              onChange={(e) => setDisplayStyle(e.target.value)}
            >
              <FormControlLabel
                value="anonymous"
                control={<Radio />}
                label="Anonymous"
              />
              <FormControlLabel
                value="first_name_last_initial"
                control={<Radio />}
                label={user?.name_options?.first_name_last_initial}
              />
              <FormControlLabel
                value="full_name"
                control={<Radio />}
                label={user?.name_options?.full_name}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            disabled={!hasChanged}
            onClick={() =>
              api.leaderboard
                .setDisplayNameStyle(displayStyle)
                .then((res) => {
                  updateUser(res.data.user);
                  handleClose();
                })
                .catch((err) => window.alert(err))
            }
            variant="contained"
          >
            Save
          </LoadingButton>
          <Button sx={{ marginLeft: "auto" }} onClick={handleClose}>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

const headers = [
  {
    id: "rank",
    label: "Rank",
    rightAlign: false,
    noSort: true,
  },
  {
    id: "name",
    label: "Name",
    rightAlign: false,
    noSort: true,
  },
  {
    id: "total_quizzes",
    label: (
      <Tooltip title="Distinct quizzes completed (excludes bonus quizzes)">
        <Box>
          Total Quizzes <InfoIcon sx={{ fontSize: 18 }} />
        </Box>
      </Tooltip>
    ),
    rightAlign: true,
  },
  {
    id: "total_games",
    label: (
      <Tooltip title="Total games played (includes bonus quizzes)">
        <Box>
          Total Games <InfoIcon sx={{ fontSize: 18 }} />
        </Box>
      </Tooltip>
    ),
    rightAlign: true,
  },
  {
    id: "average_score",
    label: (
      <Tooltip title="The quiz score is the average of all the times you've played a given quiz. The average quiz score is the average of all your quiz scores.">
        <Box>
          Average Quiz Score <InfoIcon sx={{ fontSize: 18 }} />
        </Box>
      </Tooltip>
    ),
    rightAlign: true,
  },
  {
    id: "total_score",
    label: "Total Score",
    rightAlign: true,
  },
  {
    id: "average_first_try_score",
    label: (
      <Box>
        Avg Quiz Score
        <br />
        <Typography variant="caption">(First Try)</Typography>
      </Box>
    ),
    rightAlign: true,
  },
  {
    id: "first_try_total_score",
    label: (
      <Box>
        Total Score
        <br />
        <Typography variant="caption">(First Try)</Typography>
      </Box>
    ),
    rightAlign: true,
  },
];

const Leaderboard = ({ companyId }) => {
  const [leaderboard, setLeaderboard] = useState();
  const [user, setUser] = useState();
  const [showDisplaySettingsModal, setShowDisplaySettingsModal] = useState();

  useEffect(() => {
    api.leaderboard.index(companyId).then((res) => {
      setUser(res.data.user);

      setLeaderboard(res.data.leaderboard);
    });
  }, [companyId]);

  if (!leaderboard) return null;

  const current_user_row = leaderboard.find((row) => row.user.id === user?.id);
  const isOnLeaderboard = !!current_user_row;

  const showAnonymousBanner = user?.leaderboard_display_name === "anonymous";

  return (
    <>
      {showDisplaySettingsModal && (
        <DisplaySettingsModal
          handleClose={() => setShowDisplaySettingsModal(false)}
          user={user}
          updateUser={(updatedUser) => {
            const combinedUser = {
              ...user,
              ...updatedUser,
            };

            setUser(combinedUser);
            setLeaderboard(
              leaderboard.map((row) => {
                if (row.user.id === combinedUser.id) {
                  return {
                    ...row,
                    user: combinedUser,
                  };
                }

                return row;
              })
            );
          }}
        />
      )}
      <Grid item sm={12}>
        <Card variant="outlined">
          <CardHeader
            title={
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                Leaderboard
              </Typography>
            }
            avatar={<LeaderboardIcon />}
            action={
              <Tooltip title="Adjust your Display Name">
                <IconButton onClick={() => setShowDisplaySettingsModal(true)}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            }
          />
          {showAnonymousBanner && (
            <>
              <Divider />
              <Alert>
                <Button
                  size="small"
                  onClick={() => setShowDisplaySettingsModal(true)}
                >
                  Show off my results
                </Button>
              </Alert>
            </>
          )}
          <Divider />
          <Alert severity="info">
            {isOnLeaderboard
              ? `Your best rank is in ${user?.highest_rank_in.label}!`
              : "Play a quiz to get on the leaderboard!"}
          </Alert>
          <Box sx={{ maxHeight: 300, overflow: "scroll" }}>
            <SortableTable
              headers={headers}
              rows={leaderboard.map((row) =>
                current_user_row === row ? { ...row, currentUser: true } : row
              )}
              startingSort={user?.highest_rank_in.value}
            />
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default Leaderboard;
