import React from "react";
import { Typography, Tooltip, Button, Box } from "@mui/material";

const Logo = ({ showLogoOnQuiz, logo }) => {
  if (showLogoOnQuiz && logo) {
    return <img src={logo} alt="Company Logo" height="43" />;
  }

  return (
    <>
      <Typography
        color="primary"
        variant="h5"
        sx={{
          fontWeight: "900 !important",
          fontFamily: "Didot !important",
          fontSize: 28,
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        Quizzify
      </Typography>
      <Box
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
        }}
      >
        <img height={44} width={44} alt="Quizzify Logo" src="/img/q-logo.png" />
      </Box>
    </>
  );
};

const ContainedLogo = ({ homeUrl, ...rest }) => {
  if (homeUrl) {
    return (
      <Tooltip title="Go to Home Page">
        <Button
          href={homeUrl}
          sx={{ textTransform: "none", padding: 0, minWidth: 0 }}
        >
          <Logo {...rest} />
        </Button>
      </Tooltip>
    );
  }
  return <Logo {...rest} />;
};

export default ContainedLogo;
