import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import api from "../../api/endpoints";

const MoreQuizzes = ({ company }) => {
  const [quizzes, setQuizzes] = useState();

  useEffect(() => {
    api.moreQuizzes
      .get({ companyId: company.id })
      .then((res) => {
        setQuizzes(res.data.quizzes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [company.id]);

  if (!quizzes) return null;
  if (quizzes.length === 0) return null;

  return (
    <Card raised>
      <CardHeader title="Try Another Quiz!" />
      <Divider />
      <CardContent sx={{ overflowX: "auto" }}>
        <Box>
          <Grid container spacing={2} flexWrap="nowrap">
            {quizzes.map((q) => (
              <Grid key={q.name} item>
                <Card
                  raised
                  sx={{
                    width: 300,
                    maxWidth: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardHeader
                    title={q.name}
                    avatar={<QuizIcon color="primary" />}
                  />
                  <Divider />

                  <CardContent
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography>{q.description}</Typography>
                    <Box sx={{ flexGrow: 1, height: "auto" }} />
                    <Button
                      href={q.url}
                      variant="contained"
                      color="secondary"
                      fullWidth
                    >
                      Play Quiz
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MoreQuizzes;
