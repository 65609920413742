import React, { useState } from "react";
import { Box, Typography, Link, Menu, MenuItem } from "@mui/material";
import reactStringReplace from "react-string-replace";

const Switch = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (options.length === 0) return null;

  return (
    <>
      <Link
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        size="small"
        component="button"
        onClick={handleClick}
      >
        Switch Quiz
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id}>
            <Link underline="hover" href={`/quiz/${option.id}`}>
              {option.name}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const Nudge = ({ contestSettings }) => {
  if (!contestSettings) return null;

  const { message, allow_switch, alternative_quizzes = [] } = contestSettings;

  if (!message) return null;

  return (
    <Box>
      <Typography variant="caption">
        {allow_switch && alternative_quizzes.length > 0
          ? reactStringReplace(message, "$SWITCH", () => (
              <Switch key="switch" options={alternative_quizzes} />
            ))
          : message}
      </Typography>
    </Box>
  );
};

export default Nudge;
