import React, { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  Stack,
  CardContent,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Container,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import SavingsIcon from "@mui/icons-material/Savings";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import SharePilotDialogue from "./SharePilotDialogue";
import Curriculum from "./Curriculum";

const productItems = [
  {
    key: "care",
    icon: "❤️",
    iconAriaLabel: "Heart Icon",
    headline:
      "Invest in education you and your colleagues will actually use, and appreciate",
  },
  {
    key: "cost",
    icon: "💵",
    iconAriaLabel: "Money Icon",
    headline:
      "Start tackling the $3,800 of wasted healthcare spend per employee per year",
  },
];

const HomePageCTA = () => {
  const [showShareDialogue, setShowShareDialogue] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const original_url = searchParams.get("original_url");

  const shareUrl = original_url || window.location.href;

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: "100%",
        p: { md: 5, sm: 0, xs: 0 },
        maxWidth: "100%",
        width: {
          xs: "100%",
          sm: 650,
          md: "100%",
        },
      }}
    >
      <Card raised sx={{ height: { md: "100%", sm: "auto" } }}>
        <SharePilotDialogue
          shareUrl={shareUrl}
          open={showShareDialogue}
          onClose={() => setShowShareDialogue(false)}
        />

        <Grid container spacing={2} sx={{ height: { md: "100%", sm: "auto" } }}>
          <Grid item md={6} sm={12} xs={12} sx={{ height: "100%" }}>
            <CardContent
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                height: "100%",
                p: 3,
              })}
            >
              <Typography
                variant="h4"
                sx={{ mb: 1, fontWeight: "bold", color: "white" }}
                textAlign="center"
              >
                Ready to Invest in Health Literacy? Take the Next Step
              </Typography>
              <Stack spacing={2}>
                <List>
                  {productItems.map((item) => (
                    <ListItem key={item.key} sx={{ mb: 2 }}>
                      <ListItemAvatar sx={{ fontSize: 40 }}>
                        <span role="img" aria-label={item.iconAriaLabel}>
                          {item.icon}
                        </span>
                      </ListItemAvatar>
                      <ListItemText sx={{ fontSize: 30, fontWeight: "bold" }}>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          {item.headline}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>

                <Button
                  sx={{ fontSize: 16 }}
                  fullWidth
                  href="https://home.quizzify.com/pricing"
                  variant="contained"
                  color="secondary"
                  rel="noopener noreferrer"
                  target="_blank"
                  startIcon={<SavingsIcon />}
                >
                  What's the cost?
                </Button>

                <Button
                  href="/pilot/offer"
                  sx={{ fontSize: 16 }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  rel="noopener noreferrer"
                  target="_blank"
                  startIcon={<FlightTakeoffIcon />}
                >
                  Do you have a pilot program?
                </Button>
                <Button
                  href="https://calendly.com/mark-quizzify/30min?month=2023-05"
                  sx={{ fontSize: 16 }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  rel="noopener noreferrer"
                  target="_blank"
                  startIcon={<CalendarMonthIcon />}
                >
                  I’d like to meet and learn more
                </Button>

                <Button
                  onClick={() => setShowShareDialogue(true)}
                  sx={{ fontSize: 16 }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  rel="noopener noreferrer"
                  target="_blank"
                  startIcon={<GroupsIcon />}
                >
                  Please send this intro to my colleagues
                </Button>
              </Stack>
            </CardContent>
          </Grid>
          <Grid item md={6} sm={12} xs={12} sx={{ height: "100%" }}>
            <CardContent
              sx={{
                p: 3,
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", mb: 2 }}
                textAlign="center"
              >
                Monthly Lesson Previews
              </Typography>
              <Curriculum />
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default HomePageCTA;
