import React from "react";
import { Typography, Link } from "@mui/material";

const Message = ({ type, value, displayName }) => {
  if (type === "text") {
    return <Typography>{value}</Typography>;
  }

  if (type === "link") {
    return (
      <Typography>
        <Link href={value}>{displayName}</Link>
      </Typography>
    );
  }

  return null;
};

export default Message;
