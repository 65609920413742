import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  Autocomplete,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const CustomFieldsForm = ({ onLogin, config, loginErrors }) => {
  const configEmail = _.get(config, "email", "");
  const companyId = _.get(config, "company.id", "");
  const loginType = _.get(config, "loginType");
  const fields = _.get(config, "fields");

  const {
    handleSubmit,
    setValue,
    register,
    formState,
    errors,
    watch,
    control,
    setError,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      email: configEmail,
    },
  });

  useEffect(() => {
    if (loginErrors) {
      Object.keys(loginErrors).forEach((key) => {
        setError(key, { type: "custom", message: loginErrors[key] });
      });
    }
  }, [loginErrors, setError]);

  useEffect(() => {
    setValue("email", configEmail);
  }, [configEmail, setValue]);

  // Use the useForm hook to create a form instance

  const castFieldValues = (values) => {
    const newValues = Object.assign({}, values);

    fields
      .filter(({ type }) => type === "select")
      .forEach((field) => {
        newValues[field.name] = newValues[field.name].value;
      });

    return newValues;
  };

  const onSubmit = handleSubmit((values) => {
    const expandedValues = {
      ...castFieldValues(values),
      email: values.email || configEmail,
      company_id: _.get(config, "company.id"),
      loginType,
    };

    onLogin(expandedValues);
  });

  const resetEmail = () => {
    setValue("email", "");
    onLogin({
      company_id: companyId,
    });
  };

  const getFieldDetails = (field) => {
    const details = { validate: {} };

    if (field.required) {
      details.required = "Required";
    }

    if (field.name.includes("password")) {
      details.validate.passwordLength = (value) =>
        value.length >= 8 || "Password must be at least 8 characters";
    }

    if (field.name === "passwordConfirmation") {
      const password = watch("password");

      details.validate.passwordsMatch = (value) =>
        value === password || "Password confirmation must match";
    }

    return details;
  };

  const getField = (field) => {
    if (field.type === "select") {
      return (
        <Controller
          control={control}
          name={field.name}
          rules={{
            required: field.required ? "Required" : false,
          }}
          render={({ onChange, value, name }) => (
            <FormControl variant="outlined" fullWidth error={!!errors[name]}>
              <Autocomplete
                labelId={`${name}-label`}
                id={name}
                label={field.label}
                value={value || null}
                onChange={(_, newValue) => onChange(newValue)}
                options={field.options.map(({ value, text }) => ({
                  label: text,
                  value,
                }))}
                renderInput={(params) => (
                  <TextField {...params} label={field.label} />
                )}
                isOptionEqualToValue={(option, currentValue) =>
                  option.value === currentValue.value
                }
              />
              {errors[name] && (
                <FormHelperText>{errors[name].message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      );
    }

    if (field.type === "date") {
      return (
        <Controller
          control={control}
          name={field.name}
          rules={{
            required: field.required ? "Required" : false,
          }}
          render={({ onChange, value }) => {
            return (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label={field.label}
                  value={value}
                  onChange={onChange}
                  inputFormat="MM/DD/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors[field.name]}
                      helperText={
                        errors[field.name] && errors[field.name].message
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            );
          }}
        />
      );
    }

    return (
      <>
        {/* If a configEmail is provided, then one was already submitted, so it's locked until changed */}
        <TextField
          disabled={!!configEmail && field.name === "email"}
          name={field.name}
          label={field.label}
          variant="outlined"
          type={field.type || "text"}
          placeholder={field.placeholder}
          inputRef={register(getFieldDetails(field))}
          aria-label={field.label}
          fullWidth={true}
          error={!!errors[field.name]}
          helperText={errors[field.name] && errors[field.name].message}
        />
        {!!configEmail && field.name === "email" && (
          <Button onClick={resetEmail}>Change Email</Button>
        )}
      </>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container direction="column" spacing={2}>
        {errors.login && (
          <Grid item>
            <Typography color="error">{errors.login.message}</Typography>
          </Grid>
        )}
        {fields.map((field) => (
          <Grid item key={field.name}>
            {getField(field)}
          </Grid>
        ))}
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            fullWidth
            disabled={formState.isSubmitting}
            endIcon={formState.isSubmitting && <CircularProgress size={20} />}
          >
            Submit
          </Button>
        </Grid>
        {config.errors && (
          <Grid item>
            {config.errors.map((err) => (
              <Typography key={err} color="error">
                {err}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CustomFieldsForm;
