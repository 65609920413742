import React from "react";
import { Grid, Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const JumpToQuizzes = ({ quizzesSectionRef }) =>
  quizzesSectionRef.current ? (
    <Grid
      item
      xs={12}
      sx={{ display: { sm: "block", md: "none" }, textAlign: "center" }}
    >
      <Button
        startIcon={<ArrowDownwardIcon />}
        onClick={() => {
          const position = quizzesSectionRef.current.offsetTop - 60;

          const container = document.getElementById("page-container");

          const myTimeout = setTimeout(
            () => container.scrollTo(0, position),
            300
          );

          const onScroll = () => {
            clearTimeout(myTimeout);

            if (container.pageYOffset === position) {
              container.removeEventListener("scroll", onScroll);
            }
          };

          container.addEventListener("scroll", onScroll);

          container.scrollTo({
            top: position,
            behavior: "smooth",
          });
        }}
        variant="contained"
        color="secondary"
      >
        Jump to Quizzes
      </Button>
    </Grid>
  ) : null;

export default JumpToQuizzes;
