import { useEffect, useContext } from "react";
import { context, actions } from "../AuthContext";

const LoginController = ({ companyId }) => {
  const { dispatch, state } = useContext(context);

  useEffect(() => {
    if (!state.isLoggedIn) {
      console.log("Dispatch ran in login controller");
      dispatch({
        type: actions.promptLogin,
        companyId,
      });
    }
  }, [companyId, dispatch, state.isLoggedIn]);

  return null;
};

export default LoginController;
