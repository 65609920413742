import React from "react";
import { Typography, CircularProgress } from "@mui/material";
import GoogleMap from "./GoogleMap";

const BodyContent = ({
  locationComplete,
  position,
  locations,
  setLocations,
  setLocationComplete,
}) => {
  if (locationComplete && !position) {
    return (
      <Typography>
        Please enable location services for this site to allow us to add nearby
        hospitals to your wallet passes.
      </Typography>
    );
  }

  if (!position) {
    return (
      <>
        <Typography>
          Fetching your location to find nearby emergency rooms...
        </Typography>
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
        The following local emergency rooms will be attached to your pass. When
        you're near them the pass will pop up in notifications to remind you.
      </Typography>
      {position && (
        <GoogleMap
          position={position}
          locations={locations}
          setLocations={setLocations}
          setLocationComplete={setLocationComplete}
        />
      )}
    </>
  );
};

export default BodyContent;
