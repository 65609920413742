const contactFields = {
  employeeId: {
    key: "employeeId",
    label: "Employee ID",
    type: "text",
  },
  message: {
    key: "message",
    label: "Message",
    type: "text",
  },
  phone: {
    key: "phone",
    label: "Phone Number",
    type: "text",
  },
  shouldContact: {
    key: "shouldContact",
    label: "May we contact you?",
    type: "bool",
  },
};

export { contactFields };
