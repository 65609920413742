import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Typography,
  TextField,
  Grid,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";
import EmailLabel from "./EmailLabel";

const EmailPasswordForm = ({ onLogin, config, confirm, loginErrors }) => {
  const configEmail = _.get(config, "email", "");
  const loginType = _.get(config, "loginType");

  const {
    handleSubmit,
    setValue,
    register,
    formState,
    errors,
    watch,
    setError,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      email: configEmail,
      password: "",
      password_confirmation: "",
    },
  });

  useEffect(() => {
    setValue("email", configEmail);
  }, [configEmail, setValue]);

  useEffect(() => {
    if (loginErrors) {
      Object.keys(loginErrors).forEach((key) => {
        setError(key, { type: "custom", message: loginErrors[key] });
      });
    }
  }, [loginErrors, setError]);

  // Use the useForm hook to create a form instance
  const onSubmit = handleSubmit((values) => {
    const expandedValues = {
      ...values,
      email: values.email || configEmail,
      company_id: _.get(config, "company.id"),
      loginType,
    };

    onLogin(expandedValues);
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item align="center" xs={12}>
          <EmailLabel
            config={config}
            onLogin={onLogin}
            onReset={() => setValue("email", "")}
          />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            name="password"
            label="Password"
            variant="outlined"
            type="password"
            placeholder="Enter your password"
            inputRef={register({
              required: "You must enter a password",
              minLength: 8,
            })}
            aria-label="password"
            error={!!errors.password}
            helperText={(() => {
              if (errors.password?.type === "minLength") {
                return "Minimum length must be 8 characters";
              }

              return errors.password?.message;
            })()}
          />
        </Grid>
        {confirm && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="password_confirmation"
              label="Password Confirmation"
              variant="outlined"
              type="password"
              disabled={watch("password").length < 8}
              placeholder="Confirm your password"
              inputRef={register({
                validate: {
                  matches: (value) => {
                    if (value !== watch("password")) {
                      return "Password confirmation must match";
                    }

                    return true;
                  },
                },
              })}
              aria-label="password_confirmation"
              error={!!errors.password_confirmation}
              helperText={errors.password_confirmation?.message}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            disabled={formState.isSubmitting}
            endIcon={formState.isSubmitting && <CircularProgress size={20} />}
          >
            Submit
          </Button>
        </Grid>

        {config.errors && (
          <Grid item>
            {config.errors.map((err) => (
              <Typography key={err} color="error">
                {err}
              </Typography>
            ))}
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default EmailPasswordForm;
