import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

const Benefits = ({ homePageUrl }) => (
  <Card>
    <CardHeader
      title={
        <Typography variant="h6" align="center" color="primary">
          Still not convinced? Here's everything your team can get at{" "}
          {homePageUrl}
        </Typography>
      }
      style={{ paddingBottom: 0 }}
    />
    <CardContent>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <List style={{ alignSelf: "center" }}>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Your Program Message" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Your Contest Message" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Who Knews (Health Factoids)" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Access to the Latest COVID-19 and Back to Work Educational Quiz Content" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Your company news feed" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="...and all without any need to login!" />
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <Typography gutterBottom>
            Best of all, everything on your home page can be customized (just
            ask us how).
          </Typography>
          <Typography gutterBottom>
            Finally, you, the administrator, will get data on how many people
            have completed your quizzes.
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default Benefits;
