import React, { useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Divider,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { context as quizContext, actions } from "../../QuizContext";
import FeedbackIcon from "./FeedbackIcon";
import AnswerImage from "./AnswerImage";

const TileAnswers = ({
  id,
  mainText,
  feedbackText,
  image,
  wasSelected,
  isCorrect,
}) => {
  const { dispatch } = useContext(quizContext);

  return (
    <Card
      style={{ height: "100%", cursor: "pointer" }}
      onClick={(_) =>
        dispatch({
          type: actions.selectAnswer,
          answerId: id,
        })
      }
    >
      {image && (
        <>
          <AnswerImage image={image} fixedHeight />
          <Divider />
        </>
      )}
      {mainText ? (
        <CardHeader
          title={
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {ReactHtmlParser(mainText)}
            </Typography>
          }
          action={
            <FeedbackIcon wasSelected={wasSelected} isCorrect={isCorrect} />
          }
        />
      ) : null}
      {feedbackText ? (
        <CardContent>
          <Collapse in={wasSelected} timeout="auto" unmountOnExit>
            <Typography variant="body1">
              {ReactHtmlParser(feedbackText)}
            </Typography>
          </Collapse>
        </CardContent>
      ) : null}
    </Card>
  );
};
export default TileAnswers;
