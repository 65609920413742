import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useForm } from "react-form";
import { useTranslation } from "react-i18next";
import * as R from "ramda";
import {
  TextField,
  contactFields as fieldMap,
  makeField,
} from "../form/fields";
import endpoints from "../../api/endpoints";
import Divider from "./Divider";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  form: {
    marginTop: ".25rem",
  },
  radio: {
    marginTop: "1rem",
  },
  field: {
    marginBottom: "0.2rem",
  },
  button: {
    marginTop: "1.25rem",
  },
}));

const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ContactForm = ({ state, contactForm }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  const buildField = makeField(classes);

  const additionalFields = R.pipe(
    R.prop("fields"),
    R.defaultTo([]),
    R.filter(
      R.compose(R.not, R.flip(R.includes)(["firstName", "lastName", "email"]))
    ),
    R.map(R.flip(R.prop)(fieldMap))
  )(contactForm);

  const { prompt } = contactForm;

  const {
    Form,
    meta: { isSubmitting, canSubmit },
  } = useForm({
    onSubmit: (values) => {
      endpoints.quiz
        .logQuestion({
          ...state,
          ...values,
        })
        .then(() => setSubmitted(true));
    },
  });

  if (submitted)
    return (
      <>
        <Divider />
        <h3>Thank you for contacting us!</h3>
      </>
    );

  return (
    <>
      <Divider />
      <Form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {prompt || "Please contact us for more info:"}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.field}>
            <TextField
              field="firstName"
              label="First Name"
              fullWidth={true}
              validate={(value) =>
                !!value ? false : "Please Enter First Name"
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.field}>
            <TextField
              field="lastName"
              label="Last Name"
              fullWidth={true}
              validate={(value) => (!!value ? false : "Please Enter Last Name")}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextField
              field="email"
              label="Email"
              fullWidth={true}
              validate={(value) =>
                !!value && validateEmail(value)
                  ? false
                  : "Please Enter Valid Email"
              }
            />
          </Grid>
          {R.map(
            (field) => (
              <Grid key={field.key} item xs={12}>
                {buildField(field)}
              </Grid>
            ),
            additionalFields
          )}
          <Grid item xs={12} className={classes.button}>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              disabled={!canSubmit || isSubmitting}
            >
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default ContactForm;
