import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  Divider,
  Button,
  CardContent,
  CardActions,
  IconButton,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { LoadingButton } from "@mui/lab";
import api from "../api/endpoints";
import { context, actions } from "./Quiz/QuizContext";

const LeaderboardDisplayNameSelector = ({ initial, handleClose }) => {
  const [user, setUser] = useState();
  const [saving, setSaving] = useState();
  const [displayStyle, setDisplayStyle] = useState("full_name");

  const { dispatch } = useContext(context);

  useEffect(() => {
    api.leaderboard
      .getUserProfile()
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => window.alert(err));
  }, []);

  if (!user) {
    return <CircularProgress />;
  }

  const hasChanged = displayStyle !== user.leaderboard_display_name;

  return (
    <Card>
      <CardHeader
        title="Select How to Display Your Name on the Leaderboard"
        subheader="(This will be shown publicly)"
        avatar={<SettingsIcon />}
        action={
          handleClose && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )
        }
      />
      <Divider />
      <CardContent>
        <FormControl>
          <FormLabel sx={{ fontWeight: "bold" }}>Display Style</FormLabel>
          <RadioGroup
            defaultValue="anonymous"
            value={displayStyle}
            onChange={(e) => setDisplayStyle(e.target.value)}
          >
            <FormControlLabel
              value="anonymous"
              control={<Radio />}
              label="Anonymous"
            />
            <FormControlLabel
              value="first_name_last_initial"
              control={<Radio />}
              label={user?.name_options?.first_name_last_initial}
            />
            <FormControlLabel
              value="full_name"
              control={<Radio />}
              label={user?.name_options?.full_name}
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
      <Divider />
      <CardActions>
        <LoadingButton
          disabled={!hasChanged}
          loading={saving}
          onClick={() => {
            setSaving(true);
            api.leaderboard
              .setDisplayNameStyle(displayStyle)
              .then(() => {
                dispatch({ type: actions.setLeaderboardDisplayName });
                setSaving(false);
              })
              .catch((err) => {
                setSaving(false);

                window.alert(err);
              });
          }}
          variant="contained"
        >
          Save
        </LoadingButton>
        {handleClose && (
          <Button sx={{ marginLeft: "auto" }} onClick={handleClose}>
            Cancel
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default LeaderboardDisplayNameSelector;
