const checkGeolocationPermission = async () => {
  try {
    const result = await navigator.permissions.query({ name: "geolocation" });
    return result.state;
  } catch (error) {
    console.error("Error checking geolocation permission:", error);
    return "unknown";
  }
};

const getPosition = async (setPosition, handleFailure) => {
  try {
    const permissionState = await checkGeolocationPermission();

    if (permissionState === "granted" || permissionState === "prompt") {
      // Dummy call to ensure the next statement works correctly.
      navigator.geolocation.getCurrentPosition(
        () => {},
        () => {},
        {}
      );
      // Actual call to get the geolocation.
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPosition({
            lat: latitude,
            lng: longitude,
          });
        },
        (e) => {
          window.alert(
            `Failed to get your location: ${e.message}. Please try again.`
          );
          handleFailure();
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log("Permission state: ", permissionState);
      window.alert("Please enable location permissions to use this feature.");
      handleFailure();
    }
  } catch (error) {
    window.alert(`An error occurred: ${error.message}`);
    handleFailure();
  }
};

export default getPosition;
