import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadState } from "../../redux/spouseSlice";

import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

import { context } from "../Quiz/QuizContext";
import { context as authContext, actions as authActions } from "../AuthContext";
import { context as homeContext } from "../PublicHome/PublicHomeContext";
import { getMainRoot } from "../../utils/externalRoutes";
import HelpButton from "../HelpButton";
import Logo from "./Logo";
import AdminLink from "./AdminLink";
import Title from "./Title";
import HomeButton from "./HomeButton";
import AuthButton from "./AuthButton";
import SpouseButton from "../SpouseManager/SpouseButton";
import SpouseManager from "../SpouseManager";
import api from "../../api/endpoints";
import homePageUrl from "../../utils/homePageUrl";

const AppContainer = () => {
  const { i18n } = useTranslation();

  const currentLocale = i18n.language;

  const dispatch = useDispatch();

  const { state } = useContext(context);
  const { state: homeState } = useContext(homeContext);
  const { state: authState, dispatch: authDispatch } = useContext(authContext);
  const history = useHistory();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [showSpouseManager, setShowSpouseManager] = useState(false);

  const title = _.get(state, "quiz.title", "");
  const hideLogin = _.get(state, "quiz.hideLogin");

  const contestSettings = _.get(state, "quiz.contest_settings");

  const { isLoggedIn, config: authConfig } = authState;
  const { showAdminLink } = homeState;

  const isEnglish = currentLocale === "en";

  useEffect(() => {
    if (isLoggedIn) {
      api.invite
        .config()
        .then((res) => dispatch(loadState(res.data)))
        .catch((err) => console.log(err));
    }
  }, [isLoggedIn, dispatch]);

  const handleLogout = () => {
    authDispatch({
      type: authActions.logout,
    });

    if (window && window.location && window.location.reload) {
      window.location.reload();
    }

    if (window && window.open) {
      window.open(`${getMainRoot()}/users/sign_out`);
    }
  };

  const handleLogin = () => {
    const { auth_format, sso_url } = authConfig;

    if (auth_format === "sso" && sso_url) {
      window.parent.location.href = sso_url;
    } else {
      history.push(`${window.location.pathname}?login=true`);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      {showSpouseManager && (
        <SpouseManager handleClose={() => setShowSpouseManager(false)} />
      )}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white !important",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexShrink: 0, mr: { xs: 1, sm: 2, md: 3 } }}>
              <Logo {...state.company} homeUrl={homePageUrl(state)} />
            </Box>

            <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
              {isEnglish && (
                <Title title={title} contestSettings={contestSettings} />
              )}
            </Box>

            <Box
              sx={{
                flexShrink: 0,
                ml: "auto",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Stack direction="row" spacing={1}>
                <HomeButton url={homePageUrl(state)} />

                {isEnglish && (
                  <>
                    <AdminLink show={showAdminLink} />

                    <SpouseButton
                      handleClick={() => setShowSpouseManager(true)}
                    />

                    <HelpButton />
                  </>
                )}

                <AuthButton
                  isLoggedIn={isLoggedIn}
                  hideLogin={hideLogin}
                  handleLogin={handleLogin}
                  handleLogout={handleLogout}
                />
              </Stack>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                ml: "auto",
                justifyContent: "flex-end",
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="options menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon color="primary" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {homePageUrl(state) && (
                  <MenuItem
                    onClick={() => {
                      window.location.href = homePageUrl(state);
                    }}
                  >
                    <HomeButton show />
                  </MenuItem>
                )}

                {showAdminLink && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <AdminLink show={showAdminLink} />
                  </MenuItem>
                )}

                <SpouseButton
                  menuItem
                  handleClick={() => {
                    handleCloseNavMenu();
                    setShowSpouseManager(true);
                  }}
                />

                <MenuItem onClick={handleCloseNavMenu}>
                  <HelpButton />
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <AuthButton
                    isLoggedIn={isLoggedIn}
                    hideLogin={hideLogin}
                    handleLogin={handleLogin}
                    handleLogout={handleLogout}
                  />
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default AppContainer;
