import React from "react";
import { Divider as MuiDivider } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  divider: {
    margin: ".5em 8px",
    alignSelf: "stretch",
  },
}));

const Divider = ({ state }) => {
  const classes = useStyles();

  return <MuiDivider classes={{ root: classes.divider }} />;
};

export default Divider;
