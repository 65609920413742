import React, { useState } from "react";
import { Button } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ShareDialogue from "../Quiz/ShareDialogue";

const ShareQuiz = ({ quizId }) => {
  const [showDialogue, setShowDialogue] = useState(false);

  const shareUrl = `${window.location.origin}/quiz/${quizId}`;

  return (
    <>
      <ShareDialogue
        shareUrl={shareUrl}
        open={showDialogue}
        onClose={() => setShowDialogue(false)}
        isQuiz
        fullWidth
        quizId={quizId}
      />
      <Button
        onClick={() => setShowDialogue(true)}
        startIcon={<ShareIcon />}
        variant="outlined"
        sx={{ flexShrink: 0, mt: "4px", mb: "4px" }}
      >
        Share Quiz
      </Button>
    </>
  );
};

export default ShareQuiz;
