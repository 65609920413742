import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Chip,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../api/endpoints";

const style = {
  position: "absolute",
  width: 1100,
  maxWidth: "100%",
  height: 850,
  maxHeight: "100%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  outline: "none",
};

const Template = ({ template }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card sx={{ height: "100%" }}>
      <img
        src={template.banner_image.url}
        alt={template.banner_image.alt}
        width="100%"
        height="175"
        style={{ objectFit: "cover" }}
      />
      <CardContent>
        <Stack spacing={1}>
          {template.audience && (
            <Chip label={`For ${template.audience.name}`} color="warning" />
          )}
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            {template.name}
          </Typography>
          <Typography>{template.description}</Typography>
        </Stack>
      </CardContent>
    </Card>
  </Grid>
);

const Curriculum = ({ open, onClose }) => {
  const [templates, setTemplates] = useState();

  useEffect(() => {
    api.pilot.curriculum_preview().then(({ data: { quiz_templates } }) => {
      setTemplates(quiz_templates);
    });
  }, []);

  const pinnedTemplates = templates?.filter(
    (template) => template.start_pinned
  );
  const rest = templates?.filter((template) => !template.start_pinned);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Card
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          raised
        >
          <CardHeader
            title={<Typography variant="h6">Curriculum Preview</Typography>}
            subheader="These are the lessons you and your invited colleagues will have available for the pilot, six up front, and one added each week."
            avatar={
              <Typography sx={{ fontSize: 24 }}>
                <span role="img" aria-label="Graduation Cap">
                  🎓
                </span>
              </Typography>
            }
            action={
              <Tooltip title="Close">
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
          />

          <Divider />
          <CardContent sx={{ backgroundColor: "#EFEFEF", overflowY: "auto" }}>
            <Grid container spacing={3}>
              {pinnedTemplates?.map((template) => (
                <Template key={template.id} template={template} />
              ))}
              {rest?.map((template) => (
                <Template key={template.id} template={template} />
              ))}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button onClick={onClose}>Close</Button>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
};

export default Curriculum;
