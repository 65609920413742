import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import copy from "copy-to-clipboard";

const HomeLink = ({ homePageUrl, subdomain }) => (
  <Card>
    <CardHeader
      title={
        <Typography variant="h6" align="center" color="primary">
          Ready to get going?
        </Typography>
      }
      style={{ paddingBottom: 0 }}
    />
    <CardContent style={{ padding: "2em" }}>
      <Typography variant="subtitle1" align="center" gutterBottom>
        1. If anything looks amiss in the Preview, just let us know and we'll
        get that sorted for you.
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        2. If it's good to go, just click to copy your dedicated link:
      </Typography>

      <Typography variant="body1" align="center" gutterBottom>
        <Button
          color="secondary"
          variant="contained"
          label="Click to Copy to Clipboard"
          onClick={() => copy(homePageUrl)}
          style={{ marginTop: "2em" }}
        >
          {homePageUrl}
        </Button>
      </Typography>
      <Typography variant="body1" align="center">
        (Click to copy to clipboard)
      </Typography>

      <Typography
        variant="subtitle1"
        style={{ marginTop: "2em" }}
        align="center"
      >
        3. Send it to any or all team members and let the learning begin!
      </Typography>
    </CardContent>
  </Card>
);

export default HomeLink;
