import React from "react";
import { Button, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";

const HomeButton = ({ url, show }) => {
  const { t } = useTranslation();

  if (!url) {
    if (show) {
      return (
        <Tooltip title="Go to Home Page">
          <Button color="primary" startIcon={<HomeIcon />}>
            {t("Home")}
          </Button>
        </Tooltip>
      );
    } else {
      return null;
    }
  }

  return (
    <Tooltip title="Go to Home Page">
      <Button href={url} color="primary" startIcon={<HomeIcon />}>
        {t("Home")}
      </Button>
    </Tooltip>
  );
};

export default HomeButton;
