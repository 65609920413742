import React from "react";
import { Grid, Typography, Link, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: ".75em 0",
    alignSelf: "center",
  },
}));

const CtaLink = ({ cta }) => {
  const classes = useStyles();

  return (
    <Grid item classes={{ root: classes.root }} key={cta.type} xs={11} sm={10}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Link
            href={cta.url}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="secondary" size="large">
              <Typography variant="body2">{cta.message}</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CtaLink;
