import React, { useContext, useState, useEffect } from "react";
import { Snackbar, Container, Alert, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import AppContainer from "../AppContainer";
import { context as authContext } from "../AuthContext";
import AcceptTerms from "../AcceptTerms";
import SetPassword from "../SetPassword";

const Content = ({ userProfile }) => {
  const history = useHistory();

  const {
    email,
    needs_action: { needs_terms, needs_password },
  } = userProfile;

  const companyId = userProfile?.individual_license?.individual_company_id;

  useEffect(() => {
    if (companyId && !(needs_terms || needs_password)) {
      history.push(`/${companyId}`);
    }
  }, [needs_terms, needs_password, history, companyId]);

  if (needs_terms) {
    return <AcceptTerms />;
  }

  if (needs_password) {
    return <SetPassword email={email} />;
  }

  return <CircularProgress />;
};

const IndividualHome = () => {
  const [showWelcome, setShowWelcome] = useState(true);

  const { state: authState } = useContext(authContext);
  const { userProfile } = authState;

  if (!userProfile) return <CircularProgress />;

  return (
    <AppContainer>
      <Container maxWidth="sm" sx={{ height: "100%" }}>
        <Snackbar
          open={showWelcome}
          autoHideDuration={7000}
          onClose={() => setShowWelcome(false)}
        >
          <Alert onClose={() => setShowWelcome(false)} severity="success">
            Welcome, {userProfile.display_name || userProfile.email} and thank
            you for subscribing. You now have individual access to Quizzify for
            a year.
          </Alert>
        </Snackbar>

        <Content userProfile={userProfile} />
      </Container>
    </AppContainer>
  );
};

export default IndividualHome;
