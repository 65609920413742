import React from "react";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Welcome from "./Welcome";
import PublicHomeIntro from "./PublicHomeIntro";
import PublicHomePreview from "./PublicHomePreview";
import HomeLink from "./HomeLink";
import QrCode from "./QrCode";
import Benefits from "./Benefits";
import { getMainRootWithSubdomain } from "../../../utils/externalRoutes";

const useStyles = makeStyles(() => ({
  container: {
    padding: "3em 0",
    maxWidth: 900,
  },
}));

const AdminWelcome = ({ state, children }) => {
  const { name: companyName, subdomain } = state;
  const classes = useStyles();

  const homePageUrl = getMainRootWithSubdomain(subdomain);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Welcome companyName={companyName} />
        </Grid>
        <Grid item xs={12}>
          <PublicHomeIntro homePageUrl={homePageUrl} />
        </Grid>
        <Grid item xs={12}>
          <PublicHomePreview children={children} />
        </Grid>
        <Grid item xs={12}>
          <HomeLink homePageUrl={homePageUrl} subdomain={subdomain} />
        </Grid>
        <Grid item xs={12}>
          <QrCode url={homePageUrl} subdomain={subdomain} />
        </Grid>
        <Grid item xs={12}>
          <Benefits homePageUrl={homePageUrl} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminWelcome;
