import React from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 250,
    maxHeight: 150,
    objectFit: "contain",
  },
}));

const LogoImage = ({ company }) => {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const isEnglish = i18n.language === "en";

  if (company.logo) {
    return (
      <Grid item xs={12} sm={6}>
        <img className={classes.image} src={company.logo} alt={company.name} />
      </Grid>
    );
  }

  if (!isEnglish) return null;

  if (company.name || company.displayName) {
    return (
      <Grid item xs={12} sm={6}>
        <Typography color="primary" variant="h4" gutterBottom>
          {company.displayName || company.name}
        </Typography>
      </Grid>
    );
  }

  return null;
};

export default LogoImage;
