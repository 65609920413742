import React, { useContext } from "react";
import { Typography, Button, Box } from "@mui/material";
import _ from "lodash";

import { context, actions } from "../AuthContext";

const EmailLabel = ({ config, onLogin, onReset = () => {} }) => {
  const { dispatch } = useContext(context);

  const email = _.get(config, "email", "");
  const companyId = _.get(config, "company.id", "");

  const resetEmail = () => {
    onReset();
    onLogin({
      company_id: companyId,
    });
    dispatch({
      type: actions.loadConfig,
      config: {
        ...config,
        email: null,
      },
      replace: true,
    });
  };

  return (
    <Box textAlign="center">
      <Typography gutterBottom align="center">
        You're logging in as:
      </Typography>
      <Typography gutterBottom variant="h6" align="center">
        {email}
      </Typography>
      <Button onClick={resetEmail} size="small" color="primary">
        Use a Different Email
      </Button>
    </Box>
  );
};

export default EmailLabel;
