import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  CardHeader,
} from "@mui/material";
import AddToAppleWalletButton from "../AddToAppleWalletButton";
import AddToGoogleWalletButton from "../AddToGoogleWalletButton";
import DownloadApplePass from "./DownloadApplePass";
import DownloadGooglePass from "./DownloadGooglePass";
import api from "../../../api/endpoints";

const WalletPasses = ({
  osOptions = ["ios", "android"],
  userCoordinates,
  mobileWebview,
}) => {
  const [showPassDownload, setShowPassDownload] = useState(false);

  let title;

  if (osOptions.includes("ios") && osOptions.includes("android")) {
    title = "Add the ER Prevent Consent to your Apple or Google Wallet";
  } else if (osOptions.includes("ios")) {
    title = "Add the ER Prevent Consent to your Apple Wallet";
  } else if (osOptions.includes("android")) {
    title = "Add the ER Prevent Consent to your Google Wallet";
  }

  return (
    <>
      {showPassDownload === "apple" && (
        <DownloadApplePass
          open
          handleClose={() => setShowPassDownload(false)}
          userCoordinates={userCoordinates}
          mobileWebview={mobileWebview}
        />
      )}

      {showPassDownload === "google" && (
        <DownloadGooglePass
          open
          handleClose={() => setShowPassDownload(false)}
          userCoordinates={userCoordinates}
        />
      )}

      <Card raised>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <Grid spacing={2} container sx={{ mb: 3 }}>
            {osOptions.includes("ios") && (
              <Grid item>
                <AddToAppleWalletButton
                  onClick={() => {
                    api.activity.logEvent({
                      event_category: "PREVENT_CONSENT_INTERACTION",
                      event_action: "Clicked add to Apple Wallet Button",
                    });
                    setShowPassDownload("apple");
                  }}
                />
              </Grid>
            )}

            {osOptions.includes("android") && (
              <Grid item>
                <AddToGoogleWalletButton
                  onClick={() => {
                    api.activity.logEvent({
                      event_category: "PREVENT_CONSENT_INTERACTION",
                      event_action: "Clicked add to Google Wallet Button",
                    });
                    setShowPassDownload("google");
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Typography>
            <ul style={{ margin: 0, paddingLeft: 18 }}>
              <li style={{ marginBottom: 12 }}>
                <Typography>
                  It will ask for your location to find the nearest hospitals
                  (the pass will pop up to remind you when you're there).
                </Typography>
              </li>
              <li>
                <Typography>Follow the instructions to save it.</Typography>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default WalletPasses;
