import React, { useContext } from "react";
import {
  Stack,
  Typography,
  Autocomplete,
  MenuItem,
  TextField,
} from "@mui/material";
import QuestionIcon from "./QuestionIcon";
import { context as quizContext, actions } from "../../QuizContext";

const stripHtml = (str) => (str ? str.replace(/(<([^>]+)>)/gi, "") : str);

const renderLabel = (option) => {
  if (option.intro)
    return `${stripHtml(option.intro)}: ${stripHtml(option.prompt)}`;

  return stripHtml(option.prompt);
};

const MobileTableOfContents = ({ questions }) => {
  const { dispatch } = useContext(quizContext);

  return (
    <Autocomplete
      sx={{
        width: "100%",
        maxWidth: "100%",
        display: { md: "none" },
        mt: 2,
        mb: 2,
      }}
      openOnFocus
      disableClearable
      size="small"
      color="white"
      disablePortal
      options={questions}
      onChange={(_event, value) => {
        dispatch({
          type: actions.onChangeQuestion,
          data: questions.indexOf(value),
        });
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={renderLabel}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <QuestionIcon question={option} />
            <Typography sx={{ fontSize: "12px !important" }}>
              {renderLabel(option)}
            </Typography>
          </Stack>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Table of Contents (Select Question)" />
      )}
    />
  );
};

export default MobileTableOfContents;
