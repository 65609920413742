import React from "react";
import { Box, Typography } from "@mui/material";
import Nudge from "./Nudge";

const Title = ({ title, ...rest }) => {
  if (!title) return null;

  return (
    <Box>
      <Typography
        variant="subtitle1"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontWeight: "500 !important",
          color: "gray",
          minWidth: 150,

          fontSize: {
            xs: 14,
            sm: "1rem",
          },
        }}
      >
        {title}
      </Typography>
      <Nudge {...rest} />
    </Box>
  );
};

export default Title;
