import React, { createContext, useReducer } from "react";
import * as R from "ramda";
import keyMirror from "../../utils/keyMirror";
import reducer from "../../utils/curriedReducer";
import { onLoadState, onSelectAnswer, onReset } from "../../reducers/";
import { initialState } from "../../StateModel";

const actions = keyMirror({
  loadState: null,
  selectAnswer: null,
  reset: null,
  removeResultId: null,
  clearQuiz: null,
  onChangeQuestion: null,
  completeQuiz: null,
  hideWaitingRoom: null,
  setLeaderboardDisplayName: null,
});

const {
  loadState,
  selectAnswer,
  reset,
  removeResultId,
  clearQuiz,
  onChangeQuestion,
  completeQuiz,
  hideWaitingRoom,
  setLeaderboardDisplayName,
} = actions;

const context = createContext(initialState);
const { Provider } = context;

const handlers = {
  [loadState]: (action) => (state) => onLoadState(action, state),
  [selectAnswer]: onSelectAnswer,
  [removeResultId]: () => (state) => R.assoc("resultId", null, state),
  [reset]: onReset,
  [clearQuiz]: () => (state) => ({ ...state, quiz: initialState.quiz }),
  [onChangeQuestion]: (action) => (state) => ({
    ...state,
    questionIndex: action.data,
  }),
  [completeQuiz]: () => (state) => ({
    ...state,
    quizComplete: true,
  }),
  [hideWaitingRoom]: () => (state) => ({
    ...state,
    hideWaitingRoom: true,
  }),
  [setLeaderboardDisplayName]: () => (state) => ({
    ...state,
    quiz: {
      ...state.quiz,
      prompt_for_leaderboard_display_name_up_front: false,
    },
  }),
};

const QuizStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer(handlers), initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { context, QuizStateProvider, actions };
