import React from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const QuestionImage = ({ image }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        marginBottom: 12,
        textAlign: "center",
        width: "auto",
        height: isMobile ? 200 : 300,
        maxWidth: "100%",
        paddingTop: 0,
      }}
    >
      <img
        style={{
          width: "auto",
          maxWidth: "100%",
          position: "relative",
          objectFit: "contain",
          height: "100%",
        }}
        src={image.url}
        alt={image.alt}
      />
    </div>
  );
};

export default QuestionImage;
