import React from "react";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  score: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
}));

const FinalScore = ({ score, usePoints }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  if (!usePoints) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        justifyContent: {
          xs: "center",
          sm: "flex-start",
        },
      }}
    >
      <Typography variant="h6">{t("finalScore")}</Typography>
      <Typography variant="h3" align="center" className={classes.score}>
        {score.total}
      </Typography>
    </Stack>
  );
};

export default FinalScore;
